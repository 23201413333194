import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import CouponItem from '~/components/CouponItem';

type CouponListProps = {};

// 顯示優惠券列表
export default function CouponList(props: CouponListProps) {
  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
      <CouponItem />
      <CouponItem />
      <CouponItem />
      <CouponItem />
      <CouponItem />
      <CouponItem />
    </SimpleGrid>
  );
}
