import { selector } from 'recoil';

import selectedMenuAtom from '~/recoil/atom/selectedMenu';
import { menuSelector } from '~/recoil/selectors/menus';
import { categoriesSelector } from './categories';
import { itemsSelector } from './items';

import filterSoldOutItemIds from '~/utils/filterSoldOutItemIds';

const filteredCategoryIdsSelector = selector<string[]>({
  key: 'filteredCategoryIdsSelector',
  get: ({ get }) => {
    const { selectedMenuId } = get(selectedMenuAtom);
    const selectedMenu = get(menuSelector(selectedMenuId));
    const selectedMenuCategoryIds = selectedMenu?.categories || [];

    const categories = get(categoriesSelector);
    const items = get(itemsSelector);
    if (!categories || !items) return [];

    const filteredCategoryIdsBySoldOut = (categoryIds: string[]) => {
      return categoryIds.filter((id) => {
        const category = categories[id];

        const categorySoldOutItems = filterSoldOutItemIds({
          items,
          itemIds: category.items,
        });

        return categorySoldOutItems.length !== 0;
      });
    };

    return filteredCategoryIdsBySoldOut(selectedMenuCategoryIds);
  },
});

export default filteredCategoryIdsSelector;
