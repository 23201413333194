import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  Text,
  HStack,
  ModalHeader,
} from '@chakra-ui/react';

type CheckoutErrorModalProps = {
  isOpen: boolean;
  title?: string | React.ReactNode;
  message?: string | React.ReactNode;
  renderTitle?: () => React.ReactNode;
  renderMessage?: () => React.ReactNode;
  onClose: () => void;
};

export default function CheckoutErrorModal({
  isOpen,
  title = '',
  message = '',
  renderTitle,
  renderMessage,
  onClose,
}: CheckoutErrorModalProps) {
  return (
    <Modal size="xs" isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        {renderTitle ? renderTitle() : <ModalHeader textAlign="center">{title}</ModalHeader>}
        <ModalBody p={5}>
          <Text textAlign="center">{message}</Text>
        </ModalBody>
        <ModalFooter p={0}>
          <HStack
            w="full"
            justify="center"
            cursor="pointer"
            p={2}
            borderTopWidth="1px"
            onClick={onClose}
          >
            {renderMessage ? renderMessage() : <Text>我知道了</Text>}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
