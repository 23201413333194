import { HStack, Flex, Text, Checkbox } from '@chakra-ui/react';

import formatPriceNumber from '~/utils/formatPriceNumber';

type ModifierOptionsSelectorItemProps = {
  optionName: string;
  price: number;
  isChecked: boolean;
  isDisabled: boolean;
  onChecked: () => void;
};

// 餐點單一選項，可打勾選擇及顯示附加價格
export default function ModifierOptionsSelectorItem({
  optionName,
  price,
  isChecked = false,
  isDisabled = false,
  onChecked,
}: ModifierOptionsSelectorItemProps) {
  return (
    <Flex
      onClick={(e) => {
        e.preventDefault();
        onChecked();
      }}
      justify="space-between"
      fontSize="sm"
    >
      <HStack spacing={2}>
        <Checkbox isChecked={isChecked} isDisabled={isDisabled} fontSize="sm">
          <Text fontSize="sm">{optionName}</Text>
        </Checkbox>
        {isDisabled && (
          <Text display="inline-block" color="red">
            完售
          </Text>
        )}
      </HStack>
      {price > 0 && <Text>+{formatPriceNumber(price)}</Text>}
    </Flex>
  );
}
