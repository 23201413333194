import React from 'react';
import { VStack, Text, HStack, RadioGroup, Radio, Divider, Image } from '@chakra-ui/react';
import linepayImg from '~/assets/images/LINEPay.png';

import { PaymentType } from '~/types';

const allPaymentTypeList = [
  {
    type: PaymentType.LINEPAY,
    content: (
      <HStack justify="space-between" flex="1">
        <Radio value={PaymentType.LINEPAY}>
          <Image src={linepayImg} h={5} objectFit="cover" />
        </Radio>
      
      </HStack>
    ),
  },
  {
    type: PaymentType.CREDIT,
    content: (
      <HStack justify="space-between" flex="1">
        <Radio value={PaymentType.CREDIT}>
          <Text fontSize="sm">信用卡付費</Text>
        </Radio>
        
      </HStack>
    ),
  },
  {
    type: PaymentType.CASH,
    content: (
      <HStack justify="space-between" flex="1">
        <Radio value={PaymentType.CASH}>
          <Text fontSize="sm">取貨後付現金</Text>
        </Radio>
      </HStack>
    ),
  },
];

type PaymentTypeSelectorProps = {
  selectedPaymentType: PaymentType;
  paymentTypeList: Array<PaymentType>; // from store
  onChangePaymentType: (paymentType: PaymentType) => void;
};

// 付款方式選擇
export default function PaymentTypeSelector({
  selectedPaymentType,
  paymentTypeList,
  onChangePaymentType,
}: PaymentTypeSelectorProps) {
  return (
    <RadioGroup
      onChange={(value) => onChangePaymentType(value as PaymentType)}
      value={selectedPaymentType}
      mt={2}
    >
      <VStack spacing={2} align="stretch">
        {allPaymentTypeList.map(({ type, content }) =>
          paymentTypeList.includes(type) ? (
            <React.Fragment key={type}>
              {content}
              <Divider />
            </React.Fragment>
          ) : (
            <React.Fragment key={type} />
          ),
        )}
      </VStack>
    </RadioGroup>
  );
}
