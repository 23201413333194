import firebase from 'firebase';
import { useState, useEffect } from 'react';
import { pastOrderListener, allOrdersListener } from '~/firebase/listener';

import { OrderWithId } from '~/types';

export default function useInProgressOrders(storeId: string, user: firebase.User | null) {
  const [orders, setOrders] = useState<Array<OrderWithId>>([]);
  const [pastOrders, setPastOrders] = useState<Array<OrderWithId>>([]);
  const [pastOrderNum, setPastOrderNum] = useState<number>(5);

  const handleUpdateOrders = (orders: Array<OrderWithId>) => {
    setOrders(orders);
    setPastOrders(orders);
  };

  const handleUpdatePastOrders = (orders: Array<OrderWithId>) => {
    // setOrders(orders);
    setPastOrders(orders);
  };

  const handleMorePastOrder = () => {
    setPastOrderNum((prev) => prev + 5);
  };

  useEffect(() => {
    let ordersUnlisten: (() => void) | null = null;

    if (storeId && user) {
      ordersUnlisten = allOrdersListener(storeId, user, pastOrderNum, handleUpdateOrders);
    }
    return () => {
      ordersUnlisten?.();
    };
  }, [storeId, user, pastOrderNum]);

  useEffect(() => {
    let pastOrdersUnlisten: (() => void) | null = null;

    if (storeId && user) {
      pastOrdersUnlisten = pastOrderListener(storeId, user, pastOrderNum, handleUpdatePastOrders);
    }
    return () => {
      pastOrdersUnlisten?.();
    };
  }, [storeId, user, pastOrderNum]);

  return { inProgressOrdersList: orders, pastOrders, handleMorePastOrder, pastOrderNum };
}
