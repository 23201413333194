import React from 'react';
import { Box, Image, VStack, Text } from '@chakra-ui/react';
import EmptyStoresImg from '~/assets/images/Stores.png'


// 當優惠券列表為空時，顯示的佔位提示
export default function EmptyStores() {
  return (
    <Box pt={16}>
      <VStack justify="center" align="center" spacing={6}>
        <Image src={EmptyStoresImg} />
        <Text fontSize="sm" color="wsGray.500">
          目前沒有接單中的店家
        </Text>
      </VStack>
    </Box>
  );
}
