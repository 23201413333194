import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { VStack, SimpleGrid } from '@chakra-ui/react';
import { isSameDay, isSameMonth, addMonths, getYear, getMonth } from 'date-fns';

import WeekBar from './WeekBar';
import ControlBar from './ControlBar';
import Day from './Day';

import preOrderDateAtom from '~/recoil/atom/preOrderDate';

import daysGenerator from './daysGenerator';

import { CheckDayTypeFn } from './daysGenerator';
import { DayType } from './type';

type CalendarProps = {
  controlMonth: Date;
  selectedDay: Date | undefined;
  onMonthChange: (date: Date) => void;
  onDaySelect: (day: Date) => void;
  onDayTypeCheck: CheckDayTypeFn;
};

// 月曆，選擇特定日期
export default function Calendar({
  controlMonth,
  selectedDay,
  onDayTypeCheck,
  onMonthChange,
  onDaySelect,
}: CalendarProps) {
  const preOrderDate = useRecoilValue(preOrderDateAtom);
  const dayList = daysGenerator(
    {
      year: getYear(controlMonth),
      month: getMonth(controlMonth),
    },
    onDayTypeCheck,
  );

  useEffect(() => {
    const isCanNotPreOrder = dayList.every((dateInfo) => {
      const { dayType } = dateInfo;
      return dayType === DayType.DISABLE || dayType === DayType.NONE;
    });

    if (isSameMonth(Date.now(), controlMonth) && isCanNotPreOrder) {
      onMonthChange(addMonths(controlMonth, 1));
    }
  }, []);

  useEffect(() => {
    if (preOrderDate) return;
    if (selectedDay) return;
    const initialDay = dayList.filter((dateInfo) => {
      return dateInfo.dayType === DayType.NORMAL;
    })[0];
    onDaySelect(initialDay.date);
  }, [dayList, selectedDay, preOrderDate]);

  return (
    <VStack align="stretch" justify="space-between" spacing={4}>
      <ControlBar date={controlMonth} onChangeDate={onMonthChange} />
      <WeekBar />

      <SimpleGrid columns={7} spacing={1}>
        {dayList.map((dateInfo) => {
          const isSelectedDay = selectedDay ? isSameDay(dateInfo.date, selectedDay) : false;
          return (
            <Day
              key={dateInfo.date.toString()}
              dateInfo={dateInfo}
              isSelectedDay={isSelectedDay}
              onDaySelect={onDaySelect}
            />
          );
        })}
      </SimpleGrid>
    </VStack>
  );
}
