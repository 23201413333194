import { Center, Text, Icon, HStack, Circle, CenterProps } from '@chakra-ui/react';
import { IoReceipt, IoArrowForward } from 'react-icons/io5';

export type OrderHeaderNoticeProps = CenterProps & {
  orderNum: number;
  handleNavigateToOrders: () => void;
};

// 訂單進行中通知：會顯示在菜單頁面的上方，下拉之後會換成fixSection的內容去替代
export default function OrderHeaderNotice({
  orderNum,
  handleNavigateToOrders,
  ...props
}: OrderHeaderNoticeProps) {
  return (
    <Center {...props}>
      <HStack
        borderColor="wsGray.400"
        boxShadow="0px 5px 5px -5px grey"
        bgColor="rgba(255, 255, 255, 0.90)"
        py={1}
        px={3}
        spacing={2}
        borderRadius="5px"
        onClick={handleNavigateToOrders}
      >
        <Circle size="1.875rem" bg="wsBlack" pr={1}>
          <Icon boxSize="1.125rem" color="white" as={IoReceipt} />
        </Circle>
        <Text fontSize="sm" color="wsBlack" fontWeight="bold">
          {`您有${orderNum}筆正在進行中的訂單`}
        </Text>
        <Icon boxSize="1rem" color="wsGray.400" as={IoArrowForward} />
      </HStack>
    </Center>
  );
}
