import useCart from '~/hooks/useCart';

type CartProviderProps = {
  children: React.ReactElement;
};

export default function CartProvider({ children }: CartProviderProps) {
  useCart();

  return <>{children}</>;
}
