import { useRecoilValue } from 'recoil';
import { RadioGroup, Radio, Text, VStack, Divider, HStack, Icon } from '@chakra-ui/react';

import FormInputMessage from '~/components/FormInputMessage';

import mallStoreOrderSettingSelector from '~/recoil/selectors/mallStoreOrderSetting';

import { PickMealTime } from '~/types';

type PickupTimeSelectProps = {
  selectedTime: PickMealTime;
  selectedTimeError: string | null;
  onPickupTimeChange: (time: PickMealTime) => void;
  selectedDayString: string | undefined;
  selectedDurationString: string | undefined;
};

// 儘快取餐或預約
export default function PickupTimeSelect({
  selectedTime,
  selectedTimeError,
  onPickupTimeChange,
  selectedDayString,
  selectedDurationString,
}: PickupTimeSelectProps) {
  const { allowPreOrder } = useRecoilValue(mallStoreOrderSettingSelector);
  return (
    <RadioGroup value={selectedTime} onChange={onPickupTimeChange}>
      <VStack align="stretch" mt={2}>
        <Radio value={PickMealTime.IMMEDIATELY}>
          <Text fontSize="sm">儘快</Text>
        </Radio>
        {selectedTime === PickMealTime.IMMEDIATELY && (
          <Text>
            {selectedDayString && selectedDayString}{' '}
            {selectedDurationString && selectedDurationString}
          </Text>
        )}
        {selectedTimeError && <FormInputMessage type="error" message={selectedTimeError} />}
        <Divider />

        {allowPreOrder && (
          <>
            <Radio value={PickMealTime.RESERVATION}>
              <Text fontSize="sm">預定</Text>
            </Radio>
            {selectedTime === PickMealTime.RESERVATION && (
              <Text>
                {selectedDayString && selectedDayString}{' '}
                {selectedDurationString && selectedDurationString}
              </Text>
            )}
            <Divider />
          </>
        )}
      </VStack>
    </RadioGroup>
  );
}
