import { selector } from 'recoil';

import { categoriesSelector } from '~/recoil/selectors/categories';
import { itemsSelector } from '~/recoil/selectors/items';
import filteredCategoryIdsSelector from '~/recoil/selectors/filteredCategoryIds';

import { CategoryItemInfo } from '~/pages/Menu/components/CategoryItemList';

const categoryItemListSelector = selector<CategoryItemInfo[]>({
  key: 'categoryItemListSelector',
  get: ({ get }) => {
    const filteredCategoryIds = get(filteredCategoryIdsSelector);
    const categories = get(categoriesSelector);
    const items = get(itemsSelector);

    if (!categories || !items || filteredCategoryIds.length === 0) return [];

    return filteredCategoryIds.map((id) => {
      const category = categories[id];
      const { name, items: itemIds } = category;
      return {
        categoryId: id,
        categoryName: name,
        items: itemIds.reduce((acc, id) => {
          const item = items[id];
          const { name, description, price, image, inventory, isSoldOut } = item;
          if (isSoldOut || inventory.currentInventory === 0) {
            return acc;
          }
          acc.push({
            itemId: id,
            itemName: name,
            description,
            price,
            imageUrl: image,
          });
          return acc;
        }, [] as Array<{ itemId: string; itemName: string; description: string; price: number; imageUrl: string }>),
      };
    });
  },
});

export default categoryItemListSelector;
