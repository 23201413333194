import React, { useState } from 'react';
import { Heading, Text, Image, VStack, HStack, Box, AspectRatio } from '@chakra-ui/react';

import ImageSrcError from '~/assets/images/ImageSrcError.png';

export type ItemInfoProps = {
  itemId: string;
  itemName: string;
  description: string;
  price: number;
  imageUrl: string;
  onNavigate?: () => void;
  onItemPositionChange?: () => void;
};

// category item
export default function Item({
  itemName,
  description,
  price,
  imageUrl,
  onNavigate,
  onItemPositionChange,
}: ItemInfoProps) {
  const handleClickItem = () => {
    onNavigate?.();
    onItemPositionChange?.();
  };

  return (
    <HStack
      h={20}
      justify="space-between"
      align="stretch"
      cursor="pointer"
      onClick={handleClickItem}
    >
      <VStack align="flex-start" spacing={0} flex="1">
        <Heading as="h4" fontSize="md" maxW="15rem" isTruncated>
          {itemName}
        </Heading>
        <Text fontSize="xs" color="wsGray.500" flex="1" overflow="hidden">
          {description}
        </Text>
        <Text>NT${price}</Text>
      </VStack>
      {imageUrl ? (
        <AspectRatio w="80px" ratio={1}>
          <Image src={imageUrl} fallbackSrc={ImageSrcError} loading="lazy" borderRadius="base" />
        </AspectRatio>
      ) : (
        <Box w="80px" h="80px" />
      )}
    </HStack>
  );
}
