import { FormControl, FormLabel, VStack, Input } from '@chakra-ui/react';
import FormInputMessage from '~/components/FormInputMessage';

import { TaxInfoState } from '~/pages/Cart/hooks/useTaxInfo';

type TaxInfoFormProps = {
  taxInfoState: TaxInfoState;
  showError: boolean;
  onChangeTitle: (value: string) => void;
  onChangeInvoice: (value: string) => void;
};

export default function TaxInfoForm({
  taxInfoState,
  onChangeInvoice,
  onChangeTitle,
  showError,
}: TaxInfoFormProps) {
  const { title, invoice, errors } = taxInfoState;

  return (
    <VStack>
      <FormControl isRequired>
        <FormLabel>抬頭</FormLabel>
        <Input
          type="text"
          maxLength={20}
          value={title}
          isInvalid={showError && !!errors.title}
          onChange={(e) => onChangeTitle(e.target.value)}
        />
        {showError && errors.title && <FormInputMessage type="error" message={errors.title} />}
      </FormControl>

      <FormControl isRequired>
        <FormLabel>統編</FormLabel>
        <Input
          type="tel"
          maxLength={20}
          value={invoice}
          isInvalid={showError && !!errors.invoice}
          onChange={(e) => onChangeInvoice(e.target.value)}
        />
        {showError && errors.invoice && <FormInputMessage type="error" message={errors.invoice} />}
      </FormControl>
    </VStack>
  );
}
