import { useCallback, useEffect } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

import { categoriesSelector } from '~/recoil/selectors/categories';
import categoryNavListSelector from '~/recoil/selectors/categoryNavList';

import { SelectedCategory, ChangeCategoryFn } from '../types';

const selectedCategoryAtom = atom<SelectedCategory>({
  key: 'selectedCategoryAtom',
  default: {
    isScrollingChange: false,
    value: '',
    label: '',
  },
});

export default function usePickCategory() {
  const categories = useRecoilValue(categoriesSelector);
  const categoryNavList = useRecoilValue(categoryNavListSelector);

  const [selectedCategory, setCategory] = useRecoilState(selectedCategoryAtom);

  const handleChangeCategory: ChangeCategoryFn = useCallback(
    (val: string, isScrollingChange: boolean) => {
      setCategory({
        isScrollingChange,
        value: val,
        label: categories ? categories[val].name : '',
      });
    },
    [categories, setCategory],
  );

  useEffect(() => {
    if (
      categoryNavList.length &&
      !categoryNavList.some(({ value }) => value === selectedCategory.value)
    ) {
      const initialValue = categoryNavList[0].value;
      handleChangeCategory(initialValue, true);
    }
  }, [categoryNavList, selectedCategory, handleChangeCategory]);

  return {
    selectedCategory,
    handleChangeCategory,
  };
}
