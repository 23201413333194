import { selector } from 'recoil';

import { menusSelector, menuIdsSelector, menuSelector } from '~/recoil/selectors/menus';
import mealOrderSettingSelector from '~/recoil/selectors/mealOrderSetting';

import checkOperatingTime from '~/utils/checkOperatingTime';

import { PickMealTime, MealPickUpType } from '~/types';

const filteredMenusIds = selector<
  {
    menuId: string;
    kitchenId: string;
  }[]
>({
  key: 'filteredMenusIdsSelector',
  get: ({ get }) => {
    const menus = get(menusSelector);
    const menuIds = get(menuIdsSelector);
    if (!menus || menuIds.length === 0) return [];

    const { pickMealTime, mealPickupType, preOrderDate } = get(mealOrderSettingSelector);

    return menuIds
      .filter(({ menuId: id }) => {
        return menus[id].allowPublish;
      })
      .filter(({ menuId: id }) => {
        const { schedule } = menus[id];

        if (pickMealTime === PickMealTime.RESERVATION && preOrderDate) {
          return checkOperatingTime(schedule, preOrderDate);
        }

        if (pickMealTime === PickMealTime.RESERVATION && !preOrderDate) {
          return false;
        }

        return checkOperatingTime(schedule);
      })
      .filter(({ menuId: id }) => {
        const { allowDelivery, allowPickup } = menus[id];
        return mealPickupType === MealPickUpType.PICKUP ? allowPickup : allowDelivery;
      });
  },
});

export default filteredMenusIds;
