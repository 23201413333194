import React, { useRef, useEffect } from 'react';

import { Heading, VStack, SimpleGrid, Box } from '@chakra-ui/react';

import Item from './Item';

import useItemPosition from '../../hooks/useItemPosition';
import { useNavigate, useParams } from 'react-router-dom';

import { ChangeCategoryFn, SelectedCategory } from '~/pages/Menu/types';
import { ItemInfoProps } from './Item';
import { ScrollPositionInfo } from '~/hooks/useScroll';

export type CategoryItemInfo = {
  categoryId: string;
  categoryName: string;
  items: ItemInfoProps[];
};

type CategoryItemListProps = {
  kitchenId: string;
  categoryItemInfo: CategoryItemInfo;
  categoryId: string;
  selectedCategory: SelectedCategory;
  positionInfo: ScrollPositionInfo;
  onChangeCategory: ChangeCategoryFn;
};

// 類別品項容器 有切換當前瀏覽類別區塊功能
export default function CategoryItemList({
  kitchenId,
  categoryItemInfo,
  categoryId,
  selectedCategory,
  positionInfo,
  onChangeCategory,
}: CategoryItemListProps) {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const categoryRef = useRef<HTMLDivElement>(null);
  const { handleSetItemPosition } = useItemPosition();

  // When this category is in view place, switch the category
  useEffect(() => {
    if (categoryRef.current) {
      const positionTop = categoryRef.current.getBoundingClientRect().top;
      const { clientHeight, scrollTop } = positionInfo;
      const isInPlace = positionTop > clientHeight / 3 && positionTop < clientHeight / 3 + 100;

      isInPlace && onChangeCategory(categoryId, true);
      if (!isInPlace && !selectedCategory.value) {
        onChangeCategory(categoryId, true);
      }
    }
  }, [positionInfo, categoryId, onChangeCategory, selectedCategory.value]);

  useEffect(() => {
    if (
      categoryRef.current &&
      selectedCategory.value === categoryId &&
      !selectedCategory.isScrollingChange
    ) {
      const { scrollTop, clientHeight } = positionInfo;
      const positionTop = categoryRef.current.getBoundingClientRect().top;
      window.scrollTo({
        top: positionTop + scrollTop - (clientHeight / 3 + 50),
        behavior: 'smooth',
      });
    }
  }, [selectedCategory, categoryId]);

  return (
    <VStack align="stretch" ref={categoryRef}>
      <Heading fontSize="lg">{categoryItemInfo.categoryName}</Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={{ base: 0, md: 10 }} spacingY={2}>
        {categoryItemInfo.items.map((item) => {
          const { itemId, itemName, description, price, imageUrl } = item;
          return (
            <Box key={itemId} py={2} borderBottomWidth="2px" borderColor="wsGray.300">
              <Item
                itemId={itemId}
                itemName={itemName}
                description={description}
                price={price}
                imageUrl={imageUrl}
                onNavigate={() => navigate(`/${storeId}/kitchen/${kitchenId}/item/${itemId}`)}
                onItemPositionChange={handleSetItemPosition}
              />
            </Box>
          );
        })}
      </SimpleGrid>
    </VStack>
  );
}
