import { VStack, Text, Icon, HStack, Box, Heading } from '@chakra-ui/react';

import { BsCircleFill } from 'react-icons/bs';
import { WsOrderStatus } from '~/types';
import PaymentInfo from './PaymentInfo';

type FailOrderNoteProps = {
  noteType: WsOrderStatus;
  createdTime: string;
  total: number;
  isAbandonOrder: boolean;
  isTransactionFailed: boolean;
  reasonForCancelling: Array<string>;
};

export default function FailOrderNote({
  noteType,
  createdTime,
  total,
  isAbandonOrder,
  isTransactionFailed,
  reasonForCancelling,
}: FailOrderNoteProps) {
  if (isAbandonOrder) {
    return (
      <VStack align="stretch" spacing={3}>
        <Box borderRadius="5px" borderWidth="1px" borderColor="wsGray.400">
          <HStack align="stretch" px={2} py={4}>
            <Icon as={BsCircleFill} color="wsRed.600" boxSize="1rem" />
            <VStack spacing={3} align="flex-start">
              <Heading fontSize="sm">客戶棄單</Heading>
              <Text fontSize="xs">取消原因：{reasonForCancelling.join('、')}</Text>
            </VStack>
          </HStack>
        </Box>

        <PaymentInfo
          createdTime={createdTime}
          total={total}
          isAbandonOrder={isAbandonOrder}
          isTransactionFailed={isTransactionFailed}
        />
      </VStack>
    );
  }

  if (isTransactionFailed) {
    return (
      <VStack align="stretch" spacing={3}>
        <Box borderRadius="5px" borderWidth="1px" borderColor="wsGray.400">
          <HStack align="stretch" px={2} py={4}>
            <Icon as={BsCircleFill} color="wsRed.600" boxSize="1rem" />
            <VStack spacing={3} align="flex-start">
              <Heading fontSize="sm">付款失敗</Heading>
              <Text fontSize="xs">取消原因：{reasonForCancelling.join('、')}</Text>
            </VStack>
          </HStack>
        </Box>

        <PaymentInfo
          createdTime={createdTime}
          total={total}
          isAbandonOrder={isAbandonOrder}
          isTransactionFailed={isTransactionFailed}
        />
      </VStack>
    );
  }

  return (
    <VStack align="stretch" spacing={3}>
      <Box borderRadius="5px" borderWidth="1px" borderColor="wsGray.400">
        <HStack align="stretch" px={2} py={4}>
          <Icon as={BsCircleFill} color="wsRed.600" boxSize="1rem" />
          <VStack spacing={3} align="flex-start">
            <Heading fontSize="sm">訂單取消</Heading>
            <Text fontSize="xs">取消原因：{reasonForCancelling.join('、')}</Text>
          </VStack>
        </HStack>
      </Box>

      <PaymentInfo
        createdTime={createdTime}
        total={total}
        isAbandonOrder={isAbandonOrder}
        isTransactionFailed={isTransactionFailed}
      />
    </VStack>
  );
}
