import { HStack, Text, Icon, Box, useDisclosure, VStack, StackDivider } from '@chakra-ui/react';

import { IoCheckmarkSharp, IoChevronDown, IoChevronUp } from 'react-icons/io5';

type CategoriesMenuProps = {
  menuNavList: { label: string; value: string; kitchenId: string }[];
  selectedMenuId: string | null;
  onMenuChange: (selectedMenu: { kitchenId: string; menuId: string }) => void;
};

// 類別選項列表 可導向特定類別
export default function MenuNav({
  menuNavList,
  selectedMenuId,
  onMenuChange,
}: CategoriesMenuProps) {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const selectedMenu = selectedMenuId
    ? menuNavList.find((menu) => menu.value === selectedMenuId)
    : null;
  const handleClickCategory = (menuId: string, kitchenId: string) => {
    onMenuChange({ menuId, kitchenId });
    onClose();
  };

  if (!selectedMenu) return <></>;
  return (
    <Box bgColor="white" borderBottomWidth="2px" borderColor="wsBlack">
      <Text fontSize="xs" color="wsGray.500">
        選擇餐廳
      </Text>
      <HStack
        cursor="pointer"
        justify="space-between"
        pr={4}
        py={2}
        align="center"
        onClick={onToggle}
      >
        <Text>{selectedMenu.label}</Text>
        <Icon as={isOpen ? IoChevronDown : IoChevronUp} boxSize="1.2rem" />
      </HStack>
      {isOpen ? (
        <VStack align="stretch" divider={<StackDivider />} spacing={0}>
          {menuNavList.map(({ label, value, kitchenId }) => {
            return (
              <HStack
                key={value}
                align="center"
                py={2}
                px={4}
                onClick={() => handleClickCategory(value, kitchenId)}
                bgColor={value === selectedMenu.value ? 'wsBlack' : 'white'}
              >
                <Icon
                  as={IoCheckmarkSharp}
                  color={value === selectedMenu.value ? 'white' : 'transparent'}
                  boxSize="1.2rem"
                />
                <Text color={value === selectedMenu.value ? 'white' : 'wsBlack'}>{label}</Text>
              </HStack>
            );
          })}
        </VStack>
      ) : null}
    </Box>
  );
}
