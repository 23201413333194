import { OrderType, PaymentType } from '~/types';

export default function calcSettlement(
  orderType: OrderType,
  paymentType: PaymentType,
  subtotal: number,
  discount: number,
  {
    shippingFee,
    handlingFee,
    costShare,
    freeLimit,
    costShareLimit,
  }: {
    shippingFee: number;
    handlingFee: number;
    costShare: number;
    freeLimit: number;
    costShareLimit: number;
  },
) {
  let calcShippingFee = 0;
  let freightSubsidy = 0;
  let total = subtotal;
  let validDiscountValue = subtotal < discount ? 0 : discount;

  const isDelivery = [OrderType.WS_PREORDER_DELIVERY, OrderType.WS_DELIVERY].includes(orderType);

  if (isDelivery && shippingFee !== null) {
    calcShippingFee = shippingFee + handlingFee;

    if (paymentType !== PaymentType.CASH) {
      freightSubsidy += handlingFee;
    }

    if (subtotal - validDiscountValue >= freeLimit && freeLimit > 0) {
      freightSubsidy = calcShippingFee;
    } else if (subtotal - validDiscountValue >= costShareLimit && costShareLimit > 0) {
      freightSubsidy = Math.min(costShare + freightSubsidy, calcShippingFee);
    }

    total = subtotal - validDiscountValue + calcShippingFee - freightSubsidy;
  }

  return {
    subtotal,
    shippingFee: calcShippingFee,
    freightSubsidy: freightSubsidy,
    total: total,
  };
}
