import { Text, Icon, HStack } from '@chakra-ui/react';

import { IoArrowBackSharp } from 'react-icons/io5';

type FixedBackwardBarProps = {
  title: string;
  onNavigate?: () => void;
};

// 固定頁面頂部的header，可點擊返回某頁（菜單）
export default function FixedBackwardBar({ title, onNavigate }: FixedBackwardBarProps) {
  return (
    <HStack
      onClick={onNavigate}
      pos="sticky"
      zIndex="100"
      top={0}
      align="center"
      w="full"
      px={3}
      py={1.5}
      spacing={1}
      color="wsGray.500"
      bgColor="white"
    >
      <Icon as={IoArrowBackSharp} fontSize="xl" />
      <Text fontSize="xs" fontWeight="bold" color="wsGray.500">
        {title}
      </Text>
    </HStack>
  );
}
