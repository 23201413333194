import storeAtom from '~/recoil/atom/store';
import isGotInitDataAtom from '~/recoil/atom/isGotInitData';
import { useRecoilValue } from 'recoil';

import { Navigate, Outlet } from 'react-router-dom';

import LoadingModal from '~/components/LoadingModal';

export default function RequireGlobalMiddleware() {
  const store = useRecoilValue(storeAtom);
  const isGotInitData = useRecoilValue(isGotInitDataAtom);

  if (!isGotInitData.store) {
    return <LoadingModal isVisible={true} />;
  }

  if (isGotInitData.store && !store) {
    return <Navigate to="/404" />;
  }

  return <Outlet />;
}
