import { VStack, Text, Icon, HStack, Heading } from '@chakra-ui/react';

import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { RiErrorWarningLine } from 'react-icons/ri';

type PaymentInfoProps = {
  createdTime: string;
  total: number;
  isAbandonOrder: boolean;
  isTransactionFailed: boolean;
};

export default function PaymentInfo({
  createdTime,
  total,
  isAbandonOrder,
  isTransactionFailed,
}: PaymentInfoProps) {
  if (isAbandonOrder) {
    return (
      <VStack align="stretch" py={4} px={2} borderRadius="5px" bgColor="wsGray.200">
        <HStack spacing={1}>
          <Icon as={RiErrorWarningLine} color="wsRed.600" boxSize="1.5rem" />
          <Heading fontSize="sm">客戶棄單將不退款</Heading>
        </HStack>

        <Text fontSize="xs">此筆訂單已出餐，視同消費者放棄該餐點，故該單無法退費。</Text>
      </VStack>
    );
  }
  if (isTransactionFailed) {
    return (
      <VStack align="stretch" py={4} px={2} borderRadius="5px" bgColor="wsGray.200">
        <HStack spacing={1}>
          <Icon as={RiErrorWarningLine} color="wsRed.600" boxSize="1.5rem" />
          <Heading fontSize="sm">付款程序未完成</Heading>
        </HStack>

        <Text fontSize="xs">
          此筆訂單因付款失敗，並沒有送出到店家端，建議您換其他信用卡進行付費。
        </Text>
      </VStack>
    );
  }
  return (
    <VStack align="stretch" py={4} px={2} borderRadius="5px" bgColor="wsGray.200">
      <HStack spacing={1}>
        <Icon as={IoCheckmarkCircleOutline} color="wsGreen.500" boxSize="1.5rem" />
        <Heading fontSize="sm">店家已退款</Heading>
      </HStack>

      <Text fontSize="xs">
        店家已從後台操作退款，因綁定的信用卡不同，線上操作退款後，實際款項入帳日期會因各家銀行退款流程不同。
      </Text>

      <VStack align="stretch" p={2} borderRadius="5px" bgColor="white">
        <Text fontSize="xs">訂單交易日期：{createdTime}</Text>
        <Text fontSize="xs">交易金額：＄{total}</Text>
      </VStack>
    </VStack>
  );
}
