import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  FormControl,
  FormLabel,
  InputRightAddon,
  InputGroup,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import Title from '~/components/Title';
import CouponItem from '~/components/CouponItem';
import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';

type PickupCouponModalProps = {};

// （結帳前）店內優惠券使用彈窗，可選擇現有優惠券或輸入優惠碼新增並使用
export default function PickupCouponModal(props: PickupCouponModalProps) {
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });
  const [codeValue, setCodeValue] = React.useState<string>('');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isLargerThan768 ? 'lg' : 'full'}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent m={0} borderRadius="unset" maxH="full">
        <ModalCloseButton />
        <ModalHeader pt={9}>
          <Title title="優惠券" />
        </ModalHeader>

        <ModalBody overflow="auto" px={4} pb={4} pt={0}>
          <VStack align="stretch" spacing={5}>
            <Box>
              <Text fontSize="xs" lineHeight={2}>
                以下為此購物車<Text as="strong">符合標準</Text>
                可使用的優惠卷，想要看更多優惠卷可到「專屬優惠」。注意：優惠卷無法合併使用。
              </Text>
            </Box>
            <Box>
              <VStack align="stretch" spacing={4}>
                <CouponItem />
                <CouponItem />
                <CouponItem />
                <CouponItem />
                <CouponItem />
                <CouponItem />
                <CouponItem />
                <CouponItem />
                <CouponItem />
                <CouponItem />
              </VStack>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter p={0}>
          <Box w="full">
            <Box px={4} py={8} borderTopWidth="1px" borderColor="rgb(0,0,0,.1)">
              <FormControl>
                <FormLabel fontSize="sm" fontWeight="normal">
                  輸入優惠券
                </FormLabel>
                <InputGroup>
                  <Input
                    value={codeValue}
                    onChange={(e) => {
                      setCodeValue(e.target.value);
                    }}
                    placeholder="請輸入優惠碼"
                    variant="unstyled"
                    px={4}
                    borderColor={codeValue.length > 0 ? 'wsBlack' : 'wsGray.400'}
                    borderWidth="1px"
                  ></Input>
                  <InputRightAddon
                    px={5}
                    bgColor={codeValue.length > 0 ? 'wsBlack' : 'wsGray.300'}
                    color={codeValue.length > 0 ? 'white' : 'wsGray.400'}
                    border="1px solid"
                    borderColor={codeValue.length > 0 ? 'wsBlack' : 'wsGray.400'}
                    cursor="pointer"
                  >
                    使用
                  </InputRightAddon>
                </InputGroup>
              </FormControl>
            </Box>
            <Button
              variant="unstyled"
              isFullWidth
              h="auto"
              py={3}
              bgColor="wsBlack"
              borderRadius="unset"
              color="white"
              letterSpacing={2}
            >
              確認
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
