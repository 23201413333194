import {
  VStack,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Center,
  Heading,
  StackDivider,
  Box,
} from '@chakra-ui/react';

export type ConfirmModalProps = {
  title: string;
  message: string;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

// 團購取消Modal：離開團購時或是取消團購的跳出視窗
export default function ConfirmModal({
  title,
  message,
  isOpen,
  onClose,
  onConfirm,
}: ConfirmModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Center mt={4}>
            <VStack spacing={4} justify="space-between" my={3}>
              <Heading fontSize="xl">{title}</Heading>
              <Text fontSize="sm" color="wsGray.500">
                {message}
              </Text>
            </VStack>
          </Center>
        </ModalBody>
        <ModalFooter
          borderTopWidth="1px"
          borderTopColor="admin.gray.300"
          justifyContent="center"
          p={0}
        >
          <HStack align="stretch" w="full" spacing={0} divider={<StackDivider />}>
            <Box as="button" flex="1" p={3} onClick={onClose}>
              <Text fontSize="sm">我想一下</Text>
            </Box>
            <Box as="button" flex="1" p={3} onClick={onConfirm}>
              <Text fontSize="sm">確定</Text>
            </Box>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
