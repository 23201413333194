import { selector, atom, selectorFamily } from 'recoil';
import { Store, Category } from '~/types';
import storeAtom from '~/recoil/atom/store';

export const categoriesSelector = selector<Record<string, Category> | null>({
  key: 'categoriesSelector',
  get: ({ get }) => {
    const store: Store | null = get(storeAtom);
    return store?.categories ? store.categories : null;
  },
});

export const categoryIdsSelector = selector<string[]>({
  key: 'categoryIdsSelector',
  get: ({ get }) => {
    const categories = get(categoriesSelector);
    return categories ? Object.keys(categories) : [];
  },
});
