import { VStack, HStack, Text, Divider, Button, Icon, Center, Box } from '@chakra-ui/react';
import { sumBy } from 'lodash';

import { IoCreateOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { OrderItem, OrderOption } from '~/types';

import formatPriceNumber from '~/utils/formatPriceNumber';

export type MallStoreProductItemProps = {
  item: OrderItem;
  onEdit?: () => void;
  onDelete?: () => void;
  allowEdit?: boolean;
};

export default function MallStoreProductItem({
  item,
  allowEdit = false,
  onEdit,
  onDelete,
}: MallStoreProductItemProps) {
  const { count, note, name, modifiers, price } = item;

  const optionList = modifiers.reduce((acc: Array<OrderOption>, modifier) => {
    return acc.concat(modifier.options);
  }, []);

  const itemTotalPrice = (sumBy(optionList, ({ price }) => price) + price) * count;

  return (
    <VStack align="stretch" p={2}>
      <HStack
        align="flex-start"
        onClick={allowEdit ? onEdit : undefined}
        cursor={allowEdit ? 'pointer' : 'default'}
      >
        <Center borderRadius="5px" bg="wsGray.200" w="2rem" h="2rem">
          <Text>{count}</Text>
        </Center>

        <Text flex="1" py={1} fontWeight="bold" fontSize="sm">
          {name}
        </Text>
        {allowEdit && (
          <Center py={1} cursor="pointer">
            <Icon as={IoCreateOutline} boxSize="1.2rem" />
          </Center>
        )}
      </HStack>

      {optionList.map(({ name, id, price }, index) => (
        <HStack align="center" justify="space-between" w="full">
          <Text key={`${id}_${index}`} color="wsGray.500" fontSize="xs">
            {name}
          </Text>
          {price !== 0 && (
            <Text key={`${id}_${index}`} color="wsGray.500" fontSize="xs">
              +{formatPriceNumber(price)}
            </Text>
          )}
        </HStack>
      ))}

      {note && (
        <Text color="wsGray.500" fontSize="xs">
          {note}
        </Text>
      )}

      <Divider />

      <HStack justify="space-between">
        <Box flex="1">
          {allowEdit && (
            <Button
              p={0}
              pr={4}
              h="2rem"
              w="6rem"
              bg="white"
              variant="ghost"
              size="xs"
              leftIcon={<Icon as={IoRemoveCircleOutline} boxSize="1.2rem" color="wsRed.600" />}
              iconSpacing={1}
              fontWeight="normal"
              color="wsGray.500"
              onClick={onDelete}
              letterSpacing={1}
              _hover={{ bg: 'wsRed.200' }}
              _active={{ bg: 'wsRed.200' }}
            >
              刪除餐點
            </Button>
          )}
        </Box>

        <Text fontSize="xs" fontWeight="bold">
          {formatPriceNumber(itemTotalPrice)}
        </Text>
      </HStack>
    </VStack>
  );
}
