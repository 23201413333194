import React from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { VStack, HStack, Flex, Text, Icon, StackProps, StackDivider } from '@chakra-ui/react';

import { AiOutlineCar, AiOutlineClockCircle } from 'react-icons/ai';
import { BiMap } from 'react-icons/bi';
import { GrLocation } from 'react-icons/gr';

import { updateCartData } from '~/firebase/updateData';

import authAtom from '~/recoil/atom/auth';
import mallStoreOrderSettingSelector from '~/recoil/selectors/mallStoreOrderSetting';

import useOrderSetting from '~/hooks/useOrderSetting';
import useModalControl from '~/hooks/useModalControl';

import getPreOrderDateString from '~/utils/getPreOrderDateString';
import convertMealPickupType from '~/utils/convertMealPickupType';

import { MealPickUpType, PickMealTime } from '~/types';
import { Pickup, Location } from '~/icons';

// 顯示部分訂餐資訊
export default function MealPickUpSection() {
  const { storeId } = useParams();
  const auth = useRecoilValue(authAtom);
  const { changeModalOpen } = useModalControl();
  const { allowPreOrder } = useRecoilValue(mallStoreOrderSettingSelector);
  const {
    mealOrderSetting: { mealPickupType, location, pickMealTime, preOrderDate },
    handleMealPickupTypeChange,
  } = useOrderSetting();

  const address = location?.address;

  const handleMealPickupClick = async (mealPickupType: MealPickUpType) => {
    if (!auth) {
      handleMealPickupTypeChange(mealPickupType);
      return;
    }

    try {
      await updateCartData(storeId, auth, {
        orderType: convertMealPickupType(mealPickupType, pickMealTime),
      });

      handleMealPickupTypeChange(mealPickupType);
    } catch (err) {
      console.log('update error');
    }
  };

  const handlePickupSettingClick = () => {
    changeModalOpen('isPickupSettingOpen', true);
  };

  const handleIsActiveBtn = (tabType: MealPickUpType) => {
    const btnStyle: StackProps = {
      bgColor: 'white',
      color: 'wsBlack',
    };
    const btnActiveStyle: StackProps = {
      bgColor: 'wsBlack',
      color: 'white',
      boxShadow: '0px 0px 10px rgba(51, 51, 51, 0.3)',
    };

    return tabType === mealPickupType ? btnActiveStyle : btnStyle;
  };

  return (
    <VStack align="stretch" borderRadius="5px" bgColor="wsGray.200" p={2}>
      {/* <Flex borderRadius="5px" borderWidth="1px" borderColor="wsGray.400" bgColor="white">
        <HStack
          onClick={() => handleMealPickupClick(MealPickUpType.DELIVERY)}
          flex="1"
          borderRadius="5px"
          justify="center"
          py={2}
          {...handleIsActiveBtn(MealPickUpType.DELIVERY)}
        >
          <Icon boxSize="1.2rem" as={AiOutlineCar} />
          <Text fontWeight="bold" color="inherit">
            外送
          </Text>
        </HStack>
        <HStack
          onClick={() => handleMealPickupClick(MealPickUpType.PICKUP)}
          flex="1"
          borderRadius="5px"
          justify="center"
          py={2}
          {...handleIsActiveBtn(MealPickUpType.PICKUP)}
        >
          <Icon boxSize="1.2rem" as={Pickup} />
          <Text fontWeight="bold" color="inherit">
            自取
          </Text>
        </HStack>
      </Flex> */}
      <HStack
        onClick={() => handleMealPickupClick(MealPickUpType.PICKUP)}
        flex="1"
        borderRadius="5px"
        justify="center"
        py={2}
        {...handleIsActiveBtn(MealPickUpType.PICKUP)}
      >
        <Icon boxSize="1.2rem" as={Pickup} />
        <Text fontWeight="bold" color="inherit">
          自取
        </Text>
      </HStack>

      <HStack onClick={handlePickupSettingClick} divider={<StackDivider />} cursor="pointer">
        {allowPreOrder && pickMealTime === PickMealTime.RESERVATION && preOrderDate ? (
          <HStack>
            <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
            <Text w="4rem" whiteSpace="nowrap">
              {getPreOrderDateString(preOrderDate, mealPickupType)}
            </Text>
          </HStack>
        ) : (
          <HStack>
            <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
            <Text whiteSpace="nowrap" overflow="hidden">
              儘快取餐
            </Text>
          </HStack>
        )}
      </HStack>
    </VStack>
  );
}
