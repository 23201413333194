import { selector, atom, selectorFamily } from 'recoil';
import { Store, Menu } from '~/types';
import storeAtom from '~/recoil/atom/store';

export const menusSelector = selector<Record<string, Menu> | null>({
  key: 'menusSelector',
  get: ({ get }) => {
    const store: Store | null = get(storeAtom);
    return store?.menus ? store.menus : null;
  },
});

export const menuIdsSelector = selector<{ menuId: string; kitchenId: string }[]>({
  key: 'menuIdsSelector',
  get: ({ get }) => {
    const menus = get(menusSelector);
    return menus
      ? Object.entries(menus).map(([menuId, { kitchenId }]) => ({ menuId, kitchenId }))
      : [];
  },
});

export const menuSelector = selectorFamily({
  key: 'menuSelector',
  get:
    (menuId: string | null) =>
    ({ get }) => {
      if (!menuId) return null;
      const menus = get(menusSelector);
      if (!menus) return null;
      const menu = menus[menuId];

      return menu;
    },
});
