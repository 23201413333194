import React from 'react';
import { Center, Text, Icon, VStack, HStack, Link } from '@chakra-ui/react';
import ContentContainer from '~/container/Content';

import { SiLine } from 'react-icons/si';
import { AiFillInstagram, AiFillFacebook, AiFillPhone } from 'react-icons/ai';
import { ImLocation2 } from 'react-icons/im';

import { useRecoilValue } from 'recoil';
import storeAtom from '~/recoil/atom/store';

export default function Footer() {
  const store = useRecoilValue(storeAtom);

  if (!store) {
    return <></>;
  }

  const { name, phoneNumber, location } = store;

  return (
    <Center bg="wsBlack" py={3}>
      <ContentContainer px={{ base: 4, md: 0 }} bg="wsBlack">
        <VStack align="stretch">
          <Text fontSize="sm" fontWeight="bold" color="white">
            {name}
          </Text>

          {/** social media link */}
          {/* <HStack spacing={3}>
            <Icon as={SiLine} color="white" boxSize="1.2rem" />
            <Icon as={AiFillFacebook} color="white" boxSize="1.2rem" />
            <Icon as={AiFillInstagram} color="white" boxSize="1.2rem" />
          </HStack> */}

          <HStack>
            <Icon as={AiFillPhone} color="white" boxSize="1rem" />
            <Text fontSize="sm" color="white">
              {phoneNumber}
            </Text>
          </HStack>

          <HStack>
            <Icon as={ImLocation2} color="white" boxSize="1rem" />
            <Text fontSize="sm" color="white">
              {location.address}
            </Text>
          </HStack>

          <HStack spacing={0.5}>
            <Text fontSize="xs" color="#787878">
              登入後代表同意weserve
            </Text>
            <Link
              letterSpacing={0.8}
              fontSize="xs"
              color="#A4A4A4"
              href="https://www.weserve.tw/privacy/"
              isExternal
            >
              隱私權政策
            </Link>
            <Text fontSize="xs" color="#787878">
              與
            </Text>
            <Link
              letterSpacing={0.8}
              fontSize="xs"
              color="#A4A4A4"
              href="https://www.weserve.tw/terms/"
              isExternal
            >
              利用章規
            </Link>
          </HStack>

          <Text fontSize="xs" color="gray.400">
            © 2021 GLOBAL MALL CO., LTD. All Rights Reserved. weserve . version 1.0.0
          </Text>
        </VStack>
      </ContentContainer>
    </Center>
  );
}
