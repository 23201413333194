import {
  VStack,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Center,
  Heading,
  Box,
  ModalFooter,
} from '@chakra-ui/react';

type OrderPaymentModalProps = {
  last4Digits?: string;
  createdAt: string;
  isOpen: boolean;
  onClose: () => void;
};

// 信用卡付款明細
export default function OrderPaymentModal({
  last4Digits,
  createdAt,
  isOpen,
  onClose,
}: OrderPaymentModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Center mt={4}>
            <VStack spacing={4} justify="space-between" my={3}>
              <Heading fontSize="xl">付款明細</Heading>
              {last4Digits && (
                <Text fontSize="sm" color="wsGray.500">
                  信用卡卡號末4碼：{last4Digits}
                </Text>
              )}
              <Text fontSize="sm" color="wsGray.500">
                刷卡時間：{createdAt}
              </Text>
            </VStack>
          </Center>
        </ModalBody>
        <ModalFooter borderTopWidth="1px" borderTopColor="admin.gray.300" justifyContent="center">
          <HStack align="stretch" w="full" spacing={0}>
            <Box as="button" flex="1" onClick={onClose}>
              <Text fontSize="sm">瞭解了</Text>
            </Box>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
