import { Divider, VStack, Text } from '@chakra-ui/react';
import React from 'react';

// 公告內文：公告的詳細文章內容（展開公告才會看到詳細內容）
export default function Content() {
  return (
    <VStack>
      <Divider borderWidth="1px" borderColor="wsGray.400" />
      <VStack spacing={6} p={2} align="stretch">
        <VStack align="stretch" spacing={0}>
          <Text>1.到貨付款的現金整數放信封</Text>
          <Text>不找零，避免現金接觸。</Text>
        </VStack>
        <VStack align="stretch" spacing={0}>
          <Text>2.確認餐點放置位置</Text>
          <Text>
            醫院、大樓等地，司機會先聯繫用戶確認放置地點，至一樓/大門取餐，避免接觸。
            若需送上樓，司機先與收件者聯繫確認，放置於門外並拍照。 後續有爭議則以通聯記錄和拍照為證
          </Text>
        </VStack>
        <VStack align="stretch" spacing={0}>
          <Text>3.關閉電子簽收功能</Text>
          <Text>目前電子簽收功能已全台暫時關閉</Text>
        </VStack>
      </VStack>
    </VStack>
  );
}
