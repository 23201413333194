import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';

import { differenceInMinutes, startOfDay, isSameDay } from 'date-fns';
import { range } from 'lodash';

import operatingTimeSelector from '~/recoil/selectors/operatingTime';
import { OperatingTime } from '~/recoil/selectors/operatingTime';

import { Week } from '~/types';

type useDurationParams = {
  selectedDay: Date | undefined;
  timeRange: number;
  preOrderAfterDate: Date | undefined;
  preOrderBeforeDate: Date | undefined;
};

export default function useDuration({
  selectedDay,
  timeRange,
  preOrderAfterDate,
  preOrderBeforeDate,
}: useDurationParams) {
  const operatingTime = useRecoilValue(operatingTimeSelector);

  const filteredDaySchedule = useMemo(() => {
    if (!selectedDay || !operatingTime) return;

    const nowDate = Date.now();
    const allWeekDayTitle = Object.keys(Week);
    const selectedDayWeekDayTitle = allWeekDayTitle[selectedDay.getDay()];
    const daySchedule = operatingTime[selectedDayWeekDayTitle as Week];

    if (!daySchedule) return;

    if (isSameDay(selectedDay, nowDate)) {
      const startDay = startOfDay(nowDate);
      const startToNowMinutes = differenceInMinutes(nowDate, startDay);

      return daySchedule?.filter(({ open, close }) => {
        return close > startToNowMinutes;
      });
    } else {
      return daySchedule;
    }
  }, [operatingTime, selectedDay]);

  const durationOptionList = useMemo(() => {
    if (!filteredDaySchedule) return;

    const durationOptionTwoArr = filteredDaySchedule.map(({ open, close }) => {
      const timeRanges = range(open, close, timeRange);

      const isPreOrderAfterDay =
        selectedDay && preOrderAfterDate && isSameDay(selectedDay, preOrderAfterDate);
      const isPreOrderBeforeDay =
        selectedDay && preOrderBeforeDate && isSameDay(selectedDay, preOrderBeforeDate);
      const startDayPreOrderMinutes =
        preOrderAfterDate && differenceInMinutes(preOrderAfterDate, startOfDay(preOrderAfterDate));
      const endDayPreOrderMinutes =
        preOrderBeforeDate &&
        differenceInMinutes(preOrderBeforeDate, startOfDay(preOrderBeforeDate));

      const filteredTimeRange = timeRanges.filter((durationMinutes) => {
        if (!startDayPreOrderMinutes || !endDayPreOrderMinutes) return false;

        if (isPreOrderAfterDay) {
          return durationMinutes >= startDayPreOrderMinutes;
        }

        if (isPreOrderBeforeDay) {
          return durationMinutes <= endDayPreOrderMinutes;
        }

        return true;
      });

      const timeStringFn = (time: number) => {
        const hour = Math.floor(time / 60);
        const newHour = hour === 12 ? 12 : Math.floor(time / 60) % 12;
        // const newHour = Math.floor(time / 60) % 12;
        const minute = time % 60;
        const timeString = `${newHour < 10 ? '0' + newHour.toString() : newHour.toString()}:${
          minute < 10 ? '0' + minute.toString() : minute.toString()
        }`;
        return timeString;
      };

      const durationOption = filteredTimeRange.map((time) => {
        let clock = 'AM';
        const hour = Math.floor(time / 60);
        if (hour >= 12) {
          clock = 'PM';
        }
        const option = {
          durationString: `${timeStringFn(time)}~${timeStringFn(time + timeRange)} ${clock}`,
          value: time,
        };
        return option;
      });

      return durationOption;
    });

    const durationOptionList = durationOptionTwoArr?.reduce((acc, currentOptionArr) => {
      return acc.concat(currentOptionArr);
    }, []);

    return durationOptionList;
  }, [filteredDaySchedule, preOrderAfterDate, preOrderBeforeDate, selectedDay, timeRange]);

  return {
    durationOptionList,
  };
}
