import { selectorFamily } from 'recoil';
import kitchenCartItemsAtom from '../atom/kitchenCartItems';
import { CartItem } from '~/types';

const cartItem = selectorFamily<
  CartItem | null,
  { itemId: string; kitchenId: string; index: number }
>({
  key: 'cartItem',
  get:
    ({ kitchenId, itemId, index }) =>
    ({ get }) => {
      const kitchenCartItems = get(kitchenCartItemsAtom);

      if (isNaN(index)) {
        return null;
      }

      return (
        kitchenCartItems
          .find((kitchenGroup) => kitchenGroup.kitchenId === kitchenId)
          ?.items.find(({ id }, i) => itemId === id && index === i) || null
      );
    },
});

export default cartItem;
