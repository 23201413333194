import { VStack, Text, Divider } from '@chakra-ui/react';

import { OrderItem } from '~/types';

import ProductItem from './MallStoreProductItem';

type MallStoreProductItemsProps = {
  order: {
    name: string;
    kitchenId: string;
    items: OrderItem[];
  };
  allowEdit?: boolean;
  onEdit?: (kitchenId: string, itemId: string, index: number) => void;
  onDelete?: (kitchenId: string, itemId: string, index: number) => void;
};

export default function MallStoreProductItems({
  allowEdit,
  order,
  onDelete,
  onEdit,
}: MallStoreProductItemsProps) {
  const { name, kitchenId, items } = order;

  return (
    <VStack
      align="stretch"
      borderRadius="5px"
      borderWidth="1px"
      borderColor="wsGray.400"
      spacing={1}
      my={4}
    >
      <VStack align="stretch" p={2} pb={0}>
        <Text fontSize="xs" fontWeight="bold">
          {name}
        </Text>
        <Divider />
      </VStack>

      <VStack align="stretch" spacing={1} pb={1} divider={<Divider borderColor="wsGray.400" />}>
        {items.map((item, index) => {
          return (
            <ProductItem
              key={`${item.id}_${index}`}
              item={item}
              allowEdit={allowEdit}
              onDelete={() => onDelete?.(kitchenId, item.id, index)}
              onEdit={() => onEdit?.(kitchenId, item.id, index)}
            />
          );
        })}
      </VStack>
    </VStack>
  );
}
