import { RadioGroup, Radio, HStack } from '@chakra-ui/react';

import { TaxType } from '~/types';

type TaxRadioGroupProps = {
  taxType: TaxType;
  onChangeTaxType?: (taxType: TaxType) => void;
};

// 發票類型選擇
export default function TaxRadioGroup({ taxType, onChangeTaxType }: TaxRadioGroupProps) {
  const handleChangeTaxType = (value: TaxType) => {
    onChangeTaxType?.(value);
  };

  return (
    <RadioGroup onChange={handleChangeTaxType} value={taxType}>
      <HStack spacing={4}>
        <Radio value={TaxType.PERSONAL}>個人發票</Radio>
        <Radio value={TaxType.COMPANY}>公司發票</Radio>
      </HStack>
    </RadioGroup>
  );
}
