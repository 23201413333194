import { useCallback, useState } from 'react';
import { VStack, Divider, Icon, Button, HStack, Text, useToast } from '@chakra-ui/react';

import { useNavigate, useParams } from 'react-router-dom';

import PageContainer from '~/container/PageContainer';

import PageTitle from '~/components/PageTitle';

import {
  PickupSettingInfoSection,
  PaymentTypeSelector,
  ContactInfo,
  CheckoutBar,
  TaxRadioGroup,
  TaxInfoForm,
  CheckErrorModal,
  EInvoiceEmail,
  NonItemNotice,
  PaymentRedirectModal,
} from './components';
import TotalPriceSection from '~/components/TotalPriceSection';
import LoginForm from '~/components/LoginForm';
import LoadingModal from '~/components/LoadingModal';
import PickupSettingSimpleModal from '~/components/PickupSettingSimpleModal';
// import TaxRadioGroup from '~/components/TaxRadioGroup';
import MallStoreProductItems from '~/components/MallStoreProductItems';

import SectionContainer from '~/container/SectionContainer';

import cartSelector from '~/recoil/selectors/cart';
import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import authAtom from '~/recoil/atom/auth';
import storeAtom from '~/recoil/atom/store';
import modalControlAtom from '~/recoil/atom/modalControl';
import emailAtom from '~/recoil/atom/email';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { debounce } from 'lodash';

import {
  IoPersonOutline,
  IoCashOutline,
  IoRestaurantOutline,
  IoReaderOutline,
  IoCloseCircle,
} from 'react-icons/io5';

import { CartStatus, TaxType } from '~/types';

import useTaxType from './hooks/useTaxType';
import usePaymentType from './hooks/usePaymentType';
import useCartSettlement from './hooks/useCartSettlement';
import useCheckout, { CheckoutErrorType } from './hooks/useCheckout';
import useKitchenCartItems from '~/hooks/useKitchenCartItems';

import { updateCartData } from '~/firebase/updateData';

import convertOrderType from '~/utils/convertOrderType';
import { useEffect } from 'react';
import useTaxInfo from './hooks/useTaxInfo';
import useEmail from './hooks/useEmail';

export default function Cart() {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);
  const setModalControl = useSetRecoilState(modalControlAtom);
  const setEmail = useSetRecoilState(emailAtom);
  const store = useRecoilValue(storeAtom);
  const auth = useRecoilValue(authAtom);

  const cart = useRecoilValue(cartSelector);

  const [showError, setShowError] = useState(false);

  // control taxType
  const { handleChangeTaxType } = useTaxType();

  const { taxInfoState, handleUpdateInvoice, handleUpdateTitle } = useTaxInfo();

  const { emailState, handleUpdateEmail } = useEmail(cart.email);

  // control paymentType
  const { paymentTypes, handleUpdatePaymentType } = usePaymentType();

  const { subtotal, shippingFee, freightSubsidy, total, discount } = useCartSettlement();

  const { handleDeleteItems } = useKitchenCartItems();

  const { handleCheckOut, checkoutError, isLoading, isOpenPaymentRedirect, handleClearError } =
    useCheckout();

  const handleOpenPickupSettingModal = () => {
    setModalControl((prev) => ({ ...prev, isPickupSettingOpen: true }));
  };

  const handleUpdateEmailCallback = useCallback(
    debounce(async (value: string) => {
      if (!auth) {
        setEmail(value);
        return;
      }

      if (storeId) {
        try {
          await updateCartData(storeId, auth, {
            email: value,
          });
        } catch (err) {
          console.error('email update error', err);
        }
      }
    }, 500),
    [storeId, auth],
  );

  const { paymentType, taxType, orderType, preOrderDate, status, orders } = cart;

  const [mealPickupType, pickMealTime] = convertOrderType(orderType);

  const toast = useToast();

  const handleSubmit = () => {
    if (Object.keys(emailState.errors).length > 0) {
      setShowError(true);
      return;
    }

    if (taxType === TaxType.COMPANY && Object.keys(taxInfoState.errors).length > 0) {
      setShowError(true);
      return;
    }

    handleCheckOut();
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.failed) {
      toast({
        position: 'top',
        title: '付款方式失敗',
        status: 'error',
        duration: 3000,
        isClosable: true,
        render: () => {
          return (
            <HStack>
              <HStack
                justify="center"
                p={2}
                bg="white"
                borderRadius="5px"
                ml={20}
                boxShadow=" 0px 0px 10px rgba(0, 0, 0, 0.25)"
              >
                <Icon as={IoCloseCircle} color="wsRed.600" boxSize="1.5rem" />
                <Text fontSize="lg">付款方式失敗</Text>
              </HStack>
            </HStack>
          );
        },
      });
    }
  }, []);

  return (
    <PageContainer hideHeader hideFooter>
      <PageTitle title="訂單" onNavigate={() => navigate(`/${storeId}`)} />
      {isLargerThan768 ? (
        <VStack spacing={5} align="stretch">
          {/* <OrderTypeBar mealPickupType={mealPickupType} /> */}

          <HStack align="flex-start" spacing={4}>
            <VStack flex="1" spacing={5} align="stretch">
              <PickupSettingInfoSection
                pickMealTime={pickMealTime}
                preOrderDate={preOrderDate}
                prepareMinutes={store?.orderPrepareMinutes || 30}
                allowEdit={true}
                onSectionClick={handleOpenPickupSettingModal}
                allowOrder={store?.allowOrders || false}
                allowPreOrder={store?.preOrderSetting.allowPreOrder || false}
              />

              <Divider />

              <SectionContainer
                title="餐點"
                icon={<Icon as={IoRestaurantOutline} boxSize="1.5rem" />}
              >
                {orders.map((order) => (
                  <MallStoreProductItems
                    allowEdit
                    order={order}
                    onDelete={handleDeleteItems}
                    onEdit={(kitchenId, itemId, index) =>
                      navigate(`/${storeId}/kitchen/${kitchenId}/item/${itemId}/edit/${index}`)
                    }
                  />
                ))}
                {orders.length === 0 && (
                  <NonItemNotice onNavigate={() => navigate(`/${storeId}`)} />
                )}
              </SectionContainer>

              <SectionContainer
                title="聯絡資訊"
                icon={<Icon as={IoPersonOutline} boxSize="1.5rem" />}
              >
                {auth ? (
                  <ContactInfo name={auth.displayName || ''} phoneNumber={auth.phoneNumber || ''} />
                ) : (
                  <LoginForm />
                )}
              </SectionContainer>

              <Divider />

              <SectionContainer
                title="電子發票"
                icon={<Icon as={IoReaderOutline} boxSize="1.5rem" />}
              >
                <EInvoiceEmail
                  emailState={emailState}
                  onUpdateEmail={(value) => {
                    handleUpdateEmail(value);
                    handleUpdateEmailCallback(value);
                  }}
                  showError={showError}
                />
              </SectionContainer>

              <TaxRadioGroup taxType={taxType} onChangeTaxType={handleChangeTaxType} />
              {taxType === TaxType.COMPANY && (
                <TaxInfoForm
                  taxInfoState={taxInfoState}
                  onChangeInvoice={handleUpdateInvoice}
                  onChangeTitle={handleUpdateTitle}
                  showError={showError}
                />
              )}
              <Text fontSize="sm">
                weserve 點餐系統僅提供發票資訊給店家，實際開立發票方式由店家決定。
              </Text>
              <Divider />
            </VStack>

            <VStack flex="1" spacing={0} align="stretch" pos="sticky" top={5}>
              <VStack align="stretch" p={4} spacing={4}>
                <SectionContainer
                  icon={<Icon as={IoCashOutline} boxSize="1.5rem" />}
                  title="付款方式"
                >
                  <PaymentTypeSelector
                    selectedPaymentType={paymentType}
                    paymentTypeList={paymentTypes}
                    onChangePaymentType={handleUpdatePaymentType}
                  />
                </SectionContainer>

                <TotalPriceSection
                  orderType={orderType}
                  subtotal={subtotal}
                  shippingFee={shippingFee}
                  freightSubsidy={freightSubsidy}
                  total={total}
                  discount={discount}
                />
              </VStack>
              <CheckoutBar total={total} onCheckout={handleSubmit} title="確認下單" />
            </VStack>
          </HStack>
        </VStack>
      ) : (
        <>
          <VStack py={5} px={4} spacing={5} align="stretch">
            {/* <OrderTypeBar mealPickupType={mealPickupType} /> */}

            <PickupSettingInfoSection
              pickMealTime={pickMealTime}
              preOrderDate={preOrderDate}
              prepareMinutes={store?.orderPrepareMinutes || 30}
              allowEdit={true}
              onSectionClick={handleOpenPickupSettingModal}
              allowOrder={store?.allowOrders || false}
              allowPreOrder={store?.preOrderSetting.allowPreOrder || false}
            />

            <Divider />

            <SectionContainer
              title="餐點"
              icon={<Icon as={IoRestaurantOutline} boxSize="1.5rem" />}
            >
              {orders.map((order) => (
                <MallStoreProductItems
                  allowEdit
                  order={order}
                  onDelete={handleDeleteItems}
                  onEdit={(kitchenId, itemId, index) =>
                    navigate(`/${storeId}/kitchen/${kitchenId}/item/${itemId}/edit/${index}`)
                  }
                />
              ))}
              {orders.length === 0 && <NonItemNotice onNavigate={() => navigate(`/${storeId}`)} />}
            </SectionContainer>

            <SectionContainer
              title="聯絡資訊"
              icon={<Icon as={IoPersonOutline} boxSize="1.5rem" />}
            >
              {auth ? (
                <ContactInfo name={auth.displayName || ''} phoneNumber={auth.phoneNumber || ''} />
              ) : (
                <LoginForm />
              )}
            </SectionContainer>

            <Divider />
            <SectionContainer
              title="電子發票"
              icon={<Icon as={IoReaderOutline} boxSize="1.5rem" />}
            >
              <EInvoiceEmail
                emailState={emailState}
                onUpdateEmail={(value) => {
                  handleUpdateEmail(value);
                  handleUpdateEmailCallback(value);
                }}
                showError={showError}
              />
            </SectionContainer>

            <TaxRadioGroup taxType={taxType} onChangeTaxType={handleChangeTaxType} />
            {taxType === TaxType.COMPANY && (
              <TaxInfoForm
                taxInfoState={taxInfoState}
                onChangeInvoice={handleUpdateInvoice}
                onChangeTitle={handleUpdateTitle}
                showError={showError}
              />
            )}
            <Text fontSize="sm">
              weserve 點餐系統僅提供發票資訊給店家，實際開立發票方式由店家決定。
            </Text>
            <Divider />

            <SectionContainer icon={<Icon as={IoCashOutline} boxSize="1.5rem" />} title="付款方式">
              <PaymentTypeSelector
                selectedPaymentType={paymentType}
                paymentTypeList={paymentTypes}
                onChangePaymentType={handleUpdatePaymentType}
              />
            </SectionContainer>

            <TotalPriceSection
              orderType={orderType}
              subtotal={subtotal}
              shippingFee={shippingFee}
              freightSubsidy={freightSubsidy}
              total={total}
              discount={discount}
            />
          </VStack>
          <CheckoutBar total={total} title="確認下單" onCheckout={handleSubmit} />
        </>
      )}
      <PickupSettingSimpleModal />
      <CheckErrorModal
        isOpen={checkoutError.type !== CheckoutErrorType.NONE}
        title={checkoutError.title}
        message={checkoutError.message}
        onClose={handleClearError}
      />
      <PaymentRedirectModal isOpen={isOpenPaymentRedirect} />
      <LoadingModal isVisible={isLoading || status === CartStatus.ORDERING} />
    </PageContainer>
  );
}
