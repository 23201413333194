import React from 'react';
import { VStack } from '@chakra-ui/react';
import PageContainer from '~/container/PageContainer';
import { UserInfo, UserDiscount, DiscountTabBar, EmptyDiscount } from './components';
import BackwardBar from '~/components/BackwardBar';
import Title from '~/components/Title';

import PickupCouponModal from '~/components/PickupCouponModal';

export default function Discount() {
  return (
    <>
      <PageContainer hideHeader hideFooter>
        <BackwardBar title="回去訂單" />
        <Title title="專屬優惠" />
        <VStack align="stretch" p={4} spacing={3}>
          <UserInfo />
          <UserDiscount earnedDiscount={100} currentPoint={30} />
          <DiscountTabBar />
          <EmptyDiscount />
        </VStack>
      </PageContainer>
      <PickupCouponModal />
    </>
  );
}
