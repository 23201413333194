import { selector } from 'recoil';

import storeAtom from '~/recoil/atom/store';

import { Week } from '~/types';

type MallStoreOrderSetting = {
  allowOrders: boolean;
  allowRealTimeOrder: boolean;
  orderNumCount: number | null;
  orderNumLimit: number | null;
  orderPrepareMinutes: number | null;
  preOrderSetting: {
    allowPreOrder: boolean;
    preOrderAfter: number;
    preOrderAfterUnit?: string | undefined;
    preOrderBefore: number;
    preOrderBeforeUnit?: string | undefined;
  } | null;
  allowPreOrder: boolean;
  operatingTime: Partial<
    Record<
      Week,
      {
        open: number;
        close: number;
      }[]
    >
  > | null;
};

const mallStoreOrderSetting = selector<MallStoreOrderSetting>({
  key: 'mallStoreOrderSettingSelector',
  get: ({ get }) => {
    const store = get(storeAtom);

    const allowOrders = store?.allowOrders || false;
    const allowRealTimeOrder = store?.allowRealTimeOrder || false;
    const orderNumCount = store?.orderNumCount || null;
    const orderNumLimit = store?.orderNumLimit || null;
    const orderPrepareMinutes = store?.orderPrepareMinutes || null;
    const preOrderSetting = store?.preOrderSetting || null;
    const allowPreOrder = preOrderSetting?.allowPreOrder || false;
    const operatingTime = store?.operatingTime || null;

    return {
      allowOrders,
      allowRealTimeOrder,
      orderNumCount,
      orderNumLimit,
      orderPrepareMinutes,
      preOrderSetting,
      allowPreOrder,
      operatingTime,
    };
  },
});

export default mallStoreOrderSetting;
