import React from 'react';
import { Box, Image, VStack, Text, Center, Button, HStack, Icon } from '@chakra-ui/react';
import Orders from '~/assets/images/Orders.png';
import { BtnVariant } from '~/customTheme/components/Button';

import {IoRestaurantOutline} from 'react-icons/io5';

type EmptyOrdersProps = {
  onNavigate?: () => void;
};

// 當訂單列表為空時，顯示的佔位提示
export default function EmptyOrders({ onNavigate }: EmptyOrdersProps) {
  return (
    <Box pt={16}>
      <Center>
        <VStack justify="space-between" spacing={6} py={4}>
          <Image src={Orders} />
          <Text fontSize="sm" color="wsGray.500">
            目前沒有訂單
          </Text>
          <Button variant={BtnVariant.SOLID} color="white" width="10rem" onClick={onNavigate}>
            <HStack>
              <Icon as={IoRestaurantOutline} boxSize="1.25rem" />
              <Text fontSize="sm" fontWeight="bold" color="white" letterSpacing={2}>
                去點餐
              </Text>
            </HStack>
          </Button>
        </VStack>
      </Center>
    </Box>
  );
}
