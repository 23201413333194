import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import customTheme from '~/customTheme';
import { BrowserRouter } from 'react-router-dom';
import RouteContainer from '~/routes';

import { RecoilRoot } from 'recoil';

import Fonts from '~/customTheme/Fonts';
import MediaQuery from '~/globalScript/MediaQuery';


function App() {
  return (
    <RecoilRoot>
      <ChakraProvider theme={customTheme}>
        <Fonts />
        <MediaQuery />
        <BrowserRouter>
          <RouteContainer />
        </BrowserRouter>
      </ChakraProvider>
    </RecoilRoot>
  );
}

export default App;
