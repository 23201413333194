import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { VStack, Text, SimpleGrid, useDisclosure } from '@chakra-ui/react';

import { IoReceiptOutline } from 'react-icons/io5';

import PageContainer from '~/container/PageContainer';

import PageTitle from '~/components/PageTitle';
import {
  SectionContainer,
  OrderContainer,
  ContactStoreAlertDialog,
  EmptyOrders,
} from './components';

import authAtom from '~/recoil/atom/auth';
import store from '~/recoil/atom/store';
import { useRecoilValue } from 'recoil';

import useInProgressOrders from './hooks/useInProgressOrders';
import { inProgressOrderNumberListener } from '~/firebase/listener';

const OrderList = () => {
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);
  const { phoneNumber } = useRecoilValue(store) || {};

  const [orderNum, setOrderNum] = useState<number>(0);

  const { inProgressOrdersList, handleMorePastOrder, pastOrderNum } = useInProgressOrders(
    storeId,
    auth,
  );

  const { isOpen: isOpenContact, onOpen: onOpenContact, onClose: onCloseContact } = useDisclosure();
  const [openOrderId, setOpenOrderId] = useState('');

  const handleChangeOrderId = (id: string) => {
    setOpenOrderId((prev) => {
      if (prev === id) {
        return '';
      }
      return id;
    });
  };

  const handleConfirmContactStore = () => {
    window.open(`tel:${phoneNumber || ''}`);
    onCloseContact();
  };

  const handleSetOrdersNum = (num: number) => {
    setOrderNum(num);
  };

  useEffect(() => {
    let listener = () => {};
    if (auth && storeId) {
      listener = inProgressOrderNumberListener(storeId, auth, handleSetOrdersNum);
    }

    return () => {
      listener?.();
    };
  }, [storeId, auth?.uid]);

  return (
    <>
      <PageContainer hideHeader>
        <PageTitle
          title="訂單"
          onNavigate={() => navigate(groupId ? `/${storeId}/group/${groupId}` : `/${storeId}`)}
        />
        <VStack align="stretch" px={{ base: 4, md: 0 }} py={3} spacing={8}>
          <SectionContainer icon={IoReceiptOutline} title="訂單">
            {inProgressOrdersList.length === 0 && (
              <EmptyOrders onNavigate={() => navigate(`/${storeId}`)} />
            )}

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              <VStack align="stretch" spacing={4}>
                {inProgressOrdersList.map((order) => (
                  <OrderContainer
                    key={order.id}
                    openOrderId={openOrderId}
                    onChangeOpenOrder={handleChangeOrderId}
                    onOpenContact={onOpenContact}
                    order={order}
                  />
                ))}

                {pastOrderNum < orderNum && (
                  <Text
                    fontSize="sm"
                    textAlign="center"
                    color="wsGray.500"
                    onClick={handleMorePastOrder}
                    cursor="pointer"
                  >
                    觀看更多
                  </Text>
                )}
              </VStack>
            </SimpleGrid>
          </SectionContainer>
        </VStack>
      </PageContainer>
      <ContactStoreAlertDialog
        storePhoneNumber={phoneNumber || ''}
        isOpen={isOpenContact}
        onClose={onCloseContact}
        onConfirm={handleConfirmContactStore}
      />
    </>
  );
};

export default OrderList;
