import React from 'react';
import { Box, StackDivider, VStack, Text, HStack, Flex, Button, Icon } from '@chakra-ui/react';
import { BsDot, BsFillClockFill, BsFillStarFill } from 'react-icons/bs';
import { AiTwotoneStar } from 'react-icons/ai';
import { IoArrowForwardCircle } from 'react-icons/io5';
import PageContainer from '~/container/PageContainer';
import BackwardBar from '~/components/BackwardBar';
import Title from '~/components/Title';

type CouponDetailProps = {};

// 優惠券詳情頁，除了優惠券基本資訊還包含優惠券說明跟注意事項，按點餐去可使用
export default function CouponDetail(props: CouponDetailProps) {
  return (
    <PageContainer hideHeader>
      <BackwardBar title="回去列表" />
      <Title title="優惠券詳情頁" />
      <VStack align="stretch" p={4} spacing={4} divider={<StackDivider />}>
        <Box>
          <VStack align="stretch" spacing={3}>
            <Text fontSize="sm" fontWeight="bold">
              優惠券名稱最長可以看有多長
              無限往下延伸無限往下延伸無限往下延伸無限往下延伸無限往下延伸
            </Text>
            <HStack>
              <Icon as={BsFillClockFill} color="wsGray.500" />
              <Text fontSize="xs" color="wsGray.500">
                2020/10/19到期
              </Text>
            </HStack>
            <HStack>
              <Icon as={AiTwotoneStar} color="wsGray.500" />
              <Text fontSize="xs" color="wsRed.600">
                滿NT$100折抵NT$20
              </Text>
            </HStack>
          </VStack>
        </Box>
        <Box>
          <VStack align="stretch" spacing={2}>
            <HStack spacing={0.5}>
              <Icon as={BsDot} color="wsGray.500" />
              <Text fontSize="xs" color="wsGray.500">
                優惠券說明
              </Text>
            </HStack>
            <Text fontSize="sm">
              說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明
            </Text>
          </VStack>
        </Box>
        <Box>
          <VStack align="stretch" spacing={2}>
            <HStack spacing={0.5}>
              <Icon as={BsDot} color="wsGray.500" />
              <Text fontSize="xs" color="wsGray.500">
                注意事項
              </Text>
            </HStack>
            <Text fontSize="sm">
              說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明說明
            </Text>
          </VStack>
        </Box>
        <Box>
          <Flex justify="center" pt={1} pb={16}>
            <Button
              h="auto"
              px={16}
              py={3.5}
              rightIcon={<Icon as={IoArrowForwardCircle} boxSize="1.25rem" ml={-1} color="white" />}
            >
              點餐去
            </Button>
          </Flex>
        </Box>
      </VStack>
    </PageContainer>
  );
}
