import React from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Text,
  Icon,
  Badge,
} from '@chakra-ui/react';
import CouponList from './CouponList';
import PointList from './PointList';
import CouponIcon from '~/icons/Coupon';
import PointIcon from '~/icons/Point';

export interface DiscountTabBarProps {}

// 切換展示優惠券列表跟集點卡列表
export default function DiscountTabBar(props: DiscountTabBarProps) {
  return (
    <Tabs isFitted colorScheme="wsBlack">
      {/* <Tabs colorScheme="wsBlack"> */}
      <TabList borderBottomWidth="1px" borderColor="wsGray.400">
        <Tab
          _focus={{
            boxShadow: 'none',
          }}
        >
          {/* <Tab
            _selected={{
              borderWidth: '0',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          > */}
          <HStack align="center">
            <Icon as={CouponIcon} boxSize="1.5rem" />
            <Text fontWeight="bold">
              優惠券
              <Badge
                variant="solid"
                ml={1}
                bgColor="wsRed.600"
                borderRadius="50%"
                colorScheme="wsRed.600"
              >
                1
              </Badge>
            </Text>
          </HStack>
        </Tab>
        <Tab
          _focus={{
            boxShadow: 'none',
          }}
        >
          <HStack align="center">
            <PointIcon boxSize="1.5rem" />
            <Text fontWeight="bold">集點卡</Text>
          </HStack>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel p={0} pt={4}>
          <CouponList />
        </TabPanel>
        <TabPanel p={0} pt={4}>
          <PointList />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
