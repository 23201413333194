import { VStack, Text, HStack, Box } from '@chakra-ui/react';

type SectionContainerProps = {
  title: string;
  icon?: JSX.Element | null;
  rightHandComponent?: React.ReactNode | string;
  children?: React.ReactNode;
};

export default function SectionContainer({
  title,
  icon = null,
  rightHandComponent = null,
  children = null,
}: SectionContainerProps) {
  return (
    <VStack spacing={2} align="stretch">
      <HStack justify="space-between" align="center">
        <HStack align="center">
          {icon}
          <Text fontWeight="bold">{title}</Text>
        </HStack>

        {rightHandComponent}
      </HStack>

      <Box>{children}</Box>
    </VStack>
  );
}
