import { useMemo } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import mealOrderSettingSelector from '~/recoil/selectors/mealOrderSetting';

import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import locationAtom from '~/recoil/atom/location';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';

import { MealOrderSetting, MealPickUpType, PickMealTime } from '~/types';

export default function useOrderSetting() {
  const mealOrderSetting = useRecoilValue(mealOrderSettingSelector);

  const setMealPickupType = useSetRecoilState(mealPickupTypeAtom);
  const setLocation = useSetRecoilState(locationAtom);
  const setPickMealTime = useSetRecoilState(pickMealTimeAtom);
  const setPreOrderDate = useSetRecoilState(preOrderDateAtom);

  const handleMealPickupTypeChange = (mealPickupType: MealPickUpType) => {
    setMealPickupType(mealPickupType);
  };

  const handleLocationClear = () => {
    setLocation({
      address: '',
      floor: '',
      note: '',
    });
  };

  const handleOrderSettingUpdate = (newOrderSetting: Partial<MealOrderSetting>) => {
    const { mealPickupType, location, pickMealTime, preOrderDate } = newOrderSetting;

    mealPickupType && setMealPickupType(mealPickupType);
    pickMealTime && setPickMealTime(pickMealTime);

    if (mealPickupType === MealPickUpType.DELIVERY && location) {
      setLocation(location);
    }

    if (pickMealTime === PickMealTime.RESERVATION && preOrderDate) {
      setPreOrderDate(preOrderDate);
    }
  };

  return {
    mealOrderSetting,
    handleMealPickupTypeChange,
    handleOrderSettingUpdate,
    handleLocationClear,
  };
}
