import { selector } from 'recoil';

import { categoriesSelector } from '~/recoil/selectors/categories';
import filteredCategoryIdsSelector from '~/recoil/selectors/filteredCategoryIds';

import { CategoryNavList } from '~/pages/Menu/types';

const categoryNavListSelector = selector<CategoryNavList | []>({
  key: 'categoryNavListSelector',
  get: ({ get }) => {
    const filteredCategoryIds = get(filteredCategoryIdsSelector);
    const categories = get(categoriesSelector);

    if (filteredCategoryIds.length === 0 || !categories) return [];
    return filteredCategoryIds.map((id) => {
      return {
        label: categories[id].name,
        value: id,
      };
    });
  },
});

export default categoryNavListSelector;
