import parsePhoneNumber, { CountryCode } from 'libphonenumber-js/mobile';
import isEmpty from 'lodash/isEmpty';

export default function checkValidPhoneNumber(
  phoneNumber: string,
  countryCode: CountryCode = 'TW',
): {
  formattedPhoneNumber: string;
  error?: string;
} {
  if (isEmpty(phoneNumber)) {
    return {
      formattedPhoneNumber: '',
      error: '請輸入手機號碼',
    };
  }

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber, countryCode);

  if (!parsedPhoneNumber) {
    return {
      formattedPhoneNumber: '',
      error: '此電話號碼驗證失敗',
    };
  }

  if (!parsedPhoneNumber.isValid() || !parsedPhoneNumber.isPossible()) {
    return {
      formattedPhoneNumber: '',
      error: '此電話號碼不合法',
    };
  }

  console.log('phone type ', parsedPhoneNumber.getType());
  if (parsedPhoneNumber.getType() !== 'MOBILE') {
    return {
      formattedPhoneNumber: '',
      error: '此號碼不是手機號碼',
    };
  }

  return {
    formattedPhoneNumber: parsedPhoneNumber.number as string,
  };
}
