import { OrderType, WsOrderStatus } from '~/types';
import {
  preOrderSelfPickupMapString,
  selfPickupMapString,
} from '../../orderStatusMapString';

export type StatusInfo = {
  label: string;
  status: WsOrderStatus;
};

export const selfPickupProgress: Array<StatusInfo> = [
  {
    label: selfPickupMapString[WsOrderStatus.CREATED],
    status: WsOrderStatus.CREATED,
  },
  {
    label: selfPickupMapString[WsOrderStatus.ACCEPTED],
    status: WsOrderStatus.ACCEPTED,
  },
  {
    label: selfPickupMapString[WsOrderStatus.PREPARED],
    status: WsOrderStatus.PREPARED,
  },
  {
    label: selfPickupMapString[WsOrderStatus.COMPLETED],
    status: WsOrderStatus.COMPLETED,
  },
];

export const preOrderSelfPickupProgress: Array<StatusInfo> = [
  {
    label: preOrderSelfPickupMapString[WsOrderStatus.CREATED],
    status: WsOrderStatus.CREATED,
  },
  {
    label: preOrderSelfPickupMapString[WsOrderStatus.CONFIRMED],
    status: WsOrderStatus.CONFIRMED,
  },
  {
    label: preOrderSelfPickupMapString[WsOrderStatus.ACCEPTED],
    status: WsOrderStatus.ACCEPTED,
  },
  {
    label: preOrderSelfPickupMapString[WsOrderStatus.PREPARED],
    status: WsOrderStatus.PREPARED,
  },
  {
    label: preOrderSelfPickupMapString[WsOrderStatus.COMPLETED],
    status: WsOrderStatus.COMPLETED,
  },
];

export function getMatchOrderTypeProgress(
  orderType: OrderType,
  isSelfDelivery: boolean = false,
): Array<StatusInfo> {
  switch (orderType) {
    case OrderType.WS_PICKUP: {
      return selfPickupProgress;
    }
  
    case OrderType.WS_PREORDER_PICKUP: {
      return preOrderSelfPickupProgress;
    }
  
    default: {
      return [];
    }
  }
}
