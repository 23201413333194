import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Box,
  HStack,
  VStack,
  Text,
  Icon,
  StackDivider,
  useDisclosure,
  Circle,
} from '@chakra-ui/react';

import { AiOutlineClockCircle } from 'react-icons/ai';
import { GrLocation } from 'react-icons/gr';
import { BiMap } from 'react-icons/bi';
import {
  IoChevronDown,
  IoChevronUp,
  IoCheckmarkSharp,
  IoReceipt,
  IoArrowForward,
} from 'react-icons/io5';

import getPreOrderDateString from '~/utils/getPreOrderDateString';
import generatePrepareString from '~/pages/Cart/utils/generatePrepareString';

import mallStoreOrderSettingSelector from '~/recoil/selectors/mallStoreOrderSetting';

import { ScrollPositionInfo } from '~/hooks/useScroll';
import useOrderSetting from '~/hooks/useOrderSetting';
import useModalControl from '~/hooks/useModalControl';

import { CategoryNavList, SelectedCategory, ChangeCategoryFn } from '~/pages/Menu/types';
import { MealPickUpType, PickMealTime } from '~/types';

import format from 'date-fns/format';
import getDay from 'date-fns/getDay';

type FixHeaderSectionProps = {
  orderNum: number;
  categoryNavList: CategoryNavList;
  selectedCategory: SelectedCategory;
  positionInfo: ScrollPositionInfo;
  onChangeCategory: ChangeCategoryFn;
  onNavigateToOrders: () => void;
};

// 定在螢幕上方顯示取餐資訊及類別選擇（若是有訂單進行中，則也會顯示訂單通知）
export default function FixHeaderSection({
  orderNum,
  categoryNavList,
  selectedCategory,
  positionInfo,
  onChangeCategory,
  onNavigateToOrders,
}: FixHeaderSectionProps) {
  const { changeModalOpen } = useModalControl();
  const { allowPreOrder } = useRecoilValue(mallStoreOrderSettingSelector);
  const {
    mealOrderSetting: { mealPickupType, location, pickMealTime, preOrderDate },
  } = useOrderSetting();

  const { isOpen, onToggle, onClose } = useDisclosure();
  const {
    isOpen: isSectionDisplay,
    onOpen: onSectionOpen,
    onClose: onSectionClose,
  } = useDisclosure({ isOpen: positionInfo.scrollTop >= 400 });

  useEffect(() => {
    if (positionInfo.scrollTop > 400) {
      onSectionOpen();
    } else {
      onSectionClose();
      onClose();
    }
  }, [positionInfo]);

  const handleClickCategory = (value: string) => {
    onChangeCategory(value, false);
    onClose();
  };

  return (
    <Box
      position="fixed"
      w="full"
      top={0}
      zIndex="1"
      transition="0.3s"
      transform={`translateY(${isSectionDisplay ? '0' : '-100%'})`}
    >
      <Box maxW="900px" w="full" m="auto">
        {orderNum > 0 && (
          <HStack
            borderColor="wsGray.400"
            boxShadow="0px 5px 5px -5px grey"
            bgColor="white"
            py={1}
            px={3}
            justify="center"
            spacing={2}
            zIndex="20"
            position="relative"
            onClick={onNavigateToOrders}
          >
            <Circle size="1.875rem" bg="wsBlack" pr={1}>
              <Icon boxSize="1.125rem" color="white" as={IoReceipt} />
            </Circle>
            <Text fontSize="sm" color="wsBlack" fontWeight="bold">
              {`您有${orderNum}筆正在進行中的訂單`}
            </Text>
            <Icon boxSize="1rem" color="wsGray.400" as={IoArrowForward} />
          </HStack>
        )}

        {mealPickupType === MealPickUpType.DELIVERY && (
          <HStack
            onClick={() => changeModalOpen('isPickupSettingOpen', true)}
            px={3}
            py={2}
            divider={<StackDivider borderColor="wsGray.400" borderWidth="1px" />}
            bgColor="wsGray.200"
          >
            {location?.address ? (
              <HStack flex="1" overflow="hidden">
                <Icon as={GrLocation} boxSize="1.2rem" />
                <Text whiteSpace="nowrap" overflow="hidden">
                  {location.address}
                </Text>
              </HStack>
            ) : (
              <HStack flex="1" overflow="hidden" color="wsRed.600">
                <Icon as={BiMap} boxSize="1.2rem" color="inherit" />
                <Text whiteSpace="nowrap" overflow="hidden" color="inherit">
                  請輸入地址
                </Text>
              </HStack>
            )}
            {allowPreOrder && pickMealTime === PickMealTime.RESERVATION && preOrderDate ? (
              <HStack>
                <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
                <Text w="4rem" whiteSpace="nowrap" overflow="hidden">
                  {getPreOrderDateString(preOrderDate, mealPickupType)}
                </Text>
              </HStack>
            ) : (
              <HStack>
                <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
                <Text w="4rem" whiteSpace="nowrap" overflow="hidden">
                  儘快
                </Text>
              </HStack>
            )}
          </HStack>
        )}

        {mealPickupType === MealPickUpType.PICKUP && (
          <HStack
            onClick={() => changeModalOpen('isPickupSettingOpen', true)}
            px={3}
            py={2}
            divider={<StackDivider borderColor="wsGray.400" borderWidth="1px" />}
            bgColor="wsGray.200"
          >
            {allowPreOrder && pickMealTime === PickMealTime.RESERVATION && preOrderDate ? (
              <HStack>
                <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
                <Text whiteSpace="nowrap" overflow="hidden">
                  {getPreOrderDateString(preOrderDate, mealPickupType)}
                </Text>
              </HStack>
            ) : (
              <HStack>
                <Icon as={AiOutlineClockCircle} boxSize="1.2rem" />
                <Text w="5rem" whiteSpace="nowrap" overflow="hidden">
                  儘快取餐
                </Text>
              </HStack>
            )}
          </HStack>
        )}

        <Box bgColor="white" borderBottomWidth="2px" borderColor="wsBlack">
          <HStack
            cursor="pointer"
            justify="space-between"
            px={4}
            py={2}
            align="center"
            onClick={onToggle}
          >
            <Text>{selectedCategory.label}</Text>
            <Icon as={isOpen ? IoChevronDown : IoChevronUp} boxSize="1.2rem" />
          </HStack>
          {isOpen ? (
            <VStack align="stretch" divider={<StackDivider />} spacing={0}>
              {categoryNavList.map(({ label, value }) => {
                return (
                  <HStack
                    key={value}
                    align="center"
                    py={2}
                    px={4}
                    onClick={() => handleClickCategory(value)}
                    bgColor={value === selectedCategory.value ? 'wsBlack' : 'white'}
                  >
                    <Icon
                      as={IoCheckmarkSharp}
                      color={value === selectedCategory.value ? 'white' : 'transparent'}
                      boxSize="1.2rem"
                    />
                    <Text color={value === selectedCategory.value ? 'white' : 'wsBlack'}>
                      {label}
                    </Text>
                  </HStack>
                );
              })}
            </VStack>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
