import { VStack, Text, Box, HStack } from '@chakra-ui/react';
import formatPriceNumber from '~/utils/formatPriceNumber';
import { OrderType } from '~/types';

type TotalPriceSectionProps = {
  orderType: OrderType;
  subtotal: number;
  shippingFee: number;
  discount: number;
  freightSubsidy: number; // 運費補助金額
  total: number;
};

// 商品結算區塊
export default function TotalPriceSection({
  orderType,
  subtotal,
  shippingFee,
  freightSubsidy,
  total,
  discount,
}: TotalPriceSectionProps) {

  const isReachDiscount = discount > 0;
  return (
    <VStack spacing={3} align="stretch">
      <VStack spacing={1} align="stretch">
        <HStack justify="space-between" align="stretch">
          <Text fontSize="lg">餐點總金額</Text>
          <Text fontSize="lg">{formatPriceNumber(subtotal)}</Text>
        </HStack>
        {isReachDiscount && (
          <HStack justify="space-between" align="stretch">
            <Text fontSize="lg">使用優惠卷</Text>
            <Text color="wsRed.600" fontSize="lg">
              -{formatPriceNumber(discount)}
            </Text>
          </HStack>
        )}
      
      </VStack>

      <Box backgroundColor="wsBlack" height="0.175rem" />
      <HStack justify="space-between" align="stretch">
        <Text fontSize="lg" fontWeight="bold">
          總計
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          {formatPriceNumber(total)}
        </Text>
      </HStack>
      <Text fontSize="xs">
        若消費者在店家確認接單後棄單，店家擁有選擇不退款或封鎖該客戶之權利。
      </Text>
    </VStack>
  );
}
