import React from 'react';

import { Outlet } from 'react-router-dom';
import useStore from '~/hooks/useStore';
import { useRecoilValue } from 'recoil';
import storeAtom from '~/recoil/atom/store';

type StoreProviderProps = {
  children: React.ReactNode;
};

export default function StoreProvider({ children }: StoreProviderProps) {
  useStore();

  return <>{children}</>;
}
