import React from 'react';
import { Icon, Text, Flex, HStack, StackProps } from '@chakra-ui/react';

import {
  IoClipboardOutline,
  IoPeopleOutline,
  IoReceiptOutline,
  IoSearchOutline,
  IoLogIn,
} from 'react-icons/io5';
import { BiStoreAlt } from 'react-icons/bi';
import { StoreInfoIcon } from '~/icons';

import { useParams, useNavigate } from 'react-router-dom';

const navList = [
  {
    title: '菜單',
    icon: IoClipboardOutline,
    link: '',
  },
  // {
  //   title: '揪團訂',
  //   icon: IoPeopleOutline,
  //   link: 'groupOrder',
  // },
  {
    title: '訂單',
    icon: IoReceiptOutline,
    link: 'orders',
  },
  // {
  //   title: '專屬優惠',
  //   icon: Coupon,
  //   link: '',
  // },
  {
    title: '關於店家',
    icon: StoreInfoIcon,
    link: 'storeInfo',
  },
];

type NavBarProps = {
  isLogin: boolean;
  onLoginModalOpen: () => void;
  onLogout: () => void;
};

export default function NavBar({ isLogin, onLoginModalOpen, onLogout }: NavBarProps) {
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();

  const itemBaseStyle: StackProps = {
    justify: 'center',
    py: 2,
    transition: '0.3s',
    cursor: 'pointer',
    _hover: { bg: 'wsGray.300' },
  };

  const handleNavigate = (path: string) => {
    if (storeId && groupId) {
      return navigate(`/${storeId}/group/${groupId}/${path}`);
    }

    if (storeId) {
      return navigate(`/${storeId}/${path}`);
    }
  };

  return (
    <Flex borderTopWidth="thin" borderTopColor="wsGray.200">
      {navList.map(({ title, icon, link }) => {
        return (
          <HStack key={title} flex="1" {...itemBaseStyle} onClick={() => handleNavigate(link)}>
            <Icon as={icon} boxSize="1.5rem" />
            <Text fontSize="lg">{title}</Text>
          </HStack>
        );
      })}
      <HStack
        flex="1"
        {...itemBaseStyle}
        onClick={() => (isLogin ? onLogout() : onLoginModalOpen())}
      >
        <Icon as={IoLogIn} boxSize="1.5rem" />
        <Text fontSize="lg">{isLogin ? '登出' : '登入'}</Text>
      </HStack>
    </Flex>
  );
}
