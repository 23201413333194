import React from 'react';

import { Flex, HStack, Text, Icon, StackProps } from '@chakra-ui/react';

import { AiOutlineCar } from 'react-icons/ai';
import { Pickup } from '~/icons';

import { MealPickUpType } from '~/types';

type OrderTypeSwitcherProps = {
  mealPickupType: MealPickUpType;
  disableSwitch?: boolean;
  onMealPickupTypeChange?: (mealPickupType: MealPickUpType) => void;
};

// 取餐放方式切換 自取 or 外送
export default function OrderTypeSwitcher({
  mealPickupType,
  disableSwitch,
  onMealPickupTypeChange,
}: OrderTypeSwitcherProps) {
  const handleMealPickupTypeClick = (mealPickupType: MealPickUpType) => {
    if (disableSwitch || !onMealPickupTypeChange) return;
    onMealPickupTypeChange(mealPickupType);
  };

  const handleIsActiveBtn = (tabType: MealPickUpType) => {
    const btnStyle: StackProps = {
      bgColor: 'white',
      color: 'wsBlack',
    };
    const btnActiveStyle: StackProps = {
      bgColor: 'wsBlack',
      color: 'white',
    };

    return tabType === mealPickupType ? btnActiveStyle : btnStyle;
  };

  const disableSwitchDisplay = () => {
    if (mealPickupType === MealPickUpType.DELIVERY) {
      return (
        <HStack
          flex="1"
          borderRadius="5px"
          justify="center"
          py={2}
          {...handleIsActiveBtn(MealPickUpType.DELIVERY)}
        >
          <Icon boxSize="1.2rem" as={AiOutlineCar} />
          <Text fontWeight="bold" color="inherit">
            外送
          </Text>
        </HStack>
      );
    }

    return (
      <HStack
        flex="1"
        borderRadius="5px"
        justify="center"
        py={2}
        {...handleIsActiveBtn(MealPickUpType.PICKUP)}
      >
        <Icon boxSize="1.2rem" as={Pickup} />
        <Text fontWeight="bold" color="inherit">
          自取
        </Text>
      </HStack>
    );
  };

  return (
    <Flex borderRadius="5px" borderWidth="1px" borderColor="wsGray.400" bgColor="white">
      {disableSwitch ? (
        disableSwitchDisplay()
      ) : (
        <>
          <HStack
            onClick={() => handleMealPickupTypeClick(MealPickUpType.DELIVERY)}
            flex="1"
            borderRadius="5px"
            justify="center"
            py={2}
            {...handleIsActiveBtn(MealPickUpType.DELIVERY)}
          >
            <Icon boxSize="1.2rem" as={AiOutlineCar} />
            <Text fontWeight="bold" color="inherit">
              外送
            </Text>
          </HStack>
          <HStack
            onClick={() => handleMealPickupTypeClick(MealPickUpType.PICKUP)}
            flex="1"
            borderRadius="5px"
            justify="center"
            py={2}
            {...handleIsActiveBtn(MealPickUpType.PICKUP)}
          >
            <Icon boxSize="1.2rem" as={Pickup} />
            <Text fontWeight="bold" color="inherit">
              自取
            </Text>
          </HStack>
        </>
      )}
    </Flex>
  );
}
