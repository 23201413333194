import React from 'react';
import { SimpleGrid, Heading, Box, VStack } from '@chakra-ui/react';
import PointItem from './PointItem';

type PointListProps = {};

// 顯示集點票卡的列表，分成兩列，一列是點數足夠可兌換的集點票卡，一列是點數不夠不行兌換的
export default function PointList(props: PointListProps) {
  return (
    <VStack align="stretch" spacing={4}>
      <Box>
        <Heading as="h4" mb={2} fontSize="sm" color="wsGray.500">
          可兌換
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          <PointItem allowExchange />
          <PointItem allowExchange />
        </SimpleGrid>
      </Box>
      <Box>
        <Heading as="h4" mb={2} fontSize="sm" color="wsGray.500">
          還差一些才可兌換
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          <PointItem />
        </SimpleGrid>
      </Box>
    </VStack>
  );
}
