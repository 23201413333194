import { WsOrderStatus, OrderType } from '~/types';

export const selfPickupMapString = {
  [WsOrderStatus.CREATED]: '等待店家接受中',
  [WsOrderStatus.ACCEPTED]: '餐點準備中',
  [WsOrderStatus.PREPARED]: '待客戶取餐',
  [WsOrderStatus.COMPLETED]: '客戶已取餐',
  [WsOrderStatus.UNPAID]: '客戶棄單',
  [WsOrderStatus.FAILED]: '訂單取消',
  [WsOrderStatus.CANCELLED]: '訂單取消',
};

export const preOrderSelfPickupMapString = {
  [WsOrderStatus.CREATED]: '等待店家接受中',
  [WsOrderStatus.CONFIRMED]: '店家已接受預約',
  [WsOrderStatus.ACCEPTED]: '餐點準備中',
  [WsOrderStatus.PREPARED]: '待客戶取餐',
  [WsOrderStatus.COMPLETED]: '客戶已取餐',
  [WsOrderStatus.UNPAID]: '客戶棄單',
  [WsOrderStatus.FAILED]: '訂單取消',
  [WsOrderStatus.CANCELLED]: '訂單取消',
};

export function getMatchOrderTypeMapString(
  orderType: OrderType,
  isSelfDelivery: boolean = false,
): Partial<Record<WsOrderStatus, string>> {
  switch (orderType) {
    case OrderType.WS_PICKUP: {
      return selfPickupMapString;
    }

    case OrderType.WS_PREORDER_PICKUP: {
      return preOrderSelfPickupMapString;
    }

    default: {
      return {};
    }
  }
}
