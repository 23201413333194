import React, { useState, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  Heading,
  Center,
  VStack,
  Button,
  Divider,
  Icon,
  HStack,
  Text,
  Box,
} from '@chakra-ui/react';
import { addMinutes, getMonth, differenceInMinutes, startOfDay } from 'date-fns';
import { AiOutlineClockCircle } from 'react-icons/ai';

import SectionContainer from '~/container/SectionContainer';
import OrderTypeSwitcher from '~/components/OrderTypeSwitcher';
import PickupTimeSelect from './PickupTimeSelect';
import PickupDaySelect from './PickupDaySelect';
import Calendar from './Calendar';
import PickupDurationSelect from './PickupDurationSelect';
import ConfirmModal from '~/components/ConfirmModal';

import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import mallStoreOrderSettingSelector from '~/recoil/selectors/mallStoreOrderSetting';

import useModalControl from '~/hooks/useModalControl';
import usePickupTime from './hooks/usePickupTime';
import useUpdateOrderSetting from './hooks/useUpdateOrderSetting';
import useConfirmChangeOrderTime from './hooks/useConfirmChangeOrderTime';

import { MealPickUpType, PickMealTime } from '~/types';
import { DatePickerType } from './types';

export default function PickupSettingSimpleModal() {
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);
  const { modalControl, changeModalOpen } = useModalControl();

  const [datePickerType, setDatePickerType] = useState(DatePickerType.WEEK);

  const { orderPrepareMinutes, preOrderSetting, allowPreOrder } = useRecoilValue(
    mallStoreOrderSettingSelector,
  );

  const {
    selectedTime,
    selectedTimeError,
    controlMonth,
    selectedDay,
    selectedDuration,
    timeRange,
    preOrderDate,
    weekDayList,
    getCheckDayType,
    durationOptionList,
    handlePickupTimeChange,
    handleMonthChange,
    handleSelectedDayChange,
    handleSelectedDurationChange,
  } = usePickupTime(MealPickUpType.PICKUP);

  const handlePickupSettingSubmit = useUpdateOrderSetting({
    mealPickupType: MealPickUpType.PICKUP,
    selectedTime,
    selectedTimeError,
    preOrderDate,
  });

  const {
    isConfirmModalOpen,
    title,
    message,
    handleSafePickupSettingSubmit,
    handleConfirm,
    handleCancel,
  } = useConfirmChangeOrderTime({
    title: '確定改變取餐時間？',
    message: '改變取餐時間，原本購物車的品項將會被清空',
    updatePickMealTime: selectedTime,
    updatePreOrderDate: preOrderDate,
    handlePickupSettingSubmit,
  });

  // ================================================================

  const handleToggleDatePickerType = () => {
    if (datePickerType === DatePickerType.CALENDAR) {
      setDatePickerType(DatePickerType.WEEK);
    }

    if (datePickerType === DatePickerType.WEEK) {
      setDatePickerType(DatePickerType.CALENDAR);
    }
  };

  // ================================================================

  const selectedDayString = useMemo(() => {
    const weeks = ['日', '一', '二', '三', '四', '五', '六'];

    if (selectedTime === PickMealTime.IMMEDIATELY) {
      const nowDate = new Date();
      return `${getMonth(nowDate) + 1}/${nowDate.getDate()} (${weeks[nowDate.getDay()]})`;
    }

    if (!selectedDay) return;
    return `${getMonth(selectedDay) + 1}/${selectedDay.getDate()} (${weeks[selectedDay.getDay()]})`;
  }, [selectedTime, selectedDay]);

  const selectedDurationString = useMemo(() => {
    const timeStringFn = (time: number) => {
      const hour = Math.floor(time / 60);
      const minute = time % 60;
      const timeString = `${hour < 10 ? '0' + hour.toString() : hour.toString()}:${
        minute < 10 ? '0' + minute.toString() : minute.toString()
      }`;
      return timeString;
    };

    if (!orderPrepareMinutes) return;
    if (selectedTime === PickMealTime.IMMEDIATELY) {
      const nowDate = new Date();
      const immediatelyPickupTimeMinutes = differenceInMinutes(
        addMinutes(nowDate, orderPrepareMinutes),
        startOfDay(nowDate),
      );
      return `${timeStringFn(immediatelyPickupTimeMinutes)}~${timeStringFn(
        immediatelyPickupTimeMinutes + timeRange,
      )}`;
    }

    if (!selectedDuration && selectedDuration !== 0) return;
    return `${timeStringFn(selectedDuration)}~${timeStringFn(selectedDuration + timeRange)}`;
  }, [selectedTime, orderPrepareMinutes, selectedDuration, timeRange]);

  const preOrderDays = useMemo(() => {
    if (!preOrderSetting) return;
    const { preOrderBefore } = preOrderSetting;
    const days = Math.ceil(preOrderBefore / 24);
    return days;
  }, [preOrderSetting]);

  if (isLargerThan768) {
    return (
      <Modal
        isOpen={modalControl.isPickupSettingOpen}
        onClose={() => {
          changeModalOpen('isPickupSettingOpen', false);
        }}
        size={'lg'}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent m={0} maxH={'100%'} h={'100%'} borderRadius="unset">
          <ModalCloseButton />
          <ModalHeader borderColor="wsGray.300" borderBottomWidth="1px">
            <Center mt={4}>
              <Heading fontSize="xl">取餐設定</Heading>
            </Center>
          </ModalHeader>

          <ModalBody overflow="auto">
            <OrderTypeSwitcher mealPickupType={MealPickUpType.PICKUP} disableSwitch />
            <Box h={5} />
            <SectionContainer
              title="取餐時間"
              icon={<Icon as={AiOutlineClockCircle} boxSize="1.5rem" />}
            >
              <PickupTimeSelect
                selectedTime={selectedTime}
                selectedTimeError={selectedTimeError}
                onPickupTimeChange={handlePickupTimeChange}
                selectedDayString={selectedDayString}
                selectedDurationString={selectedDurationString}
              />
              {allowPreOrder && selectedTime === PickMealTime.RESERVATION && (
                <>
                  <HStack justify="space-between" align="flex-start" my={3}>
                    {preOrderDays && (
                      <Text fontSize="xs" color="wsGray.500">
                        最遠可預訂{preOrderDays}天後
                      </Text>
                    )}
                    <Text fontSize="xs" color="wsBlack" onClick={handleToggleDatePickerType}>
                      {datePickerType === DatePickerType.WEEK && '顯示月曆'}
                      {datePickerType === DatePickerType.CALENDAR && '顯示日曆'}
                    </Text>
                  </HStack>

                  {datePickerType === DatePickerType.WEEK && (
                    <PickupDaySelect
                      dayList={weekDayList}
                      selectedDay={selectedDay}
                      onDayClick={handleSelectedDayChange}
                    />
                  )}
                  {datePickerType === DatePickerType.CALENDAR && (
                    <Calendar
                      controlMonth={controlMonth}
                      selectedDay={selectedDay}
                      onDayTypeCheck={getCheckDayType}
                      onMonthChange={handleMonthChange}
                      onDaySelect={handleSelectedDayChange}
                    />
                  )}

                  <PickupDurationSelect
                    durationOptionList={durationOptionList}
                    selectedDuration={selectedDuration}
                    selectedDay={selectedDay}
                    onDurationSelect={handleSelectedDurationChange}
                  />
                </>
              )}
            </SectionContainer>
          </ModalBody>

          <ModalFooter p={0}>
            <Button
              onClick={handleSafePickupSettingSubmit}
              variant="unstyled"
              bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
              borderRadius="unset"
              color="white"
              isFullWidth
            >
              確認
            </Button>
          </ModalFooter>
        </ModalContent>
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onConfirm={handleConfirm}
          onClose={handleCancel}
          title={title}
          message={message}
        />
      </Modal>
    );
  }
  return (
    <Drawer
      isOpen={modalControl.isPickupSettingOpen}
      onClose={() => {
        changeModalOpen('isPickupSettingOpen', false);
      }}
      size="full"
    >
      <DrawerOverlay />
      <DrawerContent h="full">
        <DrawerCloseButton />
        <DrawerHeader borderColor="wsGray.300" borderBottomWidth="1px">
          <Center mt={4}>
            <Heading fontSize="xl">取餐設定</Heading>
          </Center>
        </DrawerHeader>
        <DrawerBody>
          <OrderTypeSwitcher mealPickupType={MealPickUpType.PICKUP} disableSwitch />
          <Box h={5} />
          <SectionContainer
            title="取餐時間"
            icon={<Icon as={AiOutlineClockCircle} boxSize="1.5rem" />}
          >
            <PickupTimeSelect
              selectedTime={selectedTime}
              selectedTimeError={selectedTimeError}
              onPickupTimeChange={handlePickupTimeChange}
              selectedDayString={selectedDayString}
              selectedDurationString={selectedDurationString}
            />
            {allowPreOrder && selectedTime === PickMealTime.RESERVATION && (
              <>
                <HStack justify="space-between" align="flex-start" my={3}>
                  {preOrderDays && (
                    <Text fontSize="xs" color="wsGray.500">
                      最遠可預訂{preOrderDays}天後
                    </Text>
                  )}
                  <Text fontSize="xs" color="wsBlack" onClick={handleToggleDatePickerType}>
                    {datePickerType === DatePickerType.WEEK && '顯示月曆'}
                    {datePickerType === DatePickerType.CALENDAR && '顯示日曆'}
                  </Text>
                </HStack>

                {datePickerType === DatePickerType.WEEK && (
                  <PickupDaySelect
                    dayList={weekDayList}
                    selectedDay={selectedDay}
                    onDayClick={handleSelectedDayChange}
                  />
                )}
                {datePickerType === DatePickerType.CALENDAR && (
                  <Calendar
                    controlMonth={controlMonth}
                    selectedDay={selectedDay}
                    onDayTypeCheck={getCheckDayType}
                    onMonthChange={handleMonthChange}
                    onDaySelect={handleSelectedDayChange}
                  />
                )}

                <PickupDurationSelect
                  durationOptionList={durationOptionList}
                  selectedDuration={selectedDuration}
                  selectedDay={selectedDay}
                  onDurationSelect={handleSelectedDurationChange}
                />
              </>
            )}
          </SectionContainer>
        </DrawerBody>
        <DrawerFooter
          px={4}
          py={2}
          bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
          borderRadius="unset"
        >
          <Button
            onClick={handleSafePickupSettingSubmit}
            variant="unstyled"
            color="white"
            isFullWidth
          >
            <Center w="full" h="2.5rem">
              <Text textAlign="center" fontSize="lg" fontWeight="bold" color="inherit">
                確認
              </Text>
            </Center>
          </Button>
        </DrawerFooter>
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onConfirm={handleConfirm}
          onClose={handleCancel}
          title={title}
          message={message}
        />
      </DrawerContent>
    </Drawer>
  );
}
