import { useState, useEffect } from 'react';
import { VStack, Box, Image, Stack, AspectRatio } from '@chakra-ui/react';
import {
  AddToCartBottomBar,
  FixedBackwardBar,
  ItemModifier,
  ItemInfo,
  NoteInput,
  LoginBottomBar,
} from './components';
import PageContainer from '~/container/PageContainer';
import LoginModal from '~/components/LoginModal';
import useScroll from '~/hooks/useScroll';

import { useParams, useNavigate, Navigate, useLocation } from 'react-router-dom';

import { itemSelector } from '~/recoil/selectors/items';
import { useRecoilValue } from 'recoil';

import useModifiersControl from './hooks/useModifiersControl';
import useAllowOperateCart from './hooks/useAllowOperateCart';
import useKitchenCartItems from '~/hooks/useKitchenCartItems';
import { CartItem, OrderModifier } from '~/types';

import useUser from '~/hooks/useUser';

import ImageSrcError from '~/assets/images/ImageSrcError.png';

export default function ItemPage() {
  const { kitchenId, storeId, itemId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { positionInfo } = useScroll();

  const [showError, setShowError] = useState<boolean>(false);

  const item = useRecoilValue(itemSelector(itemId));

  // check operating time
  const allowOperateCart = useAllowOperateCart(itemId);

  const { handleAddItems } = useKitchenCartItems();

  const { isLogin, login } = useUser();

  // control item info hook
  const {
    selectedModifiers,
    modifierErrors,
    optionsTotalPrice,
    count,
    note,
    handleChangeCount,
    handleChangeModifier,
    handleChangeNote,
  } = useModifiersControl(item?.modifiers || null, {});

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  if (!item) {
    return <Navigate to={`/${storeId}`} />;
  }

  const handleBackToMenuPage = () => {
    navigate(`/${storeId}`, { state: { from: location.pathname } });
  };

  const handleClickAddItem = async () => {
    if (modifierErrors.size > 0) {
      setShowError(true);
      return;
    }

    const modifiers: Array<OrderModifier> = item.modifiers.reduce((acc, { id }) => {
      const modifier = selectedModifiers.get(id);
      if (modifier) acc.push(modifier);
      return acc;
    }, [] as Array<OrderModifier>);

    const editedCartItem: CartItem = {
      id: itemId,
      count,
      note,
      modifiers,
      name: item.name,
      tax: item.tax,
      price: item.price,
    };

    handleAddItems(kitchenId, editedCartItem);

    handleBackToMenuPage();
  };

  return (
    <PageContainer hideHeader hideFooter>
      <FixedBackwardBar title="繼續點餐" onNavigate={handleBackToMenuPage} />
      <Stack
        direction={{ base: 'column', md: 'row' }}
        align="stretch"
        spacing={{ base: 0, md: 10 }}
      >
        <Box w="full" flex="1">
          {item.image && (
            <AspectRatio m="auto" maxW="1000px" ratio={1}>
              <Image
                src={item.image}
                fallbackSrc={ImageSrcError}
                w="full"
                pos={{ base: 'static', md: 'sticky' }}
                top={20}
                zIndex="100"
              />
            </AspectRatio>
          )}
        </Box>
        <VStack
          align="stretch"
          flex="1"
          px={{ base: 4, md: 4 }}
          pt={{ base: 3, md: 0 }}
          pb={{ base: 0, md: 3 }}
          spacing={5}
        >
          <ItemInfo
            title={item.name}
            description={item.description}
            price={item.price}
            inventory={item.inventory.currentInventory}
          />
          <ItemModifier
            itemModifiers={item.modifiers}
            selectorModifiers={selectedModifiers}
            modifierErrors={modifierErrors}
            showError={showError}
            positionInfo={positionInfo}
            onChangeModifier={handleChangeModifier}
          />
          <NoteInput
            value={note}
            onChange={(e) => handleChangeNote(e.target.value)}
            textLimit={100}
          />
          {!item.image && (
            <Box h="400px" pos={{ base: 'static', md: 'sticky' }} top={20} zIndex="100" />
          )}
          {isLogin ? (
            <AddToCartBottomBar
              isDisabled={!allowOperateCart}
              amount={count}
              currentInventory={item.inventory.currentInventory}
              unitPrice={optionsTotalPrice + item.price}
              onChangeCount={handleChangeCount}
              onAddItemClick={handleClickAddItem}
            />
          ) : (
            <LoginBottomBar login={login} />
          )}
        </VStack>
      </Stack>
      <LoginModal />
    </PageContainer>
  );
}
