import { Text, RadioGroup, Stack, Radio } from '@chakra-ui/react';

import DisplayInput from './DisplayInput';

import { TaxType } from '~/types';

type DisplayTaxSectionProps = {
  taxType: TaxType;
  taxInfo?: {
    title: string;
    invoice: string;
  };
};

// 顯示不可編輯的 radio
export default function DisplayTaxSection({ taxType, taxInfo }: DisplayTaxSectionProps) {
  return (
    <>
      <RadioGroup value={taxType}>
        <Stack direction="row">
          <Radio
            value={TaxType.PERSONAL}
            _checked={{
              background: 'wsBlack',
              borderWidth: '0.15rem',
              borderColor: 'white',
              boxShadow: '0px 0px 1px 1px #333',
            }}
          >
            個人發票
          </Radio>
          <Radio
            value={TaxType.COMPANY}
            _checked={{
              background: 'wsBlack',
              borderWidth: '0.15rem',
              borderColor: 'white',
              boxShadow: '0px 0px 1px 1px #333',
            }}
          >
            公司發票
          </Radio>
        </Stack>
      </RadioGroup>
      {taxType === TaxType.COMPANY && taxInfo && (
        <>
          <Stack direction="column">
            <Text fontSize="sm">抬頭</Text>
            <DisplayInput displayText={taxInfo.title} />
          </Stack>
          <Stack direction="column">
            <Text fontSize="sm">統編</Text>
            <DisplayInput displayText={taxInfo.invoice} />
          </Stack>
        </>
      )}
    </>
  );
}
