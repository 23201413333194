import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { selectedMenuAtom } from '~/recoil/atom/selectedMenu';
import menuNavListSelector from '~/recoil/selectors/menuNavList';

export default function usePickMenu() {
  const [selectedMenu, setSelectedMenu] = useRecoilState(selectedMenuAtom);
  const menuNavList = useRecoilValue(menuNavListSelector);

  const handleChangeMenu = useCallback(
    (selectedMenu: { menuId: string; kitchenId: string }) => {
      setSelectedMenu((pre) => {
        return {
          selectedKitchenId: selectedMenu.kitchenId,
          selectedMenuId: selectedMenu.menuId,
        };
      });
    },
    [setSelectedMenu],
  );

  useEffect(() => {
    const { selectedMenuId } = selectedMenu;
    if (menuNavList.length && !menuNavList.some(({ value }) => value === selectedMenuId)) {
      handleChangeMenu({
        menuId: menuNavList[0].value || '',
        kitchenId: menuNavList[0].kitchenId || '',
      });
    }
  }, [selectedMenu, handleChangeMenu, menuNavList]);

  return {
    selectedMenu,
    handleChangeMenu,
  };
}
