import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import defaultBanner from '~/assets/images/banner.png';

import { Image, Flex, Box } from '@chakra-ui/react';
import Slider from 'react-slick';

type ImageSlickProps = {
  images?: Array<string>;
};

export default function ImageSlick({ images = [] }: ImageSlickProps) {
  const imagesList = images.length === 0 ? [defaultBanner] : images;

  return (
    <Box w="full" overflow="hidden">
      <Slider
        dots={true}
        infinite={true}
        speed={400}
        autoplay={true}
        autoplaySpeed={4000}
        slidesToShow={1}
        slidesToScroll={1}
        appendDots={(dots) => {
          return (
            <Flex spacing={0} position="absolute" justify="flex-end" bottom={0} right={0}>
              {dots}
            </Flex>
          );
        }}
      >
        {imagesList.map((url) => {
          return (
            <Image
              key={url}
              src={url}
              loading="lazy"
              h={{ base: '230px', md: '510px' }}
              objectFit="cover"
            />
          );
        })}
      </Slider>
    </Box>
  );
}
