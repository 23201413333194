
import React from 'react';

import { Flex, HStack, Text, Icon, StackProps } from '@chakra-ui/react';

import { AiOutlineCar } from 'react-icons/ai';
import { Pickup } from '~/icons';

type OrderTypeSectionProps = {
  isDelivery: boolean;
}

// 顯示取餐方式 : 自取 or 外送
export default function OrderTypeSection({ isDelivery }: OrderTypeSectionProps) {
  return (
    <Flex borderRadius="5px" borderWidth="1px" borderColor="wsGray.400" bgColor="white">
      <HStack
        flex="1"
        borderRadius="5px"
        justify="center"
        py={2}
        color="white"
        bgColor="wsBlack"
        boxShadow="0px 0px 10px rgba(51, 51, 51, 0.3)"
      >
        <Icon boxSize="1.2rem" as={Pickup} />
        <Text fontWeight="bold" color="inherit">
          自取
        </Text>
      </HStack>
    </Flex>
  );
}
