import { selector } from 'recoil';
import storeAtom from '~/recoil/atom/store';

import { Store } from '~/types';

type PickOperatingTime = Pick<Store, 'operatingTime'>;

export type OperatingTime = PickOperatingTime[keyof PickOperatingTime];

const operatingTimeSelector = selector<OperatingTime | null>({
  key: 'operatingTimeSelector',
  get: ({ get }) => {
    const store: Store | null = get(storeAtom);
    return store?.operatingTime || null;
  },
});

export default operatingTimeSelector;
