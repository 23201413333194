import React, { useState, useEffect } from 'react';
import { VStack, FormControl, FormLabel, InputGroup, Input } from '@chakra-ui/react';
import FormInputMessage from '~/components/FormInputMessage';

import { EmailState } from '~/pages/Cart/hooks/useEmail';

export type EInvoiceEmailProps = {
  emailState: EmailState;
  onUpdateEmail: (value: string) => void;
  showError: boolean;
};
// 電子發票收件電子郵件
export default function EInvoiceEmail({
  emailState,
  onUpdateEmail,
  showError,
}: EInvoiceEmailProps) {
  const { email, errors } = emailState;

  return (
    <VStack align="stretch" spacing={4}>
      <FormControl isRequired>
        <FormLabel fontWeight="normal" fontSize="sm">
          請輸入收件電子郵件
        </FormLabel>
        <InputGroup>
          <Input
            fontWeight="normal"
            type="email"
            value={email}
            isInvalid={showError && !!errors.email}
            onChange={(e) => onUpdateEmail(e.target.value)}
          />
        </InputGroup>
        {showError && errors.email && <FormInputMessage type="error" message={errors.email} />}
      </FormControl>
    </VStack>
  );
}
