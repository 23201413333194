import { useState, useMemo, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import {
  isSameDay,
  startOfDay,
  addMinutes,
  differenceInMinutes,
  addDays,
  differenceInDays,
  differenceInMilliseconds,
  setMinutes,
} from 'date-fns';

import operatingTimeSelector from '~/recoil/selectors/operatingTime';
import mallStoreOrderSettingSelector from '~/recoil/selectors/mallStoreOrderSetting';

import useOrderSetting from '~/hooks/useOrderSetting';
import useDay from './useDay';
import useDuration from './useDuration';

import checkOperatingTime from '~/utils/checkOperatingTime';
import { getUpdatedPreOrderAfterDate, getUpdatedPreOrderBeforeDate } from '../utils';

import { MealPickUpType, PickMealTime, PickupTimeRange, DeliveryTimeRange } from '~/types';
import { DayType } from '~/components/PickupSettingModal/Calendar/type';
import { DayCheckMode } from '~/components/PickupSettingModal/types';

const DEFAULT_DAYLIST_LEN = 5;

export default function usePickupTime(mealPickupType: MealPickUpType) {
  const { mealOrderSetting } = useOrderSetting();
  const { operatingTime, preOrderSetting } = useRecoilValue(mallStoreOrderSettingSelector);

  const [selectedTime, setSelectedTime] = useState<PickMealTime>(
    mealOrderSetting.pickMealTime || PickMealTime.IMMEDIATELY,
  );
  const [selectedTimeError, setSelectedTimeError] = useState<null | string>(null);
  const [controlMonth, setControlMonth] = useState<Date>(new Date());
  const [selectedDay, setSelectedDay] = useState<Date | undefined>();
  const [selectedDuration, setSelectedDuration] = useState<number | undefined>();
  const [preOrderDate, setPreOrderDate] = useState<Date | null>(null);

  const [preOrderAfterDate, setPreOrderAfterDate] = useState<Date | undefined>();
  const [preOrderBeforeDate, setPreOrderBeforeDate] = useState<Date | undefined>();

  const timeRange = useMemo(() => {
    if (mealPickupType === MealPickUpType.DELIVERY) {
      return DeliveryTimeRange;
    }
    if (mealPickupType === MealPickUpType.PICKUP) {
      return PickupTimeRange;
    }
    return DeliveryTimeRange;
  }, [mealPickupType]);

  // ================================================================

  useEffect(() => {
    if (!operatingTime) return;

    if (selectedTime === PickMealTime.RESERVATION) {
      setSelectedTimeError(null);
      return;
    }

    if (selectedTime === PickMealTime.IMMEDIATELY && !checkOperatingTime(operatingTime)) {
      setSelectedTimeError('目前無法提供儘快取餐服務');
      return;
    }
  }, [selectedTime, operatingTime]);

  useEffect(() => {
    const { preOrderDate } = mealOrderSetting;
    if (!preOrderDate) return;
    handleMonthChange(preOrderDate);
    handleSelectedDayChange(startOfDay(preOrderDate));
    setSelectedDuration(differenceInMinutes(preOrderDate, startOfDay(preOrderDate)));
  }, [mealOrderSetting]);

  useEffect(() => {
    if (!preOrderSetting) return;
    setPreOrderAfterDate((pre) => {
      return getUpdatedPreOrderAfterDate(preOrderSetting);
    });
    setPreOrderBeforeDate((pre) => {
      return getUpdatedPreOrderBeforeDate(preOrderSetting);
    });

    const nowDate = new Date();
    const refreshTimeUnit = (Math.floor(nowDate.getMinutes() / timeRange) + 1) * timeRange;
    const refreshTime = differenceInMilliseconds(
      setMinutes(nowDate, refreshTimeUnit).setSeconds(0),
      nowDate,
    );
    const refreshInterval = setInterval(() => {
      setPreOrderAfterDate((pre) => {
        return getUpdatedPreOrderAfterDate(preOrderSetting);
      });
      setPreOrderBeforeDate((pre) => {
        return getUpdatedPreOrderBeforeDate(preOrderSetting);
      });
      handlePreOrderDateChange(null);
    }, refreshTime);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [preOrderSetting, timeRange]);

  // ============================================================================

  const handlePickupTimeChange = (time: PickMealTime) => {
    setSelectedTime(time);
  };

  const handleMonthChange = (date: Date) => {
    setControlMonth(date);
  };

  const handleSelectedDayChange = (newDay: Date) => {
    setSelectedDay(newDay);
  };

  const handleSelectedDurationChange = (minutes: number) => {
    if (!selectedDay) return;
    setSelectedDuration(minutes);
    const newDate = addMinutes(selectedDay, minutes);
    handlePreOrderDateChange(newDate);
  };

  const handlePreOrderDateChange = (newDate: Date | null) => {
    setPreOrderDate(newDate);
  };

  // ============================================================================

  const { getCheckDayType } = useDay({
    controlMonth,
    preOrderAfterDate,
    preOrderBeforeDate,
  });

  const weekDayList = useMemo(() => {
    if (!preOrderBeforeDate || !preOrderAfterDate) return;

    let dayArr = [];
    let day = startOfDay(Date.now());
    const defaultDayListLength = DEFAULT_DAYLIST_LEN;
    const maxCanPreOrderDay = differenceInDays(preOrderBeforeDate, preOrderAfterDate) + 2;

    for (let i = 0; i <= maxCanPreOrderDay; i++) {
      if (dayArr.length < defaultDayListLength) {
        const dayType = getCheckDayType(day, DayCheckMode.WEEK);

        if (dayType !== DayType.DISABLE) {
          dayArr.push(day);
        }
        day = addDays(day, 1);
      }
    }

    const dayList = dayArr.map((day) => {
      const weeks = ['週日', '週一', '週二', '週三', '週四', '週五', '週六'];
      const months = [
        '一月',
        '二月',
        '三月',
        '四月',
        '五月',
        '六月',
        '七月',
        '八月',
        '九月',
        '十月',
        '十一月',
        '十二月',
      ];
      const isToday = isSameDay(day, Date.now());
      return {
        date: day,
        weekDay: isToday ? '今天' : weeks[day.getDay()],
        day: day.getDate().toString(),
        month: months[day.getMonth()],
      };
    });

    return dayList;
  }, [getCheckDayType, preOrderAfterDate, preOrderBeforeDate]);

  const { durationOptionList } = useDuration({
    selectedDay,
    timeRange,
    preOrderAfterDate,
    preOrderBeforeDate,
  });

  return {
    selectedTime,
    selectedTimeError,
    controlMonth,
    selectedDay,
    selectedDuration,
    timeRange,
    preOrderDate,
    weekDayList,
    getCheckDayType,
    durationOptionList,
    handlePickupTimeChange,
    handleMonthChange,
    handleSelectedDayChange,
    handleSelectedDurationChange,
    handlePreOrderDateChange,
  };
}
