import { HStack, Heading, Icon, Text, StackProps } from '@chakra-ui/react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

export type OrderStatusTitleProps = {
  status: string;
  isMatchId: boolean;
  orderNumber: string;
  onToggle: () => void;
} & StackProps;

export default function OrderStatusTitle({
  status,
  isMatchId,
  orderNumber,
  onToggle,
  ...props
}: OrderStatusTitleProps) {
  return (
    <HStack
      align="stretch"
      justify="space-between"
      bgColor="wsBlack"
      borderTopRadius="5px"
      width="full"
      py={2}
      px={3}
      onClick={onToggle}
      {...props}
    >
      <Heading fontSize="sm" letterSpacing={2} color="white">
        訂單編號 {orderNumber}
      </Heading>
      <HStack spacing={1}>
        <Text fontSize="xs" color="wsGray.400">
          {isMatchId ? '收起明細' : '展開明細'}
        </Text>
        <Icon as={isMatchId ? IoChevronUp : IoChevronDown} color="wsGray.400" />
      </HStack>
    </HStack>
  );
}
