import { useState, useEffect } from 'react';
import { Navigate, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { VStack, Box, CenterProps } from '@chakra-ui/react';

import storeAtom from '~/recoil/atom/store';
import authAtom from '~/recoil/atom/auth';
import kitchenCartItemsAtom from '~/recoil/atom/kitchenCartItems';

import PageContainer from '~/container/PageContainer';

import {
  MenuNav,
  CategoryItemList,
  MealPickUpSection,
  StoreInfoSection,
  FixFooterSection,
  FixHeaderSection,
  OrderHeaderNotice,
  FixFooterLoginSection,
  EmptyStores,
} from './components';
import ImageSlick from '~/components/ImageSlick';
import PickupSettingSimpleModal from '~/components/PickupSettingSimpleModal';
import LoginModal from '~/components/LoginModal';
import NotOperatingTimeNotice from '~/components/NotOperatingTimeNotice';

import menuNavListSelector from '~/recoil/selectors/menuNavList';
import categoryNavListSelector from '~/recoil/selectors/categoryNavList';
import categoryItemListSelector from '~/recoil/selectors/categoryItemList';

import useScroll from '~/hooks/useScroll';
import usePickMenu from './hooks/usePickMenu';
import usePickCategory from './hooks/usePickCategory';
import useItemPosition from './hooks/useItemPosition';

import { inProgressOrderNumberListener } from '~/firebase/listener';

import calcCartItemsPrice from '~/utils/calcCartItemsPrice';
import { sumBy } from 'lodash';
import useUser from '~/hooks/useUser';

export default function MenuPage() {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { positionInfo } = useScroll();
  const { itemPosition } = useItemPosition();
  const { isLogin, login } = useUser();

  const [orderNum, setOrderNum] = useState<number>(0);

  const store = useRecoilValue(storeAtom);
  const auth = useRecoilValue(authAtom);

  const kitchenCartItems = useRecoilValue(kitchenCartItemsAtom);
  // const cartItems = kitchenCartItems;

  const menuNavList = useRecoilValue(menuNavListSelector);
  const categoryNavList = useRecoilValue(categoryNavListSelector);
  const categoryItemList = useRecoilValue(categoryItemListSelector);

  const { selectedMenu, handleChangeMenu } = usePickMenu();
  const { selectedCategory, handleChangeCategory } = usePickCategory();

  const handleSetOrdersNum = (num: number) => {
    setOrderNum(num);
  };

  const handleNavigateToOrders = () => {
    navigate(`/${storeId}/orders`);
  };

  useEffect(() => {
    let listener = () => {};
    if (auth && storeId) {
      listener = inProgressOrderNumberListener(storeId, auth, handleSetOrdersNum);
    }

    return () => {
      listener?.();
    };
  }, [storeId, auth?.uid]);

  useEffect(() => {
    //@ts-ignore
    const locationFrom = location?.state?.from || null;
    if (itemPosition && locationFrom && locationFrom.includes('item')) {
      window.scrollTo({
        top: itemPosition,
      });
    }
  }, []);

  if (!store) {
    return <Navigate to="404" />;
  }

  const orderHeaderNoticeStyles: CenterProps = {
    top: 2,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 20,
    position: 'absolute',
    margin: 'auto',
    w: 'full',
  };

  const kitchenCartItemTotal = sumBy(kitchenCartItems, ({ items }) => calcCartItemsPrice(items));

  const kitchenCartItemAmount = sumBy(kitchenCartItems, ({ items }) =>
    sumBy(items, ({ count }) => count),
  );

  const isAllowOrder = store.allowOrders;
  const isAllowRealTimeOrder = store.allowRealTimeOrder;
  const isAllowPreOder = store.preOrderSetting.allowPreOrder;

  return (
    <>
      <PageContainer
        beforeHeaderComponent={
          <FixHeaderSection
            orderNum={orderNum}
            categoryNavList={categoryNavList}
            selectedCategory={selectedCategory}
            positionInfo={positionInfo}
            onChangeCategory={handleChangeCategory}
            onNavigateToOrders={handleNavigateToOrders}
          />
        }
      >
        <Box w="full" overflow="hidden" position="relative">
          {orderNum > 0 && (
            <OrderHeaderNotice
              {...orderHeaderNoticeStyles}
              orderNum={orderNum}
              handleNavigateToOrders={handleNavigateToOrders}
            />
          )}
          <ImageSlick images={store.images} />
        </Box>
        <VStack align="stretch" px={{ base: 4, md: 4 }} py={3} spacing={4}>
          <StoreInfoSection name={store.name} operatingTime={store.operatingTime} />
          <NotOperatingTimeNotice
            timeRange={store.operatingTime}
            isAllowOrder={isAllowOrder}
            isAllowRealTimeOrder={isAllowRealTimeOrder}
            isAllowPreOder={isAllowPreOder}
          />

          {menuNavList.length > 0 ? (
            <>
              <MealPickUpSection />
              <MenuNav
                menuNavList={menuNavList}
                selectedMenuId={selectedMenu.selectedMenuId}
                onMenuChange={handleChangeMenu}
              />

              <VStack spacing={6} align="stretch">
                {categoryItemList.map((categoryItemInfo) => {
                  return (
                    <CategoryItemList
                      key={categoryItemInfo.categoryId}
                      kitchenId={selectedMenu.selectedKitchenId || ''}
                      categoryId={categoryItemInfo.categoryId}
                      categoryItemInfo={categoryItemInfo}
                      positionInfo={positionInfo}
                      selectedCategory={selectedCategory}
                      onChangeCategory={handleChangeCategory}
                    />
                  );
                })}
              </VStack>
            </>
          ) : (
            <EmptyStores />
          )}
        </VStack>

        {isLogin ? (
          <FixFooterSection subtotal={kitchenCartItemTotal} itemAmount={kitchenCartItemAmount} />
        ) : (
          <FixFooterLoginSection login={login} />
        )}
      </PageContainer>

      {/* <PickupSettingModal /> */}
      <PickupSettingSimpleModal />
      {/* <GroupOrderNoticeModal icon={IoReceiptOutline} /> */}
      <LoginModal />
    </>
  );
}
