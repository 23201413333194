import { HStack, Icon, Text } from '@chakra-ui/react';
import { AiOutlineClockCircle } from 'react-icons/ai';

import { WsOrderStatus } from '~/types';

export type OrderTimeInfoProps = {
  time?: string;
  status: WsOrderStatus;
  isPreOrder: boolean;
};

export default function OrderTimeInfo({ status, time, isPreOrder }: OrderTimeInfoProps) {
  let infoText = <Text fontSize="sm">預計完成時間 {time}</Text>;
  if (status === WsOrderStatus.CANCELLED || status === WsOrderStatus.FAILED) {
    infoText = <Text fontSize="sm">已取消</Text>;
  }
  if (status === WsOrderStatus.COMPLETED) {
    infoText = <Text fontSize="sm">完成時間 {time}</Text>;
  }
  if (status !== WsOrderStatus.FAILED && isPreOrder) {
    infoText = <Text fontSize="sm">預定時間 {time}</Text>;
  }

  return (
    <HStack spacing={0} px={2}>
      <Icon as={AiOutlineClockCircle} mr={2} boxSize="1.125rem" />
      {infoText}
    </HStack>
  );
}
