import React from 'react';
import { VStack, FormControl, FormLabel, InputGroup } from '@chakra-ui/react';
import DisplayInput from './DisplayInput';

type ContactInfoProps = {
  phoneNumber: string;
  name: string;
};

// 聯絡資訊顯示
export default function ContactInfo({ name, phoneNumber }: ContactInfoProps) {
  return (
    <VStack align="stretch" spacing={4}>
      <FormControl>
        <FormLabel fontWeight="normal">姓名</FormLabel>
        <InputGroup>
          <DisplayInput isReadOnly value={name} fontWeight="normal" />
        </InputGroup>
      </FormControl>

      <FormControl>
        <FormLabel fontWeight="normal">手機</FormLabel>
        <InputGroup>
          <DisplayInput isReadOnly value={phoneNumber} fontWeight="normal" />
        </InputGroup>
      </FormControl>
    </VStack>
  );
}
