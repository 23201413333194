import { HStack, Text, Button } from '@chakra-ui/react';
import formatPriceNumber from '~/utils/formatPriceNumber';

// 結帳按鈕
export default function CheckOutBar({
  total,
  title,
  onCheckout,
}: {
  total: number;
  title: string;
  onCheckout?: () => void;
}) {
  const isDisabled = total === 0;
  return (
    <HStack
      justify="space-between"
      px={4}
      py={3}
      bg={isDisabled ? 'wsGray.400' : ''}
      bgGradient={isDisabled ? '' : 'linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)'}
    >
      <Text color="white">{formatPriceNumber(total)}</Text>
      <Button
        variant="unstyled"
        color="white"
        onClick={onCheckout}
        isDisabled={isDisabled}
        _disabled={{ color: 'white',cursor:"unset" }}
      >
        {title}
      </Button>
    </HStack>
  );
}
