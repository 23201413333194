import { useToast } from '@chakra-ui/react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import firebase from 'firebase';

import { updateCartData, updateGroupCart } from '~/firebase/updateData';

import useUser from '~/hooks/useUser';
import useOrderSetting from '~/hooks/useOrderSetting';
import useModalControl from '~/hooks/useModalControl';

import kitchenCartItemsAtom from '~/recoil/atom/kitchenCartItems';

import convertMealPickupType from '~/utils/convertMealPickupType';

import { MealPickUpType, PickMealTime, Location, FirebaseCart, CartItem } from '~/types';

type useUpdateOrderSettingParams = {
  mealPickupType: MealPickUpType;
  selectedTime: PickMealTime;
  selectedTimeError: string | null;
  preOrderDate: Date | null;
};

export default function useUpdateOrderSetting({
  mealPickupType,
  selectedTime,
  selectedTimeError,
  preOrderDate,
}: useUpdateOrderSettingParams) {
  const toast = useToast();

  const { storeId } = useParams();
  const { auth, isLogin } = useUser();

  const kitchenCartItems = useRecoilValue(kitchenCartItemsAtom);

  const { handleOrderSettingUpdate } = useOrderSetting();

  const { changeModalOpen } = useModalControl();

  const handlePickupSettingUpdate = async (isShouldClearCart: boolean) => {
    let errorMsg = '';

    if (selectedTime === PickMealTime.IMMEDIATELY && selectedTimeError) {
      toast({ title: selectedTimeError, status: 'error', position: 'top' });
      return;
    }

    if (selectedTime === PickMealTime.RESERVATION && !preOrderDate) {
      errorMsg = '請選擇預約時間';
      toast({ title: errorMsg, status: 'error', position: 'top' });
      return;
    }

    const newOrderSetting = {
      mealPickupType,
      // location: location ? location : undefined,
      pickMealTime: selectedTime,
      preOrderDate: selectedTime === PickMealTime.RESERVATION && preOrderDate ? preOrderDate : null,
    };

    const newOrders: Array<{
      name: string;
      kitchenId: string;
      items: Array<CartItem>;
    }> = (() => {
      if (isShouldClearCart) return [];
      return kitchenCartItems;
    })();

    const firebaseUpdateData: Partial<FirebaseCart> = {
      orderType: convertMealPickupType(mealPickupType, selectedTime),
      // location: location ? location : undefined,
      preOrderDate:
        selectedTime === PickMealTime.RESERVATION && preOrderDate
          ? firebase.firestore.Timestamp.fromDate(preOrderDate)
          : null,
      orders: newOrders,
    };

    if (!isLogin || !auth) {
      handleOrderSettingUpdate(newOrderSetting);
      changeModalOpen('isPickupSettingOpen', false);
      return;
    }

    if (auth) {
      try {
        await updateCartData(storeId, auth, firebaseUpdateData);
        handleOrderSettingUpdate(newOrderSetting);
        changeModalOpen('isPickupSettingOpen', false);
        return;
      } catch (error) {
        console.log('[updateCartData Error]', error);
      }
    }
  };

  return handlePickupSettingUpdate;
}
