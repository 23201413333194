import { useEffect } from 'react';
import {
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Link,
} from '@chakra-ui/react';

import LoginForm from '~/components/LoginForm';

import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import authAtom from '~/recoil/atom/auth';
import isLoginModalOpenSelector from '~/recoil/selectors/isLoginModalOpen';
import { useRecoilValue, useRecoilState } from 'recoil';
import Title from './Title';

// 登入頁面
export default function LoginModal() {
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);
  const auth = useRecoilValue(authAtom);
  const [isLoginModalOpen, setLoginModalOpen] = useRecoilState(isLoginModalOpenSelector);

  useEffect(() => {
    if (auth) {
      setLoginModalOpen(false);
    }
  }, [auth]);

  return (
    <Modal isOpen={isLoginModalOpen} onClose={() => setLoginModalOpen(false)} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent py={4} m={0} h={isLargerThan768 ? '95%' : 'full'}>
        <ModalHeader>
          <Title title="手機登入" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="stretch" px={{ base: 4, md: 4 }} spacing={4}>
            <VStack align="stretch" spacing={1}>
              <Text fontSize="xs">手機登入後，系統將會記錄您的會員資訊</Text>
              <Text fontSize="xs">未來使用同一個瀏覽器點餐，將會自動帶入聯絡人資訊。</Text>
            </VStack>

            <LoginForm />
          </VStack>
        </ModalBody>
        <ModalFooter p={0}>
          <HStack w="full" justify="center" p={2} spacing={0.5}>
            <Text fontSize="xs">登入後代表同意weserve</Text>
            <Link
              letterSpacing={0.8}
              fontSize="xs"
              color="#C8161D"
              href="https://www.weserve.tw/privacy/"
              isExternal
            >
              隱私權政策
            </Link>
            <Text fontSize="xs">與</Text>
            <Link
              letterSpacing={0.8}
              fontSize="xs"
              color="#C8161D"
              href="https://www.weserve.tw/terms/"
              isExternal
            >
              利用章規
            </Link>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
