import { useEffect, useRef, useState } from 'react';
import ModifierOptionsSelectorItem from './ModifierOptionsSelectorItem';
import { ListItem, Box, VStack, Text, StackDivider, CheckboxGroup } from '@chakra-ui/react';
import { ScrollPositionInfo } from '~/hooks/useScroll';
import { ModifierWithId, OrderModifier, OrderOption } from '~/types';

type ModifierOptionsSingleSelectorProps = {
  modifier: ModifierWithId;
  selectedModifiers: Map<string, OrderModifier>;
  modifierErrors: Set<string>;

  isErrorShow: boolean;
  positionInfo: ScrollPositionInfo;
  onChangeModifier: (modifiers: Map<string, OrderModifier>) => void;
};

// 餐點選項（單選）區塊，顯示若干個單一選項組件
export default function ModifierOptionsSingleSelector({
  modifier,
  modifierErrors,
  selectedModifiers,
  isErrorShow = false,
  positionInfo,
  onChangeModifier,
}: ModifierOptionsSingleSelectorProps) {
  const [isFullWidth, setIsFullWidth] = useState<boolean>(false);
  const modifierRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modifierRef.current) {
      modifierRef.current.getBoundingClientRect().top < 30
        ? setIsFullWidth(true)
        : setIsFullWidth(false);
    }
  }, [positionInfo]);

  const handleSelectOption = (option: OrderOption) => {
    const newModifiers = new Map(selectedModifiers);

    const selectedOptions = newModifiers.get(modifier.id)?.options || [];

    if (!modifier.required && selectedOptions.some(({ id }) => option.id === id)) {
      newModifiers.set(modifier.id, {
        id: modifier.id,
        name: modifier.name,
        options: selectedOptions.filter(({ id }) => id !== option.id),
      });
      onChangeModifier(newModifiers);
      return;
    }

    newModifiers.set(modifier.id, {
      id: modifier.id,
      name: modifier.name,
      options: [option],
    });
    onChangeModifier(newModifiers);
  };

  const { id, name, required, options } = modifier;

  const selectedOptions = selectedModifiers.get(id)?.options || [];

  const isShoeError = isErrorShow && modifierErrors.has(modifier.id);

  return (
    <ListItem>
      <VStack align="stretch" spacing={3} ref={modifierRef}>
        <VStack
          position="sticky"
          top="0"
          zIndex="200"
          align="flex-start"
          px={2}
          py={1}
          mx={isFullWidth ? -4 : 0}
          spacing={1}
          bgColor={isShoeError ? 'wsRed.200' : 'wsGray.200'}
          borderRadius="5px"
        >
          <Text
            as="h4"
            fontSize="sm"
            fontWeight="bold"
            color={isShoeError ? 'wsRed.600' : 'wsBlack'}
          >
            {name}
            {required && (
              <Box as="span" color="red">
                *
              </Box>
            )}
          </Text>
          <Text fontSize="xs" color={isShoeError ? 'wsRed.600' : 'wsGray.500'}>
            {required ? '必填' : '選填'}（1項）
          </Text>
        </VStack>
        <CheckboxGroup colorScheme="checkBox">
          <VStack align="stretch" divider={<StackDivider borderColor="wsGray.300" />}>
            {options.map(({ id, name, inventory, price }) => {
              return (
                <ModifierOptionsSelectorItem
                  key={id}
                  optionName={name}
                  price={price}
                  isChecked={selectedOptions.some((option) => id === option.id)}
                  isDisabled={inventory.currentInventory < 1}
                  onChecked={() => handleSelectOption({ id, name, price })}
                />
              );
            })}
          </VStack>
        </CheckboxGroup>
      </VStack>
    </ListItem>
  );
}
