import { useEffect, useState, useRef } from 'react';
import { ListItem, Box, VStack, Text, StackDivider, CheckboxGroup } from '@chakra-ui/react';
import ModifierOptionsSelectorItem from './ModifierOptionsSelectorItem';
import { ScrollPositionInfo } from '~/hooks/useScroll';

import { ModifierWithId, OrderModifier, OrderOption } from '~/types';

type ModifierOptionsMultiSelectorProps = {
  modifier: ModifierWithId;
  selectedModifiers: Map<string, OrderModifier>;
  modifierErrors: Set<string>;

  isErrorShow: boolean;
  positionInfo: ScrollPositionInfo;
  onChangeModifier: (modifiers: Map<string, OrderModifier>) => void;
};

// 餐點選項（多選）區塊，顯示若干個單一選項組件
export default function ModifierOptionsMultiSelector({
  modifier,
  selectedModifiers,
  modifierErrors,
  isErrorShow = false,
  positionInfo,
  onChangeModifier,
}: ModifierOptionsMultiSelectorProps) {
  const [isFullWidth, setIsFullWidth] = useState<boolean>(false);
  const modifierRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modifierRef.current) {
      modifierRef.current.getBoundingClientRect().top < 30
        ? setIsFullWidth(true)
        : setIsFullWidth(false);
    }
  }, [positionInfo]);

  const handleSelectOption = (option: OrderOption) => {
    const newModifiers = new Map(selectedModifiers);

    const selectedOptions = newModifiers.get(modifier.id)?.options || [];

    let updateOptions = [...selectedOptions];

    // if option exist in array then unchecked option
    if (selectedOptions.some(({ id }) => option.id === id)) {
      newModifiers.set(modifier.id, {
        id: modifier.id,
        name: modifier.name,
        options: updateOptions.filter(({ id }) => id !== option.id),
      });

      onChangeModifier(newModifiers);
      return;
    }

    updateOptions.push(option);
    if (updateOptions.length > modifier.max) {
      updateOptions.shift();
    }

    // checked option
    newModifiers.set(modifier.id, {
      id: modifier.id,
      name: modifier.name,
      options: updateOptions,
    });
    onChangeModifier(newModifiers);
  };

  const { id, name, required, options, min, max } = modifier;

  const selectedOptions = selectedModifiers.get(id)?.options || [];

  const isShowError = isErrorShow && modifierErrors.has(modifier.id);

  return (
    <ListItem>
      <VStack align="stretch" spacing={3} ref={modifierRef}>
        <VStack
          position="sticky"
          top="0"
          zIndex="200"
          align="flex-start"
          px={2}
          py={1}
          mx={isFullWidth ? -4 : 0}
          spacing={1}
          bgColor={isShowError ? 'wsRed.200' : 'wsGray.200'}
          borderRadius="5px"
        >
          <Text
            as="h4"
            fontSize="sm"
            fontWeight="bold"
            color={isShowError ? 'wsRed.600' : 'wsBlack'}
          >
            {name}
            {required && (
              <Box as="span" color="red">
                *
              </Box>
            )}
          </Text>
          <Text fontSize="xs" color={isShowError ? 'wsRed.600' : 'wsGray.500'}>
            {required ? '必填 ' : '選填 '}
            {max === min ? `(${min}項)` : `(${min}~${max}項)`}
          </Text>
        </VStack>
        <CheckboxGroup colorScheme="checkBox">
          <VStack align="stretch" divider={<StackDivider borderColor="wsGray.300" />}>
            {options.map(({ id, name, inventory, price }) => {
              return (
                <ModifierOptionsSelectorItem
                  key={id}
                  optionName={name}
                  price={price}
                  isChecked={selectedOptions.some((option) => id === option.id)}
                  isDisabled={inventory.currentInventory < 1}
                  onChecked={() => handleSelectOption({ id, name, price })}
                />
              );
            })}
          </VStack>
        </CheckboxGroup>
      </VStack>
    </ListItem>
  );
}
