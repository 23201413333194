import { Item } from '~/types';

const filterSoldOutItemIds = ({
  items,
  itemIds,
}: {
  items: { [key in string]: Item };
  itemIds: string[];
}): string[] => {
  return itemIds.filter((id) => {
    const item = items[id];
    return !item.isSoldOut || item.inventory.currentInventory !== 0;
  });
};

export default filterSoldOutItemIds;
