import React from 'react';

import { Flex, HStack, Text } from '@chakra-ui/react';

import { Schedule } from '~/types';

import checkOperatingTime from '~/utils/checkOperatingTime';

type NotOperatingTimeNoticeProps = {
  timeRange: Schedule;
  time?: Date;
  isItem?: boolean;
  isAllowOrder: boolean;
  isAllowRealTimeOrder: boolean;
  isAllowPreOder: boolean;
};

// 非營業時間通知：分為目前非營業時間以及訂單過大暫停關閉營業
export default function NotOperatingTimeNotice({
  timeRange,
  time,
  isItem,
  isAllowOrder,
  isAllowRealTimeOrder,
  isAllowPreOder,
}: NotOperatingTimeNoticeProps) {
  const isPreOrder = time;
  const isNotOperatingTime = time
    ? !checkOperatingTime(timeRange, time)
    : !checkOperatingTime(timeRange);

  // 僅限預定不提供立刻取餐
  const isAllowOnlyPreOrder = (isAllowOrder && !isNotOperatingTime && !isAllowRealTimeOrder);

  const noticeText = (): string => {
    if (!isAllowOrder) {
      return '店家暫時關閉中';
    }

    if (isPreOrder && isNotOperatingTime && isAllowPreOder) {
      return '預定時間不符合';
    }

    if (!isPreOrder && isNotOperatingTime && !isAllowRealTimeOrder && isAllowPreOder) {
      return '店家僅開放預約功能';
    }

    if (!isPreOrder && !isNotOperatingTime && !isAllowRealTimeOrder) {
      return '僅限預定不提供立刻取餐';
    }

    if (!isPreOrder && isNotOperatingTime && isAllowRealTimeOrder) {
      return '目前非營業時間';
    }

    return '';
  };

  const displayText = noticeText();

  return (
    <>
      {displayText && (
        <HStack
          bgColor={isAllowOnlyPreOrder ? 'wsGray.200' : 'wsRed.200'}
          borderRadius={isItem ? '' : '5px'}
          position={isItem ? 'sticky' : 'relative'}
          zIndex={isItem ? 1000 : 0}
          bottom={isItem ? '0' : '0'}
          p={3}
        >
          <Text color="wsRed.600" fontSize="sm" fontWeight="bold">
            {displayText}
          </Text>
        </HStack>
      )}
    </>
  );
}
