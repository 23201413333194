import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Text, VStack, Select } from '@chakra-ui/react';

import preOrderDateAtom from '~/recoil/atom/preOrderDate';

type PickupDurationSelectProps = {
  durationOptionList: { durationString: string; value: number }[] | undefined;
  selectedDay: Date | undefined;
  selectedDuration: number | undefined;
  onDurationSelect: (minutes: number) => void;
};

// 時間選擇
export default function PickupDurationSelect({
  durationOptionList,
  selectedDuration,
  selectedDay,
  onDurationSelect,
}: PickupDurationSelectProps) {
  const preOrderDate = useRecoilValue(preOrderDateAtom);
  useEffect(() => {
    if (preOrderDate || !durationOptionList) return;
    const initialValue = durationOptionList[0]?.value;
    onDurationSelect(initialValue);
  }, [durationOptionList, preOrderDate]);

  useEffect(() => {
    if (!selectedDay || !durationOptionList) return;
    const initialValue = durationOptionList[0]?.value;
    onDurationSelect(initialValue);
  }, [durationOptionList, selectedDay]);

  const handleDurationSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onDurationSelect(Number(e.target.value));
  };

  return (
    <VStack align="stretch">
      <Text fontSize="sm">選擇時段</Text>
      <Select
        onChange={handleDurationSelect}
        value={selectedDuration}
        variant="flushed"
        borderBottom="4px solid"
        borderBottomColor="wsBlack"
        _focus={{
          boxShadow: 'unset',
        }}
      >
        {durationOptionList?.map(({ durationString, value }) => {
          return (
            <option key={durationString} value={value}>
              {durationString}
            </option>
          );
        })}
      </Select>
    </VStack>
  );
}
