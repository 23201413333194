import firebase from 'firebase';
import React from 'react';
import { format } from 'date-fns';
import { VStack, HStack, Text, Icon, Box } from '@chakra-ui/react';

import FailOrderNote from './FailOrderNote';

import { FaCircle } from 'react-icons/fa';
import { getMatchOrderTypeProgress } from './orderTypeProgress';
import { OrderType, WsOrderStatus } from '~/types';

type OrderStatusProgressProps = {
  orderStatus: WsOrderStatus;
  statusRecord: Partial<Record<WsOrderStatus, firebase.firestore.Timestamp>>;
  type: OrderType;
  isSelfDelivery?: boolean;
  createdTime: string;
  total: number;
  isAbandonOrder: boolean;
  isTransactionFailed?: boolean;
  reasonForCancelling: Array<string>;
};

export default function OrderStatusProgress({
  orderStatus,
  statusRecord,
  type,
  isSelfDelivery = false,
  createdTime,
  total,
  isAbandonOrder,
  isTransactionFailed = false,
  reasonForCancelling,
}: OrderStatusProgressProps) {
  if ([WsOrderStatus.FAILED, WsOrderStatus.CANCELLED, WsOrderStatus.UNPAID].includes(orderStatus)) {
    return (
      <FailOrderNote
        noteType={orderStatus}
        createdTime={createdTime}
        total={total}
        isAbandonOrder={isAbandonOrder}
        isTransactionFailed={isTransactionFailed}
        reasonForCancelling={reasonForCancelling}
      />
    );
  }

  const orderTypeProgress = getMatchOrderTypeProgress(type, isSelfDelivery);

  const handleFormatTimeString = (time: firebase.firestore.Timestamp | undefined) => {
    if (time) {
      return `${format(time.toDate(), 'HH:mm')} 完成`;
    }
    return '';
  };

  // retry status act as prepared status
  if (orderStatus === WsOrderStatus.RETRY) {
    orderStatus = WsOrderStatus.PREPARED;
  }

  return (
    <VStack
      align="stretch"
      px={2}
      py={4}
      borderWidth="1px"
      borderColor="wsGray.400"
      borderRadius="5px"
      spacing={0}
    >
      {orderTypeProgress.map(({ label, status }, index) => {
        return (
          <React.Fragment key={status}>
            {index !== 0 && (
              <Box position="relative" w={0.5} h={3} bgColor="wsGray.400" left="7px" />
            )}
            <HStack key={index}>
              <Icon
                as={FaCircle}
                boxSize="1rem"
                color={status === orderStatus ? 'wsGreen.500' : 'wsGray.400'}
              />

              <Text flex="1" fontSize="sm" fontWeight={status === orderStatus ? 'bold' : 'normal'}>
                {label}
              </Text>

              <Text fontSize="sm" color="wsGray.400">
                {handleFormatTimeString(
                  index < orderTypeProgress.length - 1
                    ? statusRecord[orderTypeProgress[index + 1].status]
                    : undefined,
                )}
              </Text>
            </HStack>
          </React.Fragment>
        );
      })}
    </VStack>
  );
}
