import { VStack, Text, Icon, Button, Image, HStack, Link, Divider } from '@chakra-ui/react';

import { BtnVariant } from '~/customTheme/components/Button';

import OrderStatusProgress from './OrderStatusProgress';
import DisplayInput from './DisplayInput';
import DisplayEmail from './DisplayEmail';
import DisplayTaxSection from './DisplayTaxSection';
import OrderTypeSection from './OrderTypeSection';
import SectionContainer from '~/container/SectionContainer';
import ProductItem from '~/components/ProductItem';
import GroupMemberProductItems from '~/components/GroupMemberProductItems';
import TotalPriceSection from '~/components/TotalPriceSection';

import {
  IoRestaurantOutline,
  IoCashOutline,
  IoGolfOutline,
  IoChevronForward,
  IoReaderOutline,
} from 'react-icons/io5';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { GrLocation } from 'react-icons/gr';
import LINEPay from '~/assets/images/LINEPay.png';

import {
  OrderType,
  OrderWithId,
  PaymentType,
  WsOrderStatus,
  PickupTimeRange,
  DeliveryTimeRange,
} from '~/types';
import { format, getDay, add } from 'date-fns';
import MallStoreProductItems from '~/components/MallStoreProductItems';

type OrderDetailProps = {
  order: OrderWithId;
  onOpen: () => void;
  onOpenAlert: () => void;
  onOpenContact: () => void;
};

const MINUTES_IN_A_HOUR = 60;

// 訂單詳細
export default function OrderDetail({
  order,
  onOpen,
  onOpenAlert,
  onOpenContact,
}: OrderDetailProps) {
  const {
    id,
    status,
    isGroupOrder,
    type,
    delivery,
    discount,
    statusRecord,
    groupItems,
    paymentType,
    taxType,
    taxInfo,
    shippingFeeDetails,
    store,
    subtotal,
    total,
    paymentInfo,
    preOrderAt,
    createdAt,
    largeOrderPrepareMinutes,
    reasonForCancelling = [],
    allowPaymentNotRefund = false,
    orders,
    invoiceEmail,
  } = order;

  const paymentTypeText = {
    [PaymentType.NONE]: <></>,
    [PaymentType.CASH]: <Text>現金</Text>,
    [PaymentType.CREDIT]: (
      <HStack justify="space-between">
        <Text>信用卡</Text>
        <HStack spacing={1} align="center">
          {paymentInfo?.createdAt && (
            <>
              <Link
                fontSize="xs"
                fontWeight="bold"
                color="wsGray.500"
                textDecoration="none"
                onClick={onOpen}
              >
                展開明細
              </Link>
              <Icon as={IoChevronForward} color="wsGray.500" boxSize="0.85rem" />
            </>
          )}
        </HStack>
      </HStack>
    ),
    [PaymentType.LINEPAY]: (
      <HStack justify="space-between">
        <Image src={LINEPay} h={4} />
        <HStack spacing={1} align="center">
          {paymentInfo && (
            <>
              <Link
                fontSize="xs"
                fontWeight="bold"
                color="wsGray.500"
                textDecoration="none"
                onClick={onOpen}
              >
                展開明細
              </Link>
              <Icon as={IoChevronForward} color="wsGray.500" boxSize="0.85rem" />
            </>
          )}
        </HStack>
      </HStack>
    ),
  };

  const handleTimeFormat = ({
    statusTime,
    after = 0,
    range = 0,
  }: {
    statusTime: Date | undefined;
    after?: number;
    range?: number;
  }) => {
    const weekday = statusTime ? getDay(statusTime) : 7;
    const weekDayConverter = ['日', '一', '二', '三', '四', '五', '六', ''];
    const prepareTimeRange = range
      ? format(add(statusTime || new Date(), { minutes: after + range }), ' ~ HH:mm')
      : '';
    return statusTime
      ? format(add(statusTime, { minutes: after }), `M/dd(${weekDayConverter[weekday]}) HH:mm`) +
          prepareTimeRange
      : ' - ';
  };

  const orderPrepareMinutes = largeOrderPrepareMinutes
    ? largeOrderPrepareMinutes
    : store.orderPrepareMinutes;
  const createdTime = handleTimeFormat({ statusTime: createdAt.toDate() });

  let displayTime = null;
  switch (status) {
    case WsOrderStatus.CANCELLED:
    case WsOrderStatus.FAILED:
    case WsOrderStatus.CREATED:
      displayTime = '-';
      break;
    case WsOrderStatus.COMPLETED:
      displayTime = handleTimeFormat({
        statusTime: statusRecord[WsOrderStatus.COMPLETED]?.toDate(),
      });
      break;
    case WsOrderStatus.PICKED:
      displayTime = handleTimeFormat({
        statusTime: statusRecord[WsOrderStatus.PICKED]?.toDate(),
        after: Math.floor((delivery?.estimateInTraffic || 0) / MINUTES_IN_A_HOUR),
        range: DeliveryTimeRange,
      });
      break;
    default:
      displayTime = handleTimeFormat({
        statusTime: statusRecord[WsOrderStatus.ACCEPTED]?.toDate(),
        after: orderPrepareMinutes,
        range: type === OrderType.WS_PICKUP ? PickupTimeRange : DeliveryTimeRange,
      });
  }
  if (preOrderAt && status !== WsOrderStatus.COMPLETED) {
    displayTime = handleTimeFormat({
      statusTime: preOrderAt.toDate(),
      after: 0,
      range: type === OrderType.WS_PREORDER_PICKUP ? PickupTimeRange : DeliveryTimeRange,
    });
  }

  const createdTimeForFailNote = format(createdAt.toDate(), 'yyyy/MM/dd HH:mm');

  return (
    <VStack align="stretch" spacing={5}>
      <OrderTypeSection isDelivery={!!delivery} />
      {status === WsOrderStatus.FAILED && (
        <SectionContainer title="餐點進度" icon={<Icon as={IoGolfOutline} boxSize="1.5rem" />}>
          <OrderStatusProgress
            orderStatus={status}
            type={type}
            isSelfDelivery={delivery?.isSelfDelivery}
            statusRecord={statusRecord}
            createdTime={createdTimeForFailNote}
            total={total}
            isAbandonOrder={allowPaymentNotRefund}
            isTransactionFailed={paymentInfo?.isTransactionFailed}
            reasonForCancelling={reasonForCancelling}
          />
        </SectionContainer>
      )}

      <SectionContainer
        title={
          status === WsOrderStatus.COMPLETED
            ? '完成時間'
            : preOrderAt
            ? '預定時間'
            : '預計取餐完成時間'
        }
        icon={<Icon as={AiOutlineClockCircle} boxSize="1.5rem" />}
      >
        <VStack spacing={2} align="stretch">
          <DisplayInput displayText={displayTime} />
          <Text fontSize="sm">下單時間：{createdTime}</Text>
        </VStack>
      </SectionContainer>
      <Divider />
      <SectionContainer title="餐點" icon={<Icon as={IoRestaurantOutline} boxSize="1.5rem" />}>
        {orders.map((order) => (
          <MallStoreProductItems order={order} />
        ))}
      </SectionContainer>
      <Divider />
      <SectionContainer title="電子發票" icon={<Icon as={IoReaderOutline} boxSize="1.5rem" />}>
        <DisplayEmail displayText={invoiceEmail} />
      </SectionContainer>
      <DisplayTaxSection taxInfo={taxInfo} taxType={taxType} />
      <Text fontSize="sm">weserve 點餐系統僅提供發票資訊給店家，實際開立發票方式由店家決定。</Text>
      <Divider />
      <SectionContainer title="付款方式" icon={<Icon as={IoCashOutline} boxSize="1.5rem" />}>
        {paymentTypeText[paymentType]}
      </SectionContainer>
      <Divider />
      <TotalPriceSection
        orderType={type}
        subtotal={subtotal}
        shippingFee={shippingFeeDetails?.customer.value}
        discount={discount}
        total={total}
        freightSubsidy={shippingFeeDetails?.customer.discount}
      />
      {status === WsOrderStatus.CREATED && (
        <Button
          variant={BtnVariant.OUTLINE}
          borderColor="wsGray.400"
          fontWeight="normal"
          fontSize="sm"
          onClick={onOpenAlert}
        >
          取消訂單
        </Button>
      )}
      {![
        WsOrderStatus.CREATED,
        WsOrderStatus.COMPLETED,
        WsOrderStatus.FAILED,
        WsOrderStatus.CANCELLED,
      ].includes(status) && (
        <Button
          variant={BtnVariant.OUTLINE}
          borderColor="wsGray.400"
          fontWeight="normal"
          fontSize="sm"
          onClick={onOpenContact}
        >
          聯絡店家
        </Button>
      )}
    </VStack>
  );
}
