import { useState, useMemo, useEffect } from 'react';
import {} from 'recoil';
import { Item, OrderModifier, OrderOption, ModifierWithId } from '~/types';

import sumBy from 'lodash/sumBy';

export default function useModifiersControl(
  modifiers: Array<ModifierWithId> | null,
  {
    defaultModifiers = [],
    defaultCount = 1,
    defaultNote = '',
  }: {
    defaultModifiers?: Array<OrderModifier>;
    defaultCount?: number;
    defaultNote?: string;
  },
) {
  const [selectedModifiers, setSelectedModifiers] = useState<Map<string, OrderModifier>>(
    defaultModifiers.reduce((acc: Map<string, OrderModifier>, modifier) => {
      acc.set(modifier.id, modifier);
      return acc;
    }, new Map()),
  );
  const [modifierErrors, setModifierErrors] = useState<Set<string>>(new Set());
  const [count, setCount] = useState<number>(defaultCount);
  const [note, setNote] = useState<string>(defaultNote);

  // check modifier error options
  useEffect(() => {
    if (modifiers) {
      const newModifierErrors: Set<string> = new Set();
      modifiers.forEach(({ required, min, max, id }) => {
        const options = selectedModifiers.get(id)?.options || [];

        const requireMin = required && min === 0 ? min + 1 : min;

        if (options.length > max || options.length < requireMin) {
          newModifierErrors.add(id);
        }
      });
      setModifierErrors(newModifierErrors);
    }
  }, [modifiers, selectedModifiers]);

  const handleChangeModifier = (modifiers: Map<string, OrderModifier>) => {
    setSelectedModifiers(modifiers);
  };

  const handleChangeCount = (num: number) => {
    setCount(num);
  };

  const handleChangeNote = (note: string) => {
    setNote(note);
  };

  const optionsTotalPrice = useMemo(() => {
    let options: Array<OrderOption> = [];
    selectedModifiers.forEach(({ options: modifierOptions }) => {
      options = options.concat(modifierOptions);
    });
    return sumBy(options, ({ price }) => price);
  }, [selectedModifiers]);

  return {
    selectedModifiers,
    modifierErrors,
    optionsTotalPrice,
    count,
    note,
    handleChangeCount,
    handleChangeModifier,
    handleChangeNote,
  };
}
