import { firestore } from '~/firebase';
import { useDisclosure } from '@chakra-ui/react';

import { useParams } from 'react-router-dom';

import React, { useState } from 'react';
import authAtom from '~/recoil/atom/auth';
import storeAtom from '~/recoil/atom/store';
import cartStatusAtom from '~/recoil/atom/cartStatus';
import cartAtom from '~/recoil/selectors/cart';

import { useRecoilValue } from 'recoil';

import checkOperatingTime from '~/utils/checkOperatingTime';
import convertOrderType from '~/utils/convertOrderType';

import { CartStatus, MealPickUpType, PickMealTime, TaxType } from '~/types';

import checkoutOrder from '~/httpApi/checkoutOrder';
import getPaymentRedirectUrl from '~/utils/getPaymentRedirectUrl';
import validTaxInvoice from '~/utils/validTaxNumber';
import checkAllowOrderKitchen from '~/utils/checkNotAllowOrderKitchen';
import { differenceInMinutes } from 'date-fns';

export enum CheckoutErrorType {
  NONE = 'NONE',
  STORE_NOT_ALLOW_ORDER = 'STORE_NOT_ALLOW_ORDER',
  STORE_NOT_ALLOW_PREORDER = 'STORE_NOT_ALLOW_PREORDER',
  INVALID_CART_STATUS = 'INVALID_CART_STATUS',
  INVALID_ORDER_TIME = 'INVALID_ORDER_TIME',
  INVALID_ADDRESS = 'INVALID_ADDRESS',
  INVALID_USER = 'INVALID_USER',
  INVALID_PAYMENT = 'INVALID_PAYMENT',
  INVALID_ORDER_TYPE = 'INVALID_ORDER_TYPE',
  INVALID_ITEM = 'INVALID_ITEM',
  INVALID_EMPTY_CART = 'INVALID_EMPTY_CART',
  INVALID_PREORDER_DATE = 'INVALID_PREORDER_DATE',
  OVER_PREORDER_DATE = 'OVER_PREORDER_DATE',
  INVALID_COUPON = 'INVALID_COUPON',
  INVALID_TAX_INFO = 'INVALID_TAX_INFO',
  INVALID_TAX_INVOICE = 'INVALID_TAX_INVOICE',
  MISSING_ADDRESS = 'MISSING_ADDRESS',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  NOT_LOGIN = 'NOT_LOGIN',
  EMAIL_ERROR = 'EMAIL_ERROR',
}

const checkoutErrorMapString: Record<CheckoutErrorType, string> = {
  [CheckoutErrorType.NONE]: '',
  [CheckoutErrorType.STORE_NOT_ALLOW_ORDER]: '店家目前不接受訂單，請稍後再試',
  [CheckoutErrorType.STORE_NOT_ALLOW_PREORDER]: '店家目前不接受預約',
  [CheckoutErrorType.INVALID_CART_STATUS]: '您的訂單正在處理中，無法重複下單',
  [CheckoutErrorType.INVALID_ORDER_TIME]: '現在非營業時間，無法下單',
  [CheckoutErrorType.INVALID_ADDRESS]: '運費錯誤或超出外送距離',
  [CheckoutErrorType.INVALID_USER]: '使用者不符',
  [CheckoutErrorType.INVALID_PAYMENT]: '未選擇正確的付款方式',
  [CheckoutErrorType.INVALID_ORDER_TYPE]: '取餐方式不符',
  [CheckoutErrorType.INVALID_ITEM]: '購物車內有不符取餐方式的品項自動移除',
  [CheckoutErrorType.INVALID_EMPTY_CART]: '您的購物車是空的',
  [CheckoutErrorType.INVALID_PREORDER_DATE]: '預定時間未填寫或填寫錯誤，請到取餐設定重新填寫',
  [CheckoutErrorType.OVER_PREORDER_DATE]: '時間已過預定時間，請重新選擇時間',
  [CheckoutErrorType.INVALID_COUPON]: '優惠卷不符',
  [CheckoutErrorType.INVALID_TAX_INFO]: '發票抬頭或統編寫錯誤',
  [CheckoutErrorType.MISSING_ADDRESS]: '運費錯誤或超出外送距離',
  [CheckoutErrorType.INTERNAL_SERVER_ERROR]: '系統發生錯誤',
  [CheckoutErrorType.NOT_LOGIN]: '請先手機驗證才可下單',
  [CheckoutErrorType.EMAIL_ERROR]: '電子郵件未填寫或填寫錯誤',
  [CheckoutErrorType.INVALID_TAX_INVOICE]: '發票抬頭或統編錯誤',
};

export default function useCheckout() {
  const { isOpen: isOpenPaymentRedirect, onOpen: onOpenPaymentRedirect } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutError, setCheckoutError] = useState<{
    type: CheckoutErrorType;
    title: string;
    renderTitle?: () => any;
    message: string;
    renderMessage?: () => React.ReactNode;
  }>({
    type: CheckoutErrorType.NONE,
    title: '',
    message: '',
  });

  const { storeId } = useParams();
  const auth = useRecoilValue(authAtom);
  const store = useRecoilValue(storeAtom);
  const cart = useRecoilValue(cartAtom);
  const cartStatus = useRecoilValue(cartStatusAtom);

  const handleCheckOut = async () => {
    if (!store) return;

    if (!auth) {
      setCheckoutError({
        type: CheckoutErrorType.NOT_LOGIN,
        title: '',
        message: checkoutErrorMapString[CheckoutErrorType.NOT_LOGIN],
      });
      return;
    }

    // 檢查購物車是否正在下單中
    if (cartStatus !== CartStatus.ACTIVE) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_CART_STATUS,
        title: '',
        message: checkoutErrorMapString[CheckoutErrorType.INVALID_CART_STATUS],
      });
      return;
    }
    const { orderType } = cart;
    const [mealPickupType, pickMealTime] = convertOrderType(orderType);

    // 檢查營業時間
    const { operatingTime } = store;
    if (pickMealTime === PickMealTime.IMMEDIATELY && !checkOperatingTime(operatingTime)) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_ORDER_TIME,
        title: '',
        message: checkoutErrorMapString[CheckoutErrorType.INVALID_ORDER_TIME],
      });
      return;
    }

    // 店家目前是否接受訂單
    const { allowOrders } = store;
    if (!allowOrders) {
      setCheckoutError({
        type: CheckoutErrorType.STORE_NOT_ALLOW_ORDER,
        title: '',
        message: checkoutErrorMapString[CheckoutErrorType.STORE_NOT_ALLOW_ORDER],
      });
      return;
    }

    // 店家是否接受預約
    const {
      preOrderSetting: { allowPreOrder },
    } = store;
    const { preOrderDate } = cart;
    if (pickMealTime === PickMealTime.RESERVATION && !allowPreOrder) {
      setCheckoutError({
        type: CheckoutErrorType.STORE_NOT_ALLOW_PREORDER,
        title: '',
        message: checkoutErrorMapString[CheckoutErrorType.STORE_NOT_ALLOW_PREORDER],
      });
      return;
    }

    // 預定時間已過
    if (
      pickMealTime === PickMealTime.RESERVATION &&
      preOrderDate &&
      differenceInMinutes(preOrderDate, new Date()) <= 0
    ) {
      setCheckoutError({
        type: CheckoutErrorType.OVER_PREORDER_DATE,
        title: '',
        message: checkoutErrorMapString[CheckoutErrorType.OVER_PREORDER_DATE],
      });
      return;
    }

    // 預定時間錯誤
    if (
      pickMealTime === PickMealTime.RESERVATION &&
      (!preOrderDate || (preOrderDate && !checkOperatingTime(operatingTime, preOrderDate)))
    ) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_PREORDER_DATE,
        title: '',
        message: checkoutErrorMapString[CheckoutErrorType.INVALID_PREORDER_DATE],
      });
      return;
    }

    // 購物車為空
    const { orders } = cart;
    if (orders.length === 0) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_EMPTY_CART,
        title: '',
        message: checkoutErrorMapString[CheckoutErrorType.INVALID_EMPTY_CART],
      });
      return;
    }

    // 店家關閉
    const { menus } = store;
    const checkDate = (() => {
      if (pickMealTime === PickMealTime.IMMEDIATELY) return new Date();
      if (pickMealTime === PickMealTime.RESERVATION) return preOrderDate || new Date();

      return new Date();
    })();

    const { validKitchenItems, invalidKitchenItems } = checkAllowOrderKitchen(
      menus,
      orders,
      checkDate,
    );

    if (invalidKitchenItems.length > 0) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_ITEM,
        title: `${invalidKitchenItems.map(({ name }) => name).join('、')}已歇業`,
        message: '抱歉，以上店家剛剛關閉營業，您所選的該店家的品項將會被自動移除',
      });
      try {
        await firestore
          .doc(`stores/${storeId}/carts/${auth.uid}`)
          .update('orders', validKitchenItems);
      } catch (err) {
        console.log('update orders error');
      }
      return;
    }

    const { email } = cart;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      setCheckoutError({
        type: CheckoutErrorType.EMAIL_ERROR,
        title: '',
        message: checkoutErrorMapString[CheckoutErrorType.EMAIL_ERROR],
      });
      return;
    }

    // 發票資訊檢查
    const { taxType, taxInfo } = cart;
    if (
      taxType === TaxType.COMPANY &&
      (taxInfo.title.length < 1 || taxInfo.invoice.length < 8 || !validTaxInvoice(taxInfo.invoice))
    ) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_TAX_INFO,
        title: '',
        message: checkoutErrorMapString[CheckoutErrorType.INVALID_TAX_INFO],
      });
      return;
    }

    const { paymentTypes } = store;
    const { paymentType } = cart;
    if (!paymentTypes.includes(paymentType)) {
      setCheckoutError({
        type: CheckoutErrorType.INVALID_PAYMENT,
        title: '',
        message: checkoutErrorMapString[CheckoutErrorType.INVALID_PAYMENT],
      });
      return;
    }

    // checkout general order
    try {
      await firestore
        .doc(`/stores/${storeId}/carts/${auth.uid}`)
        .update('status', CartStatus.ORDERING);
    } catch (err) {
      console.log('change status error');
    }

    try {
      setIsLoading(true);
      const token = await auth.getIdToken();
      const res = await checkoutOrder(storeId, token, auth.uid);
      setIsLoading(false);
      onOpenPaymentRedirect();
      getPaymentRedirectUrl(res.orderId, token);
    } catch (err: any) {
      console.log('checkout error', err.response.data);
      if (err.response.data) {
        setCheckoutError({
          type: err.response.data.code as CheckoutErrorType,
          title: '',
          message: checkoutErrorMapString[err.response.data.code as CheckoutErrorType] || '',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearError = () => {
    setCheckoutError({
      type: CheckoutErrorType.NONE,
      title: '',
      message: checkoutErrorMapString[CheckoutErrorType.NONE],
    });
  };

  return {
    checkoutError,
    handleCheckOut,
    isLoading,
    isOpenPaymentRedirect,
    handleClearError,
  };
}
