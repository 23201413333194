import { HStack, Text, Icon, Button, Center } from '@chakra-ui/react';
import ContentContainer from '~/container/Content';

export type FixFooterLoginSectionProps = {
  login: () => void;
};

// 購物車資訊
export default function FixFooterLoginSection({ login }: FixFooterLoginSectionProps) {
  return (
    <ContentContainer position="sticky" bottom={0} zIndex="1" transition="0.3s">
      <HStack
        justify="space-between"
        px={4}
        py={2}
        bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
        spacing={1}
      >
        <HStack>
          <Text color="white" fontSize="xs">
            請先登入才能將品項加入購物車
          </Text>
        </HStack>
        <Button
          onClick={login}
          variant="unstyled"
          color="white"
          bg="wsGray.700"
          w="9rem"
          h="2.5rem"
          pl=".1rem"
          borderRadius="5px"
        >
          登入
        </Button>
      </HStack>
    </ContentContainer>
  );
}
