import { Input, VStack ,Text} from '@chakra-ui/react';

type DisplayEmailProps = {
  displayText: string;
};
// 顯示不可編輯的 input
export default function DisplayEmail({ displayText }: DisplayEmailProps) {
  return (
    <VStack align="stretch">
      <Text fontSize="sm">收件電子郵件</Text>
      <Input
        disabled
        value={displayText}
        _disabled={{
          color: 'wsBlack',
        }}
      />
    </VStack>
  );
}
