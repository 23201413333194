import {
  VStack,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Center,
  Heading,
  Box,
  ModalFooter,
} from '@chakra-ui/react';

type OrderPaymentFailedModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

// 信用卡付款明細
export default function OrderPaymentFailedModal({ isOpen, onClose }: OrderPaymentFailedModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Center mt={4}>
            <VStack spacing={4} justify="space-between" my={3}>
              <Heading fontSize="xl">付款失敗</Heading>
            </VStack>
          </Center>
        </ModalBody>
        <ModalFooter borderTopWidth="1px" borderTopColor="admin.gray.300" justifyContent="center">
          <HStack align="stretch" w="full" spacing={0}>
            <Box as="button" flex="1" onClick={onClose}>
              <Text fontSize="sm">瞭解了</Text>
            </Box>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
