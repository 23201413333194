import React from 'react';
import { VStack, Box, FormLabel, InputGroup, Heading, Button } from '@chakra-ui/react';
import DisplayInput from './DisplayInput';

type NonItemNoticeProps = {
  onNavigate?: () => void;
};

// 目前無餐點通知
export default function NonItemNotice({ onNavigate }: NonItemNoticeProps) {
  return (
    <Box bg="wsGray.200" borderRadius="5px" p={3}>
      <VStack w="full" spacing={4}>
        <Heading size="sm">目前無餐點，快來點餐</Heading>
        <Button width="full" onClick={onNavigate}>
          去點餐
        </Button>
      </VStack>
    </Box>
  );
}
