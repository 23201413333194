import React from 'react';

import { Icon, IconProps } from '@chakra-ui/react';

export default function Point(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" fill="transparent" {...props}>
      <g clip-path="url(#clip0)">
        <path
          d="M12 13.5C15.3137 13.5 18 10.8137 18 7.5C18 4.18629 15.3137 1.5 12 1.5C8.68629 1.5 6 4.18629 6 7.5C6 10.8137 8.68629 13.5 12 13.5Z"
          stroke="#333333"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.73359 10.6787L2.25 18.7496L6.32156 18.73C6.45201 18.7293 6.58038 18.7627 6.69399 18.8268C6.8076 18.8909 6.90252 18.9836 6.96937 19.0956L9 22.4996L13.1405 13.3909"
          stroke="#333333"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.1816 10.501L21.75 18.751L17.6784 18.7313C17.548 18.7306 17.4196 18.764 17.306 18.8281C17.1924 18.8922 17.0975 18.9849 17.0306 19.0969L15 22.501L12 15.901"
          stroke="#333333"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 10.5C13.6569 10.5 15 9.15685 15 7.5C15 5.84315 13.6569 4.5 12 4.5C10.3431 4.5 9 5.84315 9 7.5C9 9.15685 10.3431 10.5 12 10.5Z"
          stroke="#333333"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
