import { VStack, Text, Image, HStack, Button, SimpleGrid, Link } from '@chakra-ui/react';

import image from '~/assets/images/404.png';
import { useNavigate } from 'react-router-dom';

const Error = () => {
  const navigate = useNavigate();

  const storeInfo = [
    {
      name: '新北中和',
      id: 'GMA1',
    },
    {
      name: '板橋車站',
      id: 'GMB1',
    },
    {
      name: '新左營車站',
      id: 'GMC1',
    },
    {
      name: '屏東市',
      id: 'GMA2',
    },
    {
      name: '南港車站',
      id: 'GMG1',
    },
    {
      name: '桃園A8',
      id: 'GMA4',
    },
    {
      name: '林口A9',
      id: 'GMA5',
    },
    {
      name: '桃園A19',
      id: 'GMA6',
    },
  ];

  const handleOnClick = (storeId: string) => {
    return navigate(`/${storeId}`);
  };

  const ColoredLine = () => (
    <hr
      style={{
        color: '#8d8d8d',
        backgroundColor: '#8d8d8d',
        height: '1px',
        width: '3rem',
      }}
    />
  );

  return (
    <VStack
      spacing={10}
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="full"
      py={20}
    >
      <VStack>
        <Image src={image} />
        <VStack>
          <Text fontSize="sm" color="wsGray.500">
            找不到網頁
          </Text>
          <Text fontSize="sm" color="wsGray.500">
            建議刷新
          </Text>
        </VStack>
      </VStack>

      <VStack spacing={6}>
        <HStack spacing={4}>
          <ColoredLine />
          <Text fontSize="sm" color="wsGray.500">
            點選您要前往的門市
          </Text>
          <ColoredLine />
        </HStack>

        <SimpleGrid columns={{ base: 2, md: 4 }} gap={4}>
          {storeInfo.map(({ name, id }) => (
            <Button as="a" w="8rem" h="3rem" href={`${window.location.origin}/${id}`} fontSize="lg">
              {name}
            </Button>
          ))}
        </SimpleGrid>
      </VStack>
    </VStack>
  );
};

export default Error;
