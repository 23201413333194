import React from 'react';

import { Text, Icon, HStack } from '@chakra-ui/react';
import { IoArrowBackSharp } from 'react-icons/io5';

type BackwardBarProps = {
  title: string;
};

// 退回上一頁，可以依照title props顯示不同標題
export default function BackwardBar({ title }: BackwardBarProps) {
  return (
    <HStack align="center" px={3} py={2} spacing={1} color="wsGray.500" bgColor="white">
      <Icon as={IoArrowBackSharp} fontSize="xl" />
      <Text fontSize="xs" color="wsGray.500" fontWeight="bold">
        {title}
      </Text>
    </HStack>
  );
}
