import { atom } from 'recoil';

export const selectedMenuAtom = atom<{
  selectedMenuId: string;
  selectedKitchenId: string;
}>({
  key: 'currentMenuAtom',
  default: {
    selectedMenuId: '',
    selectedKitchenId: '',
  },
});

export default selectedMenuAtom;
