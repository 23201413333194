import { VStack, HStack, Text, Center } from '@chakra-ui/react';

import PageContainer from '~/container/PageContainer';
import PageTitle from '~/components/PageTitle';

import { Container, Content } from './components';
import { useState } from 'react';

const Announcement = () => {
  const [isClick, setClick] = useState<boolean>(false);

  return (
    <PageContainer hideHeader>
      <PageTitle title="公告" />

      <VStack align="stretch" px={{ base: 4, md: 4 }} py={4} spacing={4}>
        <Container isOpen={false} title="防範疫情！外送服務注意">
          {isClick && <Content />}
        </Container>
        <Container isOpen={true} title="防範疫情！外送服務注意">
          {true && <Content />}
        </Container>
      </VStack>
    </PageContainer>
  );
};

export default Announcement;
