import React from 'react';
import { auth as firebaseAuth } from '~/firebase/index';
import { Center, Icon, HStack, Image, useDisclosure } from '@chakra-ui/react';
import { AiOutlineBell, AiOutlineMenu } from 'react-icons/ai';

import { useSetRecoilState, useRecoilValue } from 'recoil';
import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import authAtom from '~/recoil/atom/auth';
import storeAtom from '~/recoil/atom/store';
import isLoginModalOpenSelector from '~/recoil/selectors/isLoginModalOpen';

import ContentContainer from '~/container/Content';
import NavDrawer from './NavDrawer';
import NavBar from './NavBar';

import defaultLogo from '~/assets/images/logo.png';

export default function Header() {
  const { isLargerThan768 } = useRecoilValue(mediaQueryStateAtom);

  const auth = useRecoilValue(authAtom);
  const store = useRecoilValue(storeAtom);
  const setLoginModalOpen = useSetRecoilState(isLoginModalOpenSelector);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleLogout = () => {
    if (auth) {
      firebaseAuth.signOut();
    }
  };

  if (!store) {
    return <></>;
  }

  const { logo } = store;

  return (
    <>
      <ContentContainer>
        <HStack h="50px" justify="center" w="full" px={{ base: 4, md: 0 }} py={1}>
          <Icon
            as={AiOutlineMenu}
            boxSize="1.75rem"
            opacity={isLargerThan768 ? 0 : 1}
            onClick={onOpen}
            position="absolute"
            left={3}
          />
          <Image src={logo || defaultLogo} h="full" />
        </HStack>

        {isLargerThan768 ? (
          <NavBar
            isLogin={!!auth}
            onLoginModalOpen={() => {
              setLoginModalOpen(true);
            }}
            onLogout={handleLogout}
          />
        ) : null}
      </ContentContainer>

      <NavDrawer
        isOpen={isOpen}
        onClose={onClose}
        isLogin={!!auth}
        onLoginModalOpen={() => {
          setLoginModalOpen(true);
        }}
        onLogout={handleLogout}
      />
    </>
  );
}
