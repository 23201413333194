import { HStack, Text, Box, Button, Center } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import ContentContainer from '~/container/Content';

import { IoCartSharp } from 'react-icons/io5';

import formatPriceNumber from '~/utils/formatPriceNumber';
import { useSetRecoilState } from 'recoil';
import isLoginModalOpenSelector from '~/recoil/selectors/isLoginModalOpen';

export type LoginBottomBarProps = {
  login: () => void;
};

// 購物車資訊
export default function LoginBottomBar({ login }: LoginBottomBarProps) {
  return (
    <Box
      position="sticky"
      zIndex="1000"
      bottom="0"
      w="calc(100% + 2rem)"
      transition=".3s all"
      transform="translateX(-1rem)"
    >
      <HStack
        justify="space-between"
        px={4}
        py={2}
        bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
        spacing={1}
      >
        <HStack>
          <Text color="white" fontSize="xs">
            請先登入才能將品項加入購物車
          </Text>
        </HStack>
        <Button onClick={login} variant="unstyled" color="white" bg="wsGray.700">
          <Center w="10rem" h="2.5rem" pl=".1rem" borderRadius="5px" bg="wsGray.700">
            <Text textAlign="center" fontSize="lg" fontWeight="bold" color="inherit">
              登入
            </Text>
          </Center>
        </Button>
      </HStack>
    </Box>
  );
}
