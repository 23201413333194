import ModifierOptionsSingleSelector from './ModifierOptionsSingleSelector';
import ModifierOptionsMultiSelector from './ModifierOptionsMultiSelector';
import { List, Box } from '@chakra-ui/react';
import { ScrollPositionInfo } from '~/hooks/useScroll';

import { ModifierWithId, OrderModifier } from '~/types';

type ItemModifierProps = {
  itemModifiers: Array<ModifierWithId>;
  selectorModifiers: Map<string, OrderModifier>;
  modifierErrors: Set<string>;
  showError: boolean;

  positionInfo: ScrollPositionInfo;
  onChangeModifier: (modifiers: Map<string, OrderModifier>) => void;
};

// 顯示餐點選項的多個編輯區塊，分成多選選項區塊跟單選選項區塊
export default function ItemModifier({
  modifierErrors,
  selectorModifiers,
  itemModifiers,
  showError,

  positionInfo,
  onChangeModifier,
}: ItemModifierProps) {
  return (
    <Box>
      <List spacing={4}>
        {itemModifiers.map((modifier) => {
          const { id, max, min } = modifier;
          if (max === 1 && min <= 1) {
            return (
              <ModifierOptionsSingleSelector
                key={id}
                modifier={modifier}
                selectedModifiers={selectorModifiers}
                modifierErrors={modifierErrors}
                isErrorShow={showError}
                positionInfo={positionInfo}
                onChangeModifier={onChangeModifier}
              />
            );
          }
          return (
            <ModifierOptionsMultiSelector
              key={id}
              modifier={modifier}
              selectedModifiers={selectorModifiers}
              modifierErrors={modifierErrors}
              isErrorShow={showError}
              positionInfo={positionInfo}
              onChangeModifier={onChangeModifier}
            />
          );
        })}
      </List>
    </Box>
  );
}
