import React from 'react';
import { Box, Flex, VStack, Text, Divider, Center } from '@chakra-ui/react';

type PointItemProps = {
  allowExchange?: boolean;
};

// 顯示單一集點票卡，票卡資訊包括優惠券名稱、期限、抵折條件，及需要兌換的點數，達到兌換條件的可直接點擊兌換
export default function PointItem({ allowExchange }: PointItemProps) {
  const gapPseudoStyle = {
    content: '""',
    display: 'block',
    position: ' absolute',
    zIndex: 100,
    left: '-.5rem',
    w: '1rem',
    h: '1.1rem',
    borderRadius: '50%',
    border: '1px solid #c4c4c4',
    bgColor: 'white',
  };
  return (
    <Box w="full" overflow="hidden">
      <Box borderWidth="1px" borderColor="wsGray.400" borderRadius="5px">
        <Flex borderRightRadius="5px">
          <Box flex="3" px={2} py={1}>
            <VStack align="stretch" spacing={2.5}>
              <Text as="h5" fontSize="sm" fontWeight="normal" isTruncated>
                優惠卷名稱最長可以到這邊這邊這邊這
              </Text>
              <VStack alignSelf="stretch" align="flex-start" spacing={2.5} letterSpacing={1}>
                <Text fontSize="xs" color="wsGray.500">
                  使用期限:2021/9/10到期
                </Text>
                <Divider color="wsGray.400" alignSelf="stretch" />
              </VStack>
            </VStack>
            <Text mt={0.5} fontSize="xs" fontWeight="bold" color="wsRed.600">
              滿NT$100 折抵NT$20ffff
            </Text>
          </Box>
          <Box flex="1" borderRightRadius="5px">
            <Flex
              position="relative"
              h="full"
              borderRightRadius="5px"
              cursor="pointer"
              _before={{
                ...gapPseudoStyle,
                top: '-.5rem',
              }}
              _after={{
                ...gapPseudoStyle,
                bottom: '-.5rem',
              }}
            >
              <Box
                w="1px"
                h="full"
                bgColor="black"
                background="linear-gradient(to bottom, #c4c4c4 50%, transparent 50%)"
                backgroundSize="100%  .75rem"
              />
              <Center
                flex="1"
                h="full"
                bgColor={allowExchange ? 'wsBlack' : 'wsGray.400'}
                borderRightRadius="5px"
              >
                <VStack align="center" spacing={1} color="white">
                  <Text fontSize="xs" fontWeight="bold" color="inherit">
                    20點
                  </Text>
                  <Text fontSize="xs" color="inherit">
                    兌換
                  </Text>
                </VStack>
              </Center>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
