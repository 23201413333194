import { HStack, Text } from '@chakra-ui/react';

const weeks = ['日', '一', '二', '三', '四', '五', '六'];

export default function WeekBar() {
  return (
    <HStack spacing={1}>
      {weeks.map((day) => {
        return (
          <Text key={day} flex="1" textAlign="center">
            {day}
          </Text>
        );
      })}
    </HStack>
  );
}
