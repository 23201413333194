import { useState } from 'react';
import { VStack, Box, Image, Stack } from '@chakra-ui/react';
import {
  AddToCartBottomBar,
  FixedBackwardBar,
  ItemModifier,
  ItemInfo,
  NoteInput,
} from './components';
import PageContainer from '~/container/PageContainer';
import useScroll from '~/hooks/useScroll';

import { useParams, useNavigate, Navigate } from 'react-router-dom';

import { itemSelector } from '~/recoil/selectors/items';
import cartItemSelector from '~/recoil/selectors/cartItem';
import { useRecoilValue } from 'recoil';

import useAllowOperateCart from '~/pages/Item/hooks/useAllowOperateCart';
import useModifiersControl from '~/pages/Item/hooks/useModifiersControl';
import useKitchenCartItems from '~/hooks/useKitchenCartItems';
import { CartItem, OrderModifier } from '~/types';

export default function ItemEdit() {
  const { storeId, kitchenId, itemId, index } = useParams();
  const navigate = useNavigate();

  const { positionInfo } = useScroll();

  const [showError, setShowError] = useState<boolean>(false);

  const item = useRecoilValue(itemSelector(itemId));
  const cartItem = useRecoilValue(cartItemSelector({ kitchenId, itemId, index: parseInt(index) }));

  // check operating time
  const allowAddOperateCart = useAllowOperateCart(itemId);

  const { handleEditItems } = useKitchenCartItems();

  // control item info hook
  const {
    selectedModifiers,
    modifierErrors,
    optionsTotalPrice,
    count,
    note,
    handleChangeCount,
    handleChangeModifier,
    handleChangeNote,
  } = useModifiersControl(item?.modifiers || null, {
    defaultModifiers: cartItem?.modifiers,
    defaultCount: cartItem?.count,
    defaultNote: cartItem?.note,
  });

  if (!item || !cartItem) {
    return <Navigate to={`/${storeId}`} />;
  }

  const handleClickEditItem = () => {
    if (modifierErrors.size > 0) {
      setShowError(true);
      return;
    }

    const modifiers: Array<OrderModifier> = item.modifiers.reduce((acc, { id }) => {
      const modifier = selectedModifiers.get(id);
      if (modifier) acc.push(modifier);
      return acc;
    }, [] as Array<OrderModifier>);

    const editedCartItem: CartItem = {
      id: itemId,
      count,
      name: item.name,
      note,
      modifiers,
      tax: item.tax,
      price: item.price,
    };

    handleEditItems(kitchenId, editedCartItem, parseInt(index));

    navigate(`/${storeId}/cart`);
  };

  return (
    <PageContainer hideHeader hideFooter>
      <FixedBackwardBar title="返回購物車" onNavigate={() => navigate(`/${storeId}/cart`)} />
      <Stack
        direction={{ base: 'column', md: 'row' }}
        align="stretch"
        spacing={{ base: 0, md: 10 }}
      >
        <Box w="full" flex="1">
          <Image
            src={item.image}
            w="full"
            pos={{ base: 'static', md: 'sticky' }}
            top={20}
            zIndex="100"
          />
        </Box>
        <VStack
          align="stretch"
          flex="1"
          px={{ base: 4, md: 4 }}
          pt={{ base: 3, md: 0 }}
          pb={{ base: 0, md: 3 }}
          spacing={5}
        >
          <ItemInfo
            title={item.name}
            description={item.description}
            price={item.price}
            inventory={item.inventory.currentInventory}
          />
          <ItemModifier
            itemModifiers={item.modifiers}
            selectorModifiers={selectedModifiers}
            modifierErrors={modifierErrors}
            showError={showError}
            positionInfo={positionInfo}
            onChangeModifier={handleChangeModifier}
          />
          <NoteInput
            value={note}
            onChange={(e) => handleChangeNote(e.target.value)}
            textLimit={100}
          />
          <AddToCartBottomBar
            isDisabled={!allowAddOperateCart}
            amount={count}
            currentInventory={item.inventory.currentInventory}
            unitPrice={optionsTotalPrice + item.price}
            onChangeCount={handleChangeCount}
            onAddItemClick={handleClickEditItem}
          />
        </VStack>
      </Stack>
    </PageContainer>
  );
}
