import firebase from 'firebase';
import { firestore } from './index';

import { FirebaseCart, CartType, OrderType, CartStatus, PaymentType, TaxType } from '~/types';

export async function getFirebaseCart(
  storeId: string,
  auth: firebase.User,
  cartUpdater: (firebaseCart: FirebaseCart) => void,
) {
  const cartSnap = await firestore.doc(`stores/${storeId}/carts/${auth.uid}`).get();
  if (cartSnap.exists) {
    const data = cartSnap.data();
    cartUpdater(data as FirebaseCart);
  } else {
    const initCart: FirebaseCart = {
      uid: auth.uid,
      cartType: CartType.PERSONAL,
      orderType: OrderType.WS_PICKUP,
      preOrderDate: null,
      status: CartStatus.ACTIVE,
      paymentType: PaymentType.CREDIT,
      taxType: TaxType.PERSONAL,
      taxInfo: {
        invoice: '',
        title: '',
      },
      orders: [],
      email: '',
    };
    await cartSnap.ref.set(initCart);

    cartUpdater(initCart);
  }
}
