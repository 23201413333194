import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogFooter,
  Button,
  Heading,
  Text,
  VStack,
  HStack,
  StackDivider,
} from '@chakra-ui/react';

type CancelAlertDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

// 取消訂單彈出視窗 （純顯示）
export default function CancelAlertDialog({ isOpen, onClose, onConfirm }: CancelAlertDialogProps) {
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogBody my={6}>
          <VStack align="center">
            <Heading fontSize="lg">取消訂單</Heading>

            <Text fontSize="sm">店家還未接單，線上付款將自動退款</Text>
            <Text fontSize="sm">確定取消訂單？</Text>
          </VStack>
        </AlertDialogBody>

        <AlertDialogFooter p={0} borderColor="wsGray.300" borderTopWidth="1px">
          <HStack w="full" spacing={0} divider={<StackDivider />}>
            <Button
              variant="unstyled"
              fontWeight="normal"
              flex="1"
              ref={cancelRef}
              onClick={onClose}
            >
              我想一下
            </Button>
            <Button variant="unstyled" fontWeight="normal" flex="1" onClick={onConfirm}>
              確定
            </Button>
          </HStack>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
