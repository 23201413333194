import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import authAtom from '~/recoil/atom/auth';

import uidAtom from '~/recoil/atom/uid';
import locationAtom from '~/recoil/atom/location';
import emailAtom from '~/recoil/atom/email';
import mealPickupTypeAtom from '~/recoil/atom/mealPickupType';
import pickMealTimeAtom from '~/recoil/atom/pickMealTime';
import paymentTypeAtom from '~/recoil/atom/paymentType';
import preOrderDateAtom from '~/recoil/atom/preOrderDate';
import taxTypeAtom from '~/recoil/atom/taxType';
import taxInfoAtom from '~/recoil/atom/taxInfo';
import kitchenCartItemsAtom from '~/recoil/atom/kitchenCartItems';
import cartStatusAtom from '~/recoil/atom/cartStatus';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';

import convertOrderType from '~/utils/convertOrderType';

import { cartListener } from '~/firebase/listener';
import { getFirebaseCart } from '~/firebase/getData';
import { updateCartData } from '~/firebase/updateData';

import { FirebaseCart } from '~/types';

export default function useCart() {
  const { storeId } = useParams();
  const auth = useRecoilValue(authAtom);

  const setUid = useSetRecoilState(uidAtom);
  const setMealPickupType = useSetRecoilState(mealPickupTypeAtom);
  const setPickMealTime = useSetRecoilState(pickMealTimeAtom);
  const setPaymentType = useSetRecoilState(paymentTypeAtom);
  const setPreOrderDate = useSetRecoilState(preOrderDateAtom);

  const setTaxType = useSetRecoilState(taxTypeAtom);
  const setTaxInfo = useSetRecoilState(taxInfoAtom);

  const setKitchenCartItems = useSetRecoilState(kitchenCartItemsAtom);
  const setCartStatus = useSetRecoilState(cartStatusAtom);
  const setEmail = useSetRecoilState(emailAtom);

  const handleUpdateCart = (firebaseCart: FirebaseCart) => {
    const [updateMealPickupType, updatePickMealTime] = convertOrderType(firebaseCart.orderType);

    setUid(firebaseCart.uid);
    setCartStatus(firebaseCart.status);
    setMealPickupType(updateMealPickupType);
    setPickMealTime(updatePickMealTime);
    setPreOrderDate(firebaseCart.preOrderDate?.toDate() || null);
    setTaxInfo(firebaseCart.taxInfo);
    setTaxType(firebaseCart.taxType);
    setPaymentType(firebaseCart.paymentType);
    setKitchenCartItems(firebaseCart.orders);
    setEmail(firebaseCart.email);
  };

  useEffect(() => {
    let cartUnlistener: (() => void) | null = null;

    const handleUpdateCartListener = async () => {
      if (!auth || !storeId) {
        setKitchenCartItems([]);
        return;
      }
      await getFirebaseCart(storeId, auth, () => {});
      cartUnlistener = cartListener(storeId, auth, handleUpdateCart);
    };
    handleUpdateCartListener();

    return () => {
      cartUnlistener?.();
    };
  }, [auth, storeId]);
}
