import { VStack, HStack, Text } from '@chakra-ui/react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineShop, AiOutlineClockCircle } from 'react-icons/ai';

import PageContainer from '~/container/PageContainer';
import ImageSlick from '~/components/ImageSlick';

import PageTitle from '~/components/PageTitle';
import { Detail, SectionContainer, OperatingTime } from './components';
import { useRecoilValue } from 'recoil';
import storeAtom from '~/recoil/atom/store';

const StoreInfo = () => {
  const store = useRecoilValue(storeAtom);
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();

  if (!store) {
    return <Navigate to="404" />;
  }

  const { name, phoneNumber, location, operatingTime, description, images } = store;

  return (
    <PageContainer hideHeader>
      <PageTitle
        title="店家資訊"
        onNavigate={() => navigate(groupId ? `/${storeId}/group/${groupId}` : `/${storeId}`)}
      />
      <ImageSlick images={images} />

      <VStack align="stretch" px={{ base: 4, md: 4 }} py={3} spacing={7}>
        <SectionContainer icon={AiOutlineShop} title={name}>
          <Detail phoneNumber={phoneNumber} location={location.address} boxSize="1.125rem" />
        </SectionContainer>

        <SectionContainer icon={AiOutlineClockCircle} title="接受訂餐時間">
          <Text fontSize="sm" color="wsRed.600">
            營業時間至22:00
          </Text>
          <OperatingTime operatingTime={operatingTime} />
        </SectionContainer>

        <SectionContainer icon={AiOutlineShop} title="店家描述">
          <HStack>
            <Text fontSize="sm">{description}</Text>
          </HStack>
        </SectionContainer>
      </VStack>
    </PageContainer>
  );
};

export default StoreInfo;
