import { isSameDay, startOfDay, differenceInMinutes, addMinutes, format } from 'date-fns';

import { MealPickUpType } from '~/types';

export default function getPreOrderDateString(preOrderDate: Date, mealPickupType: MealPickUpType) {
  const nowDate = new Date();
  const weeks = ['日', '一', '二', '三', '四', '五', '六'];
  const timeStringFn = (time: number) => {
    const hour = Math.floor(time / 60);
    const minute = time % 60;
    const timeString = `${hour < 10 ? '0' + hour.toString() : hour.toString()}:${
      minute < 10 ? '0' + minute.toString() : minute.toString()
    }`;
    return timeString;
  };

  if (mealPickupType === MealPickUpType.DELIVERY) {
    if (isSameDay(preOrderDate, nowDate)) {
      const startOfToday = startOfDay(nowDate);
      const startToPreOrderMinutes = differenceInMinutes(preOrderDate, startOfToday);
      return `${timeStringFn(startToPreOrderMinutes)}`;
    }

    return `${format(preOrderDate, 'MM/dd')} (${weeks[preOrderDate.getDay()]})`;
  }

  if (mealPickupType === MealPickUpType.PICKUP) {
    return `${format(preOrderDate, 'MM/dd')} (${weeks[preOrderDate.getDay()]}) ${format(
      preOrderDate,
      'HH:mm',
    )}~${format(addMinutes(preOrderDate, 15), 'HH:mm')}`;
  }
}
