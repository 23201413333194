import React from 'react';
import { Avatar, HStack, VStack, Box, Text, Center } from '@chakra-ui/react';
import PhoneIcon from '~/icons/Phone';
type UserInfoProps = {};

// 顯示會員資訊區塊，已登入會顯示頭貼、姓名、編輯個人資訊連結，為登入則顯示手機註冊
export default function UserInfo(props: UserInfoProps) {
  const isLogin = true;
  return (
    <Box>
      {isLogin ? (
        <HStack spacing={3}>
          <Avatar name="Dan Abrahmov" src="https://bit.ly/dan-abramov" />
          <VStack align="flex-start" spacing={0}>
            <Text>王小明</Text>
            <Text fontSize="xs" color="wsGray.500">
              編輯個人資訊
            </Text>
          </VStack>
        </HStack>
      ) : (
        <Center
          position="relative"
          px={4}
          py={2}
          color="white"
          bgColor="wsBlack"
          borderRadius="5px"
          overflow="hidden"
        >
          <PhoneIcon
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-9rem,-50%)"
            boxSize="3.3rem"
            color="wsGray.300"
          />
          <VStack spacing={0}>
            <Text fontWeight="bold" color="white">
              手機註冊
            </Text>
            <Text fontSize="xs" color="white">
              手機驗證立刻成為會員享優惠
            </Text>
          </VStack>
        </Center>
      )}
    </Box>
  );
}
