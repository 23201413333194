import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useToast } from '@chakra-ui/react';

import { isEqual } from 'date-fns';

import useModalControl from '~/hooks/useModalControl';
import useOrderSetting from '~/hooks/useOrderSetting';
import kitchenCartItemsAtom from '~/recoil/atom/kitchenCartItems';

import { PickMealTime, PreOrderDate } from '~/types';

export default function useConfirmChangeOrderTime({
  title,
  message,
  updatePickMealTime,
  updatePreOrderDate,
  handlePickupSettingSubmit,
}: {
  title: string;
  message: string;
  updatePickMealTime: PickMealTime;
  updatePreOrderDate: PreOrderDate;
  handlePickupSettingSubmit: (isShouldClearCart: boolean) => void;
}) {
  const toast = useToast();
  const kitchenCartItems = useRecoilValue(kitchenCartItemsAtom);
  const { modalControl, changeModalOpen } = useModalControl();
  const {
    mealOrderSetting: { preOrderDate, pickMealTime },
  } = useOrderSetting();

  const handleSafePickupSettingSubmit = () => {
    if (kitchenCartItems.length > 0) {
      const isSamePickMealTime = updatePickMealTime === pickMealTime;
      if (!isSamePickMealTime) {
        changeModalOpen('isConfirmOpen', true);
        return;
      }

      if (updatePickMealTime === PickMealTime.RESERVATION) {
        if (!preOrderDate || !updatePreOrderDate) {
          toast({ title: '預約取餐時間未選取', status: 'error', position: 'top' });
          return;
        }

        const isSameDate = isEqual(preOrderDate, updatePreOrderDate);
        if (!isSameDate) {
          changeModalOpen('isConfirmOpen', true);
          return;
        }
      }
    }

    handlePickupSettingSubmit(false);
  };

  const handleConfirm = () => {
    handlePickupSettingSubmit(true);
    changeModalOpen('isConfirmOpen', false);
  };

  const handleCancel = () => {
    changeModalOpen('isConfirmOpen', false);
  };

  return {
    isConfirmModalOpen: modalControl.isConfirmOpen,
    title,
    message,
    handleSafePickupSettingSubmit,
    handleConfirm,
    handleCancel,
  };
}
