import { atom } from 'recoil';

export type ModalControlType = {
  isLoginOpen: boolean;
  isPickupSettingOpen: boolean;
  isEditAddressOpen: boolean;
  isConfirmOpen: boolean;
};

const modalControl = atom<ModalControlType>({
  key: 'modalControl',
  default: {
    isLoginOpen: false,
    isPickupSettingOpen: false,
    isEditAddressOpen: false,
    isConfirmOpen: false,
  },
});

export default modalControl;
