import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export enum InputVariants {
  OUTLINE = 'outline',
}

const Input: ComponentSingleStyleConfig = {
  variants: {
    [InputVariants.OUTLINE]: {
      field: { borderColor: 'wsGray.400' },
    },
  },
  defaultProps: {
    focusBorderColor: 'wsBlack',
  },
};

export default Input;
