import { HStack, Text, Icon } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { AiFillEnvironment } from 'react-icons/ai';
import { FiPhone } from 'react-icons/fi';

type DetailProps = {
  phoneNumber: string;
  location: string;
  boxSize: string;
};

// 店家詳細資訊
export default function Detail({ phoneNumber, location, boxSize }: DetailProps) {
  return (
    <>
      <HStack>
        <Icon as={FiPhone} boxSize={boxSize} />
        <Text fontSize="sm"> {phoneNumber}</Text>
      </HStack>
      <HStack>
        <Icon as={AiFillEnvironment} boxSize={boxSize} />
        <Text fontSize="sm"> {location}</Text>
      </HStack>
    </>
  );
}
