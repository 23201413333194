import { Menu, KitchenItems } from '~/types';
import checkOperatingTime from './checkOperatingTime';

export default function checkAllowOrderKitchen(
  menus: Record<string, Menu>,
  orders: Array<KitchenItems>,
  date: Date,
): {
  invalidKitchenItems: Array<KitchenItems>;
  validKitchenItems: Array<KitchenItems>;
} {
  const menusArray = Object.values(menus);

  return orders.reduce(
    (acc, kitchenItems) => {
      const { kitchenId } = kitchenItems;
      const filterMenus = menusArray.filter((menu) => menu.kitchenId === kitchenId);

      if (
        filterMenus.some(
          ({ schedule, allowPickup, allowPublish }) =>
            allowPublish && allowPickup && checkOperatingTime(schedule, date),
        )
      ) {
        acc.validKitchenItems.push(kitchenItems);
      } else {
        acc.invalidKitchenItems.push(kitchenItems);
      }

      return acc;
    },
    { invalidKitchenItems: [], validKitchenItems: [] } as {
      invalidKitchenItems: Array<KitchenItems>;
      validKitchenItems: Array<KitchenItems>;
    },
  );
}
