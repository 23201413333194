import { HStack, Text, Icon, Button, Center } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import ContentContainer from '~/container/Content';

import { IoCartSharp } from 'react-icons/io5';

import formatPriceNumber from '~/utils/formatPriceNumber';

type FixFooterSectionProps = {
  subtotal: number;
  itemAmount: number;
};

// 購物車資訊
export default function FixFooterSection({ subtotal, itemAmount }: FixFooterSectionProps) {
  const { storeId, groupId } = useParams();
  const navigate = useNavigate();

  const handleNavigateCart = () => {
    if (groupId && storeId) {
      navigate(`/${storeId}/group/${groupId}/cart`);
      return;
    }

    if (storeId) {
      navigate(`/${storeId}/cart`);
    }
  };

  return (
    <ContentContainer position="sticky" bottom={0} zIndex="1" transition="0.3s">
      <HStack
        onClick={handleNavigateCart}
        justify="space-between"
        px={4}
        py={2}
        bgGradient="linear-gradient(90.77deg, #595757 7.25%, #000000 93.29%)"
      >
        <HStack>
          <Center borderRadius="5px" bg="wsGray.700" w="2rem" h="2rem">
            <Text color="white" fontSize="md" fontWeight="bold">
              {itemAmount}
            </Text>
          </Center>
          <Text color="white" fontSize="md" fontWeight="bold">
            {formatPriceNumber(subtotal)}
          </Text>
        </HStack>
        <Button variant="unstyled" color="white">
          <HStack spacing={1}>
            <Text color="white" fontSize="md" fontWeight="bold">
              結帳
            </Text>
            <Icon as={IoCartSharp} boxSize="1.5rem" color="white" />
          </HStack>
        </Button>
      </HStack>
    </ContentContainer>
  );
}
