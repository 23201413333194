import { PickMealTime } from '~/types';
import { format, addMinutes } from 'date-fns';

export default function generatePrepareString(
  pickMealTime: PickMealTime,
  prepareMinutes: number,
  preOrderDate: Date | null,
): string {
  if (pickMealTime === PickMealTime.IMMEDIATELY) {
    return `儘快（${prepareMinutes}~${prepareMinutes + 15}分鐘）`;
  }

  if (preOrderDate && pickMealTime === PickMealTime.RESERVATION) {
    return `${format(preOrderDate, 'MM/dd hh:mm aa')}~${format(
      addMinutes(preOrderDate, prepareMinutes),
      'hh:mm aa',
    )}`;
  }

  return '未選擇取餐時間';
}
