import { selector } from 'recoil';

import { menusSelector, menuSelector } from '~/recoil/selectors/menus';
import filteredMenusIdsSelector from './filteredMenusIds';

const menuNavListSelector = selector({
  key: 'menuNavListSelector',
  get: ({ get }) => {
    const filteredMenusIds = get(filteredMenusIdsSelector);
    const menus = get(menusSelector);
    if (filteredMenusIds.length === 0 || !menus) return [];
    return filteredMenusIds.map(({ menuId, kitchenId }) => {
      const menu = menus[menuId];
      return {
        label: menu.name,
        value: menuId,
        kitchenId: kitchenId,
      };
    });
  },
});

export default menuNavListSelector;
