import { HStack, Heading, Icon } from '@chakra-ui/react';
import { IconType } from 'react-icons';

import { IoChevronUp, IoChevronDown } from 'react-icons/io5';
import {AiOutlineSound }from 'react-icons/ai';

export type TitleSectionProps = {
  isOpen: boolean;
  title: string;
};

// 公告標題：公告的主要標題
export default function TitleSection({ isOpen, title }: TitleSectionProps) {
  return (
    <HStack justify="space-between" align="stretch" >
      <HStack>
        <Icon as={AiOutlineSound} boxSize="1.25rem" />
        <Heading fontSize="sm" letterSpacing={2}>
          {title}
        </Heading>
      </HStack>
      <Icon as={isOpen ? IoChevronUp : IoChevronDown} boxSize="1.25rem" />
    </HStack>
  );
}
