import { HStack, Heading, Icon, Circle, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';

export type SectionLabelProps = {
  icon: IconType;
  title: string;
};

export default function SectionLabel({ icon, title }: SectionLabelProps) {
  return (
    <HStack spacing={1}>
      <Icon as={icon} boxSize="1.25rem" />
      <Heading fontSize="sm" letterSpacing={2}>
        {title}
      </Heading>
    </HStack>
  );
}
