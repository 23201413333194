import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Text, Box, HStack } from '@chakra-ui/react';
import { isSameDay } from 'date-fns';

import preOrderDateAtom from '~/recoil/atom/preOrderDate';

type PickupDaySelectProps = {
  dayList:
    | {
        date: Date;
        weekDay: string;
        day: string;
        month: string;
      }[]
    | undefined;
  selectedDay: Date | undefined;
  onDayClick: (day: Date) => void;
};

// 選擇特定日期 一星期
export default function PickupDaySelect({
  dayList,
  selectedDay,
  onDayClick,
}: PickupDaySelectProps) {
  const preOrderDate = useRecoilValue(preOrderDateAtom);
  useEffect(() => {
    if (preOrderDate) return;
    if (selectedDay) return;
    if (!dayList) return;
    const initialDay = dayList[0]?.date;
    onDayClick(initialDay);
  }, [dayList, selectedDay, preOrderDate]);

  return (
    <HStack align="flex-start" spacing={2} py={2}>
      {dayList?.map(({ date, weekDay, day, month }) => {
        const isSelected = selectedDay && isSameDay(date, selectedDay);
        return (
          <Box
            onClick={() => onDayClick(date)}
            key={date.toString()}
            as="button"
            borderRadius="5px"
            bg={isSelected ? 'wsBlack' : 'white'}
            p={2}
            flex="1"
            color={isSelected ? 'white' : 'wsBlack'}
            border="1px solid"
            borderColor={isSelected ? 'wsBlack' : 'wsGray.400'}
          >
            <Text color="inherit" fontSize="xs">
              {weekDay}
            </Text>
            <Text color="inherit" fontSize="lg" fontWeight="bold">
              {day}
            </Text>
            <Text color="inherit" fontSize="xs">
              {month}
            </Text>
          </Box>
        );
      })}
    </HStack>
  );
}
