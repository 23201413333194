import { Box } from '@chakra-ui/react';
import useAuth from '~/hooks/useAuth';

type AuthProviderProps = {
  children: React.ReactElement;
};

export default function AuthProvider({ children }: AuthProviderProps) {
  useAuth();

  return (
    <>
      <Box id="recaptcha-container" display="none" />
      {children}
    </>
  );
}
