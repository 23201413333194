import firebase from 'firebase';
import { useEffect } from 'react';
import { authListener } from '~/firebase/listener';
import { useSetRecoilState } from 'recoil';
import authAtom from '~/recoil/atom/auth';
import isGotInitDataAtom from '~/recoil/atom/isGotInitData';
export default function useAuth() {
  const setAuth = useSetRecoilState(authAtom);
  const setIsGotInitData = useSetRecoilState(isGotInitDataAtom);

  const handleUpdate = (auth: firebase.User | null) => {
    setIsGotInitData((prev) => ({ ...prev, auth: true }));
    setAuth(auth);
  };

  useEffect(() => {
    const authUnlisten = authListener(handleUpdate);

    return () => {
      authUnlisten?.();
    };
  }, []);
}
