import cartSelector from '~/recoil/selectors/cart';
import { useRecoilValue } from 'recoil';

import sumBy from 'lodash/sumBy';
import calcSettlement from '~/utils/calcSettlement';

import calcCartItemsPrice from '~/utils/calcCartItemsPrice';

export default function useCartSettlement() {
  const cart = useRecoilValue(cartSelector);
  const { orderType, paymentType, orders } = cart;

  const kitchenCartItemTotal = sumBy(orders, ({ items }) => calcCartItemsPrice(items));

  const { subtotal, shippingFee, freightSubsidy, total } = calcSettlement(
    orderType,
    paymentType,
    kitchenCartItemTotal,
    0,
    {
      shippingFee: 0,
      handlingFee: 0,
      costShare: 0,
      freeLimit: 0,
      costShareLimit: 0,
    },
  );
  return {
    subtotal: subtotal,
    discount: 0,
    shippingFee,
    freightSubsidy,
    total,
  };
}
