import { useState, useEffect } from 'react';

export type EmailState = {
  email: string;
  errors: Partial<{
    email: string;
  }>;
};

function checkEmailError(value: string): Partial<{ email: string }> {
  let errors: Partial<{ email: string }> = {};

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!emailRegex.test(value)) {
    errors.email = '請輸入正確的電子郵件';
  }

  return errors;
}

export default function useEmail(defaultEmail: string = '') {
  const [emailState, setEmailState] = useState<EmailState>({
    email: defaultEmail,
    errors: checkEmailError(defaultEmail),
  });

  const handleUpdateEmail = (value: string) => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRegex.test(value)) {
      setEmailState({
        email: value,
        errors: {},
      });
      return;
    }

    setEmailState({
      email: value,
      errors: {
        email: '請輸入正確的電子郵件',
      },
    });
  };

  useEffect(() => {
    handleUpdateEmail(defaultEmail);
  }, [defaultEmail]);

  return {
    emailState,
    handleUpdateEmail,
  };
}
