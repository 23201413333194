import React from 'react';
import { Box, Flex, VStack, Text, Divider, Link, Icon, Center, Circle } from '@chakra-ui/react';
import { FiArrowRight } from 'react-icons/fi';
import { FiCheck } from 'react-icons/fi';
import { IoArrowForwardCircle } from 'react-icons/io5';
import { AiFillCheckCircle } from 'react-icons/ai';

type CouponItemProps = {};

export default function CouponItem(props: CouponItemProps) {
  const gapPseudoStyle = {
    content: '""',
    display: 'block',
    position: ' absolute',
    zIndex: 100,
    left: '-.5rem',
    w: '1rem',
    h: '1.1rem',
    borderRadius: '50%',
    border: '1px solid #c4c4c4',
    bgColor: 'white',
  };

  return (
    <Box w="full" overflow="hidden">
      <Flex>
        <Box
          flex="3"
          px={2}
          py={1}
          borderWidth="1px"
          borderColor="wsGray.400"
          borderRightWidth="0"
          borderLeftRadius="5px"
        >
          <VStack align="stretch" spacing={0}>
            <Text as="h5" fontSize="sm" fontWeight="normal" isTruncated>
              優惠卷名稱最長可以到這邊這邊這邊這
            </Text>
            <VStack alignSelf="stretch" align="flex-start" spacing={1} letterSpacing={1}>
              <Text fontSize="xs" color="wsGray.500">
                使用期限:2021/9/10到期
              </Text>
              <Link fontSize="xs" color="wsGray.500" bgColor="wsGray.300" p={1} borderRadius="5px">
                優惠卷詳情
              </Link>
              <Divider color="wsGray.400" alignSelf="stretch" />
            </VStack>
          </VStack>
          <Text mt={0.5} fontSize="xs" fontWeight="bold" color="wsRed.600">
            滿NT$100 折抵NT$20
          </Text>
        </Box>
        <Box
          flex="1"
          borderWidth="1px"
          borderColor="wsGray.400"
          borderLeftWidth="0"
          borderRightRadius="5px"
          cursor="pointer"
          role="group"
        >
          <Flex
            position="relative"
            h="full"
            borderRightRadius="5px"
            _before={{
              ...gapPseudoStyle,
              top: '-.5rem',
            }}
            _after={{
              ...gapPseudoStyle,
              bottom: '-.5rem',
            }}
          >
            <Box
              w="1px"
              h="full"
              background="linear-gradient(to bottom, #c4c4c4 50%, transparent 50%)"
              backgroundSize="100%  .75rem"
            />
            <Center
              flex="1"
              h="full"
              bgColor="wsGray.300"
              borderRightRadius="5px"
              _groupHover={{
                bgColor: 'wsBlack',
              }}
            >
              <VStack align="center" color="wsGray.400">
                <Icon
                  as={IoArrowForwardCircle}
                  boxSize="1.6rem"
                  _groupHover={{
                    color: 'white',
                  }}
                />
                {/* check style */}
                {/* <Icon as={AiFillCheckCircle} color="wsGray.400" boxSize="1.6rem" /> */}
                <Text
                  fontSize="xs"
                  color="inherit"
                  _groupHover={{
                    color: 'white',
                  }}
                >
                  點餐去
                </Text>
                {/* check style */}
                {/* <Text fontSize="xs" color="inherit">
                  選擇
                </Text> */}
              </VStack>
            </Center>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
