import React, { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export type ScrollPositionInfo = {
  clientHeight: number;
  scrollHeight: number;
  scrollTop: number;
};

export default function useScroll(wait: number = 100) {
  const [positionInfo, setPositionInfo] = useState<ScrollPositionInfo>({
    clientHeight: document.body.clientHeight,
    scrollHeight: document.body.scrollHeight,
    scrollTop: document.body.scrollTop,
  });

  useEffect(() => {
    const handleScroll = throttle((e: any) => {
      const { clientHeight, scrollHeight, scrollTop, scrollTopMax } =
        e.target.scrollingElement;

      setPositionInfo({
        clientHeight,
        scrollHeight,
        scrollTop,
      });

      //console.log(e.target.scrollingElement);
    }, wait);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [window]);

  return { positionInfo };
}
