import { useRecoilState, useRecoilValue } from 'recoil';
import authAtom from '~/recoil/atom/auth';
import kitchenCartItemsAtom from '~/recoil/atom/kitchenCartItems';
import { updateCartData } from '~/firebase/updateData';
import { useParams } from 'react-router-dom';
import { CartItem, Menu } from '~/types';
import { menusSelector } from '~/recoil/selectors/menus';

export default function useKitchenCartItems() {
  const [kitchenCartItems, setKitchenCartItems] = useRecoilState(kitchenCartItemsAtom);
  const auth = useRecoilValue(authAtom);
  const { storeId } = useParams();

  const menus = useRecoilValue(menusSelector);

  const handleAddItems = async (kitchenId: string, item: CartItem) => {
    if (!kitchenId) {
      return;
    }

    const findKitchenGroup = kitchenCartItems.find(
      (kitchenGroup) => kitchenGroup.kitchenId === kitchenId,
    );

    let updateKitchenItems = [];
    if (findKitchenGroup) {
      updateKitchenItems = kitchenCartItems.map((kitchenGroup) => {
        if (kitchenGroup.kitchenId === kitchenId) {
          return {
            ...kitchenGroup,
            items: [...kitchenGroup.items, item],
          };
        }
        return kitchenGroup;
      });
    } else {
      updateKitchenItems = [
        ...kitchenCartItems,
        {
          kitchenId: kitchenId,
          name: Object.values(menus || {}).find((menu) => menu.kitchenId === kitchenId)?.name || '',
          items: [item],
        },
      ];
    }

    if (!auth) {
      setKitchenCartItems(updateKitchenItems);
      return;
    }

    if (storeId) {
      await updateCartData(storeId, auth, {
        orders: updateKitchenItems,
      });
    }
  };

  const handleDeleteItems = async (kitchenId: string, itemId: string, index: number) => {
    if (!kitchenId) {
      return;
    }

    const updateKitchenCartItems = kitchenCartItems
      .map((kitchenGroup) => {
        if (kitchenGroup.kitchenId === kitchenId) {
          return {
            ...kitchenGroup,
            items: kitchenGroup.items.filter(({ id }, i) => id !== itemId && i !== index),
          };
        }
        return kitchenGroup;
      })
      .filter(({ items }) => items.length !== 0);

    if (!auth) {
      setKitchenCartItems(updateKitchenCartItems);
      return;
    }

    if (storeId) {
      await updateCartData(storeId, auth, {
        orders: updateKitchenCartItems,
      });
    }
  };
  const handleEditItems = async (kitchenId: string, item: CartItem, index: number) => {
    if (!kitchenId) {
      return;
    }

    const updateKitchenCartItems = kitchenCartItems.map((kitchenGroup) => {
      if (kitchenGroup.kitchenId === kitchenId) {
        return {
          ...kitchenGroup,
          items: kitchenGroup.items.map((kitchenItem, i) =>
            kitchenItem.id === item.id && index === i ? item : kitchenItem,
          ),
        };
      }
      return kitchenGroup;
    });
    if (!auth) {
      setKitchenCartItems(updateKitchenCartItems);
      return;
    }

    if (storeId) {
      await updateCartData(storeId, auth, {
        orders: updateKitchenCartItems,
      });
    }
  };

  return {
    kitchenCartItems,
    handleEditItems,
    handleAddItems,
    handleDeleteItems,
  };
}
