export default function validTaxInvoice(invoice: string) {
  const TEX_INVOICE_VALID_MOD = 10; // maybe will become 5 after 112 (2023)

  const times = [1, 2, 1, 2, 1, 2, 4, 1];
  const invoiceArray = invoice.split('').map((char) => parseInt(char));
  const productArray = invoiceArray.map((num, index) => num * times[index]);
  const productSum = productArray.map((num) => {
    if (num >= 10) {
      return Math.floor(num / 10) + (num % 10);
    }
    return num;
  });

  // special rule for 7 in place 7
  if (invoiceArray[6] !== 7) {
    const sum = productSum.reduce((prev, num) => prev + num, 0) - 10;

    return (sum + 1) % TEX_INVOICE_VALID_MOD === 0 || sum % TEX_INVOICE_VALID_MOD === 0;
  }

  return productSum.reduce((prev, num) => prev + num, 0) % TEX_INVOICE_VALID_MOD === 0;
}
