import { useRecoilValue } from 'recoil';
import { VStack, Icon, Text, FormControl } from '@chakra-ui/react';
import SectionContainer from '~/container/SectionContainer';
import DisplayInput from './DisplayInput';
import FromInputMessage from '~/components/FormInputMessage';

import operatingTimeSelector from '~/recoil/selectors/operatingTime';

import { AiOutlineClockCircle } from 'react-icons/ai';

import checkOperatingTime from '~/utils/checkOperatingTime';
import generatePrepareString from '../utils/generatePrepareString';

import { PickMealTime, Schedule } from '~/types';

type PickupSettingInfoSectionProps = {
  pickMealTime: PickMealTime;
  prepareMinutes: number;
  preOrderDate: Date | null;
  allowPreOrder: boolean;
  allowOrder: boolean;
  onSectionClick?: () => void;
  allowEdit?: boolean;
};

export default function PickupSettingInfoSection({
  pickMealTime,
  prepareMinutes,
  preOrderDate,
  allowPreOrder,
  allowOrder,
  onSectionClick,
  allowEdit = false,
}: PickupSettingInfoSectionProps) {
  const operatingTime = useRecoilValue(operatingTimeSelector);

  const pickMealTimeErrorText = checkPickMealTimeError(pickMealTime, {
    allowOrder,
    allowPreOrder,
    operatingTime,
  });

  const isTimeError =
    (pickMealTime === PickMealTime.RESERVATION && !preOrderDate) || !!pickMealTimeErrorText;

  return (
    <VStack align="stretch">
      <SectionContainer
        title="取餐時間"
        icon={<Icon as={AiOutlineClockCircle} boxSize="1.5rem" />}
        rightHandComponent={
          allowEdit && (
            <Text onClick={onSectionClick} color={isTimeError ? '#e83b3b' : 'wsBlack'}>
              編輯
            </Text>
          )
        }
      >
        <DisplayInput
          isInvalid={isTimeError}
          color={isTimeError ? 'wsRed.500' : 'wsBlack'}
          value={generatePrepareString(pickMealTime, prepareMinutes, preOrderDate)}
          onClick={onSectionClick}
        />
        {pickMealTimeErrorText && <FromInputMessage type="error" message={pickMealTimeErrorText} />}
      </SectionContainer>
    </VStack>
  );
}

function checkPickMealTimeError(
  pickMealTime: PickMealTime,
  {
    allowOrder,
    allowPreOrder,
    operatingTime,
  }: {
    allowOrder: boolean;
    allowPreOrder: boolean;
    operatingTime: Schedule | null;
  },
): string {
  if (!allowOrder) {
    return '店家目前不接受任何訂單';
  }

  if (pickMealTime === PickMealTime.RESERVATION && !allowPreOrder) {
    return '店家不接受預訂訂單';
  }

  if (!operatingTime) return '';

  if (pickMealTime === PickMealTime.IMMEDIATELY && !checkOperatingTime(operatingTime)) {
    return '非營業時間無法提供儘快取餐服務';
  }

  return '';
}
