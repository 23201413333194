import React from 'react';
import { Box, Flex, VStack, Text, Center, Icon, HStack, Circle } from '@chakra-ui/react';
import { IoWalletSharp } from 'react-icons/io5';

type UserDiscountProps = {
  earnedDiscount: number;
  currentPoint?: number;
};

// 顯示顧客現在累積使用的優惠券金額跟集點，當不傳入點數則只顯示優惠券金額
export default function UserDiscount({ earnedDiscount, currentPoint }: UserDiscountProps) {
  return (
    <Box>
      {currentPoint ? (
        <Box borderWidth="1px" borderColor="wsGray.400" borderRadius="5px">
          <Flex py={1}>
            <Center flex="1">
              <VStack spacing={1} py={1}>
                <Text fontSize="xs">目前已賺優惠</Text>
                <Text fontSize="sm" fontWeight="bold">
                  NT${earnedDiscount}
                </Text>
              </VStack>
            </Center>
            <Box w="1px" bgColor="wsGray.400" />
            <Center flex="1">
              <VStack spacing={1} py={1}>
                <Text fontSize="xs">目前點數</Text>
                <Text fontSize="sm" fontWeight="bold">
                  {currentPoint}
                </Text>
              </VStack>
            </Center>
          </Flex>
        </Box>
      ) : (
        <Box px={2} py={3} bgColor="wsGray.200" borderRadius="5px">
          <Flex justify="space-between" align="center">
            <HStack spacing={2}>
              <Circle boxSize="2rem" bg="white">
                <Icon as={IoWalletSharp} boxSize="1.25rem" color="wsGray.500" />
              </Circle>
              <Text fontSize="sm">目前已賺優惠</Text>
            </HStack>
            <HStack spacing={1}>
              <Text fontSize="sm" fontWeight="bold">
                NT${earnedDiscount}
              </Text>
            </HStack>
          </Flex>
        </Box>
      )}
    </Box>
  );
}
